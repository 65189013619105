import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  AccortoCUtil,
  AUtil,
  DataRecord,
  DataRecordF,
  DataRecordI,
  FormManager,
  Logger,
  ModelUtil,
  ProjectLine,
  ProjectLineD,
  selectUi,
  UiFormSection,
  uiRequestAction,
  UiTab
} from 'accorto';
import {select, Store} from '@ngrx/store';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';

import {AppState} from '../../reducers';
import {selectCurrentProjectLines} from '../../project/project.selectors';
import {projectLineSaveRequestAction} from '../../project/project.actions';
import {selectProjectTeItems} from '../../te-item/te-item.selectors';
import {GraphBase} from '../../graphs/graph-base';
import {TimeLinePoint} from '../../graphs/time-line/time-line-point';
import {TEItemD} from '../../../../projects/track4d/src/app/model/t-e-item-i';
import {ProjectService} from '../../project/project.service';

@Component({
  selector: 'psa-project-burn-down',
  templateUrl: './project-burn-down.component.html',
  styleUrls: ['./project-burn-down.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectBurnDownComponent extends GraphBase implements OnInit {

  /** Project Lines */
  projectLines: DataRecordI[] = [];

  /** Project Line DataRecord */
  projectLine: DataRecord = new DataRecord();
  /** Project Line UI */
  ui: UiTab = new UiTab();
  /** TE Items */
  items: DataRecordI[] = [];
  protected subscriptions: Subscription[] = [];
  /** Lines g */
  private svgLines?: d3.Selection<any, any, any, any>;
  /** Lines g */
  private svgGuides?: d3.Selection<any, any, any, any>;

  private log: Logger = new Logger('ProjectBurnDown');
  private lastInfo?: string;

  constructor(private store: Store<AppState>) {
    super();
    this.projectLine.isActive = false;
  }

  get showPLineEdit(): boolean {
    return this.projectLine.id != null;
  }

  /**
   * Describe - destroy
   */
  public ngDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  } // ngDestroy

  ngOnInit(): void {
    // Current Project Lines
    this.subscriptions.push(this.store.pipe(
      select(selectCurrentProjectLines))
      .subscribe((projectLines) => {
        this.setProjectLines(projectLines);
      }));

    // Ui
    this.subscriptions.push(this.store
      .pipe(
        select(selectUi('ProjectLine')),
        tap(uiTab => {
          if (!uiTab) {
            this.store.dispatch(uiRequestAction({ uiName: 'ProjectLine' }));
          }
        })
      )
      .subscribe((ui) => {
        if (ui) {
          this.initUi(ui);
        }
      }));

    // items of the selected project - initiated by project select
    this.subscriptions.push(this.store.pipe(select(selectProjectTeItems))
      .subscribe((items: DataRecordI[]) => {
        this.items = items;
        this.initGraph('items #' + this.items?.length);
      }));
  } // ngOnInit

  /**
   * Project Line Change
   */
  onProjectLineChange(event: Event): void {
    const selectEle = event.target as HTMLSelectElement;
    const selectedId = selectEle.value;
    //
    this.projectLine = new DataRecord();
    this.projectLine.isActive = false;
    for (const pl of this.projectLines) {
      if (pl.id === selectedId) {
        this.projectLine = AccortoCUtil.createDataRecord(pl); // assign
        break;
      }
    }
    this.log.info('onProjectLineChange', selectedId, this.projectLine)();
    this.initGraph('plChange');
  } // onProjectLineChange

  /**
   * Record Changed - update Graph
   * @param fm form manager
   */
  onRecordChange(fm: FormManager): void {
    this.log.info('onRecordChange', fm.changes)();
    this.initGraph('change');
  }

  /**
   * Save Record
   * @param plRecord project line record
   */
  onRecordSave(plRecord: DataRecord): void {
    this.log.info('onRecordSave', plRecord)();
    this.store.dispatch(projectLineSaveRequestAction({projectLine: plRecord}));
  }

  /**
   * Planned End of project or line
   */
  private getPlannedEnd(): number | undefined | null {
    let plannedEnd: number | undefined | null;
    if (this.projectLine.id) { // single
      plannedEnd = DataRecordF.valueNumberOpt(this.projectLine, ProjectLineD.plannedEnd.n);
    } else {
      for (const pl of this.projectLines) {
        const end = DataRecordF.valueNumberOpt(pl, ProjectLineD.plannedEnd.n);
        if (end && (!plannedEnd || plannedEnd < end)) {
          plannedEnd = end;
        }
      }
    }
    return plannedEnd;
  } // getPlannedEnd

  /**
   * Planned Hours of project or line
   */
  private getPlannedHours(): number {
    let plannedEffort: number = 0;
    if (this.projectLine.id) { // single
      plannedEffort = DataRecordF.valueNumber(this.projectLine, ProjectLineD.plannedEffort.n, 0);
      if (plannedEffort) {
        return plannedEffort;
      }
    } else {
      for (const pl of this.projectLines) {
        plannedEffort += DataRecordF.valueNumber(pl, ProjectLineD.plannedEffort.n, 0);
      }
    }
    return plannedEffort;
  } // getPlannedHours

  /**
   * Planned Start of project or line
   */
  private getPlannedStart(): number | undefined | null {
    let plannedStart: number | undefined | null;
    if (this.projectLine.id) { // single
      plannedStart = DataRecordF.valueNumberOpt(this.projectLine, ProjectLineD.plannedStart.n);
    } else {
      for (const pl of this.projectLines) {
        const start = DataRecordF.valueNumberOpt(pl, ProjectLineD.plannedStart.n);
        if (start && (!plannedStart || plannedStart > start)) {
          plannedStart = start;
        }
      }
    }
    return plannedStart;
  } // getPlannedStart

  /**
   * Create Graph
   */
  private initGraph(cause: string): void {
    const selectorBackground = '.bd-background';
    if (super.initialize()) {
      this.svgMain
        .append('rect') // background for legend
        .attr('class', selectorBackground.substring(1))
        .attr('x', '0')
        .attr('y', '0')
        .attr('width', this.dimensions.boundedWidth)
        .attr('height', this.dimensions.boundedHeight);
      this.svgLines = this.svgMain
        .append('g')
        .attr('class', 'lines');
      this.svgGuides = this.svgMain
        .append('g')
        .attr('class', 'guildes');
    }

    // build data
    const plId = this.projectLine.id;
    const plannedStart = this.getPlannedStart();
    const plannedEnd = this.getPlannedEnd();
    const plannedHours: number = this.getPlannedHours(); // 0 ..
    const info = 'items=' + this.items?.length
      + ' line=' + plId + ' effort=' + plannedHours
      + ' start=' + AUtil.dateStringMs(plannedStart) + ' end=' + AUtil.dateStringMs(plannedEnd);
    if (info !== this.lastInfo) {
      this.log.debug('initGraph ' + cause, info)();
      this.lastInfo = info;
    }
    if (!plannedStart || !plannedEnd) {
      this.log.info('initGraph', 'noPlannedStart/End')();
      return;
    }

    const line: TimeLinePoint[] = [];

    let remainingHours = plannedHours;
    let instanceNo: number = 0;
    for (const item of this.items) {
      const projectLineId = DataRecordF.value(item, TEItemD.projectLineSfId.n);
      if (plId && projectLineId !== plId) {
        continue;
      }
      const teDate = DataRecordF.valueNumberOpt(item, TEItemD.teDate.n);

      instanceNo++;
      const hours = DataRecordF.valueNumberOpt(item, TEItemD.hours.n);

      if (teDate && hours) {
        remainingHours -= hours;
        const tlp: TimeLinePoint = {
          timePoint: teDate,
          label: '',
          value: hours,
          valueCumulated: remainingHours,
          tooltip: '',
          color: 'blue',
          isRight: false,
          id: 'bd-i' + instanceNo
        };
        line.push(tlp);
      }
    } // for all items
    const yMinDomain: number = remainingHours < 0 ? remainingHours : 0;

    // --- X ---
    let dateMin = line.length > 0 ? line[0].timePoint : undefined;
    let itemDates = 'itemMin=' + AUtil.dateStringMs(dateMin);
    if (plannedStart && (!dateMin || dateMin > plannedStart)) {
      dateMin = plannedStart;
    }
    let dateMax = line.length > 0 ? line[line.length - 1].timePoint : undefined;
    itemDates += ' itemMax=' + AUtil.dateStringMs(dateMax);
    if (plannedEnd && (!dateMax || dateMax < plannedEnd)) {
      dateMax = plannedEnd;
    }
    if (!dateMin || !dateMax) {
      this.log.info('initGraph', 'NoDateMon/Max')();
      return;
    }
    const xScale = d3Scale.scaleTime()
      .range([0, this.dimensions.boundedWidth])
      .domain([dateMin, dateMax])
      .nice();
    super.createXAxis(xScale);
    // this.log.debug('initGraph x', itemDates, 'xMin=' + AUtil.dateStringMs(dateMin), 'xMax=' + AUtil.dateStringMs(dateMax))();

    // --- Y ----
    const yScale = d3Scale.scaleLinear()
      .rangeRound([this.dimensions.boundedHeight, 0])
      .domain([ yMinDomain, plannedHours ])
      .nice();
    super.createYAxis(yScale);
    // this.log.debug('initGraph y', 'yMin=' + yMinDomain, 'yMax=' + plannedHours)();


    // -- burn-down line --
    // line for scale
    const lineGenerator = d3Shape.line<TimeLinePoint>()
      .x(d => xScale(d.timePoint))
      .y(d => yScale(d.valueCumulated));

    if (!this.svgLines || !this.svgGuides) {
      this.log.info('initGraph', 'NoSvgLines/Guides')();
      return;
    }
    const selectorLine = '.bd-line';
    if (this.svgLines.select(selectorLine).empty()) {
      this.svgLines
        .datum(line)
        .append('path')
        .attr('class', selectorLine.substring(1))
        .attr('d', lineGenerator)
        .attr('fill', 'none');
    } else {
      this.svgLines.select(selectorLine)
        .datum(line)
        .attr('d', lineGenerator)
        .attr('stroke', 'blue');
    }

    // - y-line target
    const selectorPlannedHours = '.bd-planned-hours';
    if (this.svgGuides.select(selectorPlannedHours).empty()) {
      this.svgGuides
        .append('line')
        .attr('class', selectorPlannedHours.substring(1))
        .attr('x1', 0)
        .attr('x2', this.dimensions.boundedWidth)
        .attr('stroke', 'green')
        .attr('stroke-dasharray', '2 1');
    }
    this.svgGuides.select(selectorPlannedHours)
      .attr('y1', yScale(plannedHours))
      .attr('y2', yScale(plannedHours));

    // - y-line 0
    const selectorZeroHours = '.bd-0-hours';
    if (this.svgGuides.select(selectorZeroHours).empty()) {
      this.svgGuides
        .append('line')
        .attr('class', selectorZeroHours.substring(1))
        .attr('x1', 0)
        .attr('x2', this.dimensions.boundedWidth)
        .attr('stroke', 'gray');
    }
    this.svgGuides.select(selectorZeroHours)
      .attr('y1', yScale(0))
      .attr('y2', yScale(0));

    // | x-line start
    const selectorStart = '.bd-start';
    if (this.svgGuides.select(selectorStart).empty()) {
      this.svgGuides
        .append('line')
        .attr('class', selectorStart.substring(1))
        .attr('y1', 0)
        .attr('y2', this.dimensions.boundedHeight)
        .attr('stroke', 'green')
        .attr('stroke-dasharray', '3 1');
    }
    this.svgGuides.select(selectorStart)
      .attr('x1', xScale(plannedStart))
      .attr('x2', xScale(plannedStart));

    // | x-line end
    const selectorEnd = '.bd-end';
    if (this.svgGuides.select(selectorEnd).empty()) {
      this.svgGuides
        .append('line')
        .attr('class', selectorEnd.substring(1))
        .attr('y1', 0)
        .attr('y2', this.dimensions.boundedHeight)
        .attr('stroke', 'green')
        .attr('stroke-dasharray', '3 1');
    }
    this.svgGuides.select(selectorEnd)
      .attr('x1', xScale(plannedEnd))
      .attr('x2', xScale(plannedEnd));

    // target line
    const selectorTarget = '.bd-target';
    if (this.svgGuides.select(selectorTarget).empty()) {
      this.svgGuides.append('line')
        .attr('class', selectorTarget.substring(1))
        .attr('stroke', 'green');
    }
    this.svgGuides.select(selectorTarget)
      .attr('x1', xScale(plannedStart))
      .attr('x2', xScale(plannedEnd))
      .attr('y1', yScale(plannedHours))
      .attr('y2', yScale(0));

  } // initGraph

  /**
   * Init UI
   * @param ui default ui
   */
  private initUi(ui: UiTab): void {
    this.log.debug('initUi', ui)();
    this.ui = ModelUtil.cloneUi(ui);
    //
    const section = new UiFormSection();
    section.name = 'Project Line Details';
    section.uiTab = this.ui; // backlink
    this.ui.formSectionList.push(section);
    //
    if (this.ui.dataTable) {
      let field = ModelUtil.cloneUiFormField(this.ui.dataTable, ProjectLineD.plannedStart.n);
      section.uiFormFieldList.push(field);
      field = ModelUtil.cloneUiFormField(this.ui.dataTable, ProjectLineD.plannedEnd.n);
      section.uiFormFieldList.push(field);
      field = ModelUtil.cloneUiFormField(this.ui.dataTable, ProjectLineD.plannedEffort.n);
      section.uiFormFieldList.push(field);
      field = ModelUtil.cloneUiFormField(this.ui.dataTable, ProjectLineD.percentComplete.n);
      section.uiFormFieldList.push(field);
    }
    // this.initGraph('ui');
  } // initUi

  /**
   * Set Project Lines
   * - filter __project__ for selection
   * - refresh selected project line
   * @param projectLines project lines
   */
  private setProjectLines(projectLines: DataRecordI[]): void {
    this.projectLines = projectLines.filter((pl) => {
      return pl.name !== ProjectService.PROJECT;
    });
    // this.log.debug('setProjectLines #' + this.projectLines.length)();
    // update selected project line
    if (this.projectLine) {
      for (const pl of this.projectLines) {
        if (pl.id === this.projectLine.id) { // refresh
          this.projectLine = AccortoCUtil.createDataRecord(pl); // assign
          break;
        }
      }
    }
    this.initGraph('pjLines #' + this.projectLines?.length);
  } // setProjectLines

} // ProjectBurnDownComponent
