import {DataRecordF, DataRecordI} from 'accorto';
import {SheetCol} from './sheet-col';

/**
 * Row
 */
export class SheetRow {

  projectLineId: string;
  projectLineName: string;

  /**
   * Row
   * @param record record
   */
  constructor(public record: DataRecordI) {
    this.projectLineId = record.id ?? '';
    //
    this.projectLineName = DataRecordF.codeLabel(record);
  } // constructor

  key(col: SheetCol): string {
    return this.projectLineId + '_' + col.propertyName;
  }

  title(col: SheetCol): string {
    return this.projectLineName + ': ' + col.label;
  }

} // SheetRow
