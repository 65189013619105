/**
 * Value Provider
 * interface CalcValue {(colName: string): number;}
 */
type CalcValue = (colName: string) => number | undefined;

/**
 * RPM Calculator
 */
export class Calculator {

  /**
   * Calculator
   * @param rpn reverse polish notation array
   */
  constructor(public rpn: string[]) {
  }

  /**
   * Perform Calculation
   * @param cv optional value provider
   * @param debug print token/stack
   */
  calc(cv: CalcValue | undefined,
       debug: boolean = false): number | undefined {
    const stack: (number | undefined)[] = [];
    this.rpn.forEach((token: string) => {
      const noParameter = this.isOperation(token);
      if (noParameter > 0) {
        const number1 = stack.pop(); // last
        const number2 = stack.pop();
        if (noParameter === 2) {
          stack.push(this.performOperation2(token, number2, number1));
        } else if (noParameter === 3) {
          const number3 = stack.pop();
          stack.push(this.performOperation3(token, number3, number2, number1));
        }
      } else {
        if (token == null || token === '') {
          stack.push(undefined);
        } else if (cv) {
          const value = cv(token);
          stack.push(value);
        } else {
          const value = Number(token);
          if (Number.isNaN(value)) {
            stack.push(undefined);
          } else {
            stack.push(value);
          }
        }
      }
      if (debug) {
        console.log('token=' + token, stack);
      }
    });
    // console.debug('==', stack);
    return stack.pop();
  } // calc

  /**
   * Token is Operation
   * @param token token
   */
  private isOperation(token: string): number {
    if (token === '+' || token === '-' || token === '*' || token === '/'
      || token === '%' || token === 'OR2') {
      return 2;
    }
    if (token === 'OR3') {
      return 3;
    }
    return 0;
  } // isOperation

  /**
   * Perform Operation with 2 parameters
   * @param operation op
   * @param num1 value 1
   * @param num2 value 2
   */
  private performOperation2(operation: string,
                            num1: number | undefined, num2: number | undefined): number | undefined {
    if (num1 != null && num2 != null) {
      switch (operation) {
        case '+':
          return num1 + num2;
        case '-':
          return num1 - num2;
        case '*':
          return (num1 * num2);
        case '/':
          return (num1 / num2);
        case '%':
          return num2 === 0 ? 0 : (num2 * 100 / num1);
      }
    }
    switch (operation) {
      case 'OR2':
        return num1 == null ? num2 : num1;

      default:
        console.error('Unknown operation2', operation);
    }
    return undefined;
  } // performOperation2

  /**
   * Perform Operation with 3 parameters
   * @param operation op
   * @param num1 value 1
   * @param num2 value 2
   * @param num3 value 3
   */
  private performOperation3(operation: string,
                            num1: number | undefined,
                            num2: number | undefined,
                            num3: number | undefined): number | undefined {
    switch (operation) {
      case 'OR3': {
        return num1 ? num1 : (num2 ? num2 : num3);
      }
      default:
        console.error('Unknown operation3', operation);
    }
    return undefined;
  } // performOperation2

} // Calculator
