import {Box} from './box';
import {ProjectLineD} from '../model/project-line-i';
import {DataRecordF} from '../utils/data-record-f';
import {DateUtil} from '../utils/date-util';

/**
 * Graph Node - Edge
 */
export class GraphEdge extends Box {

  dependentIds: string[] = [];
  prerequisiteIds: string[] = [];

  prerequisiteSet = new Set<string>();
  dependentSet = new Set<string>();

  startOffsetDays: number | undefined = 0;


  addDependent(to: GraphEdge): void {
    if (!this.dependentSet.has(to.id)) {
      this.dependentSet.add(to.id);
      this.dependentIds.push(to.id);
    }
    this.updateDependent();
  }

  addPrerequisite(from: GraphEdge): void {
    if (!this.prerequisiteSet.has(from.id)) {
      this.prerequisiteSet.add(from.id);
      this.prerequisiteIds.push(from.id);
    }
    this.updatePrerequisite();
  }

  deleteDependent(to: GraphEdge): void {
    this.dependentSet.delete(to.id);
    const index = this.dependentIds.indexOf(to.id);
    if (index >= 0) {
      this.dependentIds.splice(index, 1);
    }
    this.updateDependent();
  }

  deletePrerequisite(from: GraphEdge): void {
    this.prerequisiteSet.delete(from.id);
    const index = this.prerequisiteIds.indexOf(from.id);
    if (index >= 0) {
      this.prerequisiteIds.splice(index, 1);
    }
    this.updatePrerequisite();
  }

  getDependentId(): string | undefined {
    // n.record.value('dependentSfId'); // ChecklistItem+ only
    return undefined;
  }

  getDurationDays(): number | undefined {
    // record.valueNumber('dateOffsetDays'); // ChecklistItem
    return undefined;
  }

  getPrerequisiteId(): string | undefined | null {
    if (this.namePrerequisiteId() && this.record) {
      return DataRecordF.value(this.record, this.namePrerequisiteId());
    }
    return undefined;
  }

  getPrerequisiteIdList(): string | undefined | null {
    if (this.namePrerequisiteIdList() && this.record) {
      return DataRecordF.value(this.record, this.namePrerequisiteIdList());
    }
    return undefined;
  }

  /**
   * Record Property Name
   */
  nameDependentId(): string | undefined {
    // n.record.value('dependentSfId'); // ChecklistItem+ only
    return undefined;
  }

  /**
   * Record Property Name
   */
  namePrerequisiteId(): string | undefined {
    // n.record.value('prerequisiteSfId'); // ChecklistItem+ProjectLine
    return undefined;
  }

  /**
   * Record Property Name
   */
  namePrerequisiteIdList(): string | undefined {
    // n.record.value('prerequisiteIdList'); // ChecklistItem+ProjectLine
    return undefined;
  }

  toStringX(): string {
    if (this.record) {
      return this.label + ' start=' + DateUtil.formatIso(this.startMs)
        + '(plan=' + DateUtil.formatIso(DataRecordF.valueNumberOpt(this.record, ProjectLineD.plannedStart.n))
        + ') end=' + DateUtil.formatIso(this.startMs)
        + '(plan=' + DateUtil.formatIso(DataRecordF.valueNumberOpt(this.record, ProjectLineD.plannedEnd.n))
        + ') effort=' + DataRecordF.value(this.record, ProjectLineD.plannedEffort.n)
        + ' pre=[' + this.prerequisiteIds
        + '] - ' + JSON.stringify(this.record.changeMap);
    }
    return this.label + ' start=' + DateUtil.formatIso(this.startMs);
  }

  /**
   * Update Record Dependency
   */
  updateDependent(): void {
    if (this.nameDependentId() && this.record) {
      const first = this.dependentIds.length > 0 ? this.dependentIds[0] : null;
      DataRecordF.setValue(this.record, this.nameDependentId(), first);
    }
  }

  /**
   * Update Record Prerequisites
   */
  updatePrerequisite(): void {
    if (this.namePrerequisiteId() && this.record) {
      const first = this.prerequisiteIds.length > 0 ? this.prerequisiteIds[0] : null;
      DataRecordF.setValue(this.record, this.namePrerequisiteId(), first);
    }
    if (this.namePrerequisiteIdList() && this.record) {
      const value = this.prerequisiteIds.length > 0 ? this.prerequisiteIds.join(',') : null;
      DataRecordF.setValue(this.record, this.namePrerequisiteIdList(), value);
    }
  }

} // GraphEdge
