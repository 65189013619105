import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ResourceSearchRestrictions} from '../resource-search/resource-search-restrictions';
import {AccortoService, appStatus, DataRecordF, DataRecordI, DateUtil, ProjectLineSharingD, ResourceD} from 'accorto';
import {CalendarWeek} from './calendar-week';
import {CalendarMonth} from './calendar-month';
import {CalendarDay, CalendarDayLine} from './calendar-day';
import {PsaBase} from '../psa-base';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../reducers';
import {ResourceService} from '../resource/resource.service';
import {PsaUtil} from '../psa-util';

@Component({
  selector: 'psa-resource-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent
  extends PsaBase implements OnInit, AfterViewInit, OnDestroy {

  /** Weeks in Calendar */
  weeks: CalendarWeek[] = [];
  /** Month */
  monthList: CalendarMonth[] = [];
  monthIndex: number = 0;
  @ViewChild('theMonth', {static: true}) theMonth?: ElementRef;

  allocationList: DataRecordI[] = [];
  restrict?: ResourceSearchRestrictions;

  constructor(route: ActivatedRoute,
              router: Router,
              store: Store<AppState>,
              conf: AccortoService,
              private service: ResourceService) {
    super('Calendar', '-', '/calendar', 'Resource Calendar',
      route, router, store, conf);
    this.buildMonths();
  }

  /**
   * ReQuery and build Calendar
   */
  doRefresh(): void {
    const month = this.monthList[this.monthIndex];
    this.log.info('doRefresh', month.label)();

    this.busyPlus('alloc');
    this.service.loadResourceAllocations(undefined, month.ms, month.endMs)
      .subscribe((records) => {
        this.allocationList = DataRecordF.cloneArray(records);
        this.message = records.length + ' allocations found';
        this.buildCalendar();
        this.busyMinus('alloc');
      });
  } // doRefresh

  ngAfterViewInit(): void { // first time
    // set initial month
    if (this.theMonth) {
      const theSelect = this.theMonth.nativeElement as HTMLSelectElement;
      theSelect.value = String(this.monthIndex);
    }
  }

  public ngOnDestroy(): void {
    super.ngDestroy();
  }

  ngOnInit(): void {
    super.ngInit();
    this.buildCalendar(); // initial
    this.doRefresh(); // query
    this.store.dispatch(appStatus({status: 'calendar'}));
  } // ngInit

  onMonthChange(event: Event): void {
    if (this.theMonth) {
      const theSelect = this.theMonth.nativeElement as HTMLSelectElement;
      this.monthIndex = Number(theSelect.value); // from selection
      this.log.debug('onMonthChange', theSelect.value, this.monthIndex)();
      this.doRefresh();
    }
  }

  onMonthNext(event: Event): void {
    if (this.monthIndex < (this.monthList.length - 1)) {
      this.monthIndex++;
    }
    this.doRefresh();
  }

  onMonthPrevious(event: EventInit): void {
    if (this.monthIndex > 0) {
      this.monthIndex--;
    }
    this.doRefresh();
  }

  /**
   * Restrict Resource Records
   * @param restrict restriction
   */
  onResourceRestriction(restrict: ResourceSearchRestrictions): void {
    this.restrict = restrict;
    this.buildCalendar();
  } // onResourceRestriction

  /**
   * Build Calendar
   * - weeks
   */
  private buildCalendar(): void {
    const month = this.monthList[this.monthIndex];
    let theDay: Date = DateUtil.toStartOfWeek(month.theDate);
    //
    this.log.debug('buildCalendar', this.monthIndex, month.label, theDay.toISOString())();
    if (this.theMonth) {
      const theSelect = this.theMonth.nativeElement as HTMLSelectElement;
      theSelect.value = String(this.monthIndex);
    }
    // build empty calendar
    this.weeks = [];
    const dayMap: { [ key: string ]: CalendarDay } = {};
    while (theDay.getUTCMonth() === month.month || this.weeks.length < 4) {
      const week = new CalendarWeek();
      this.weeks.push(week);
      for (let ii = 0; ii < 7; ii++) {
        const ms = theDay.getTime();
        const day = new CalendarDay(ms, DateUtil.isWeekend(theDay));
        week.days.push(day);
        dayMap[ String(ms) ] = day;
        //
        // console.log('d', theDay.toISOString());
        theDay = new Date(ms + DateUtil.ONEDAY);
      }
      // console.log('w', theDay.toISOString(), theDay.getUTCMonth(), month.month);
    }

    // Fill calendar
    for (const alloc of this.allocationList) {
      // refs
      const resourceId = DataRecordF.value(alloc, ProjectLineSharingD.resourceSfId.n);
      const resource = this.resourceMap[resourceId ?? ''];
      // resource restriction
      if (resource && this.restrict) {
        if (!this.restrict.matches(resource)) {
          continue;
        }
      }
      //
      if (!alloc.details) {
        const detailString = DataRecordF.value(alloc, ProjectLineSharingD.plannedDetails.n);
        if (detailString && detailString.length > 0) {
          alloc.details = JSON.parse(detailString);
        } else {
          alloc.details = {};
        }
      }
      //
      const resourceName = resource?.name ? resource.name : resourceId;
      const capacityDay = DataRecordF.valueNumber(resource, ResourceD.capacityDay.n, 8);
      const projectName = DataRecordF.value(alloc, PsaUtil.AllocProjectName)
        + ': ' + DataRecordF.value(alloc, PsaUtil.AllocProjectLineName);
      //
      for (const key of Object.keys(alloc.details)) {
        if (key.startsWith('d')) {
          const valueString = alloc.details[ key ];
          const day = dayMap[ key.substring(1) ]; // ms
          if (day) {
            const line = new CalendarDayLine(resourceName ?? '?', projectName, Number(valueString), capacityDay);
            day.add(line);
          }
        }
      }
    }
  } // buildCalendar

  /**
   * Build monthList and set monthIndex
   */
  private buildMonths(): void {
    this.monthList = [];
    this.monthIndex = 0;
    const now = new Date(); // local
    const timeMs = Date.UTC(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);

    let yyyy = now.getUTCFullYear() - 1;
    let mm = 0;
    for (let ii = 0; ii < 36; ii++) {
      const ms = Date.UTC(yyyy, mm, 1, 0, 0, 0, 0);
      this.monthList.push(new CalendarMonth(ms, this.monthList.length));
      if (ms === timeMs) {
        this.monthIndex = ii;
      }
      //
      mm++;
      if (mm === 12) {
        mm = 0;
        yyyy++;
      }
    }
    this.log.debug('buildMonths', this.monthIndex, new Date(timeMs).toISOString())();
  } // buildMonths

} // CalendarComponent
