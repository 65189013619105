import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {
  AccortoService,
  appStatus,
  DataRecordF,
  DataRecordI,
  ModalInfo,
  ModalService,
  ProjectLineD,
  ProjectLineSharingD,
  Trl
} from 'accorto';
import {ResourceInfo} from '../resource-info';
import {AppState} from '../../reducers';
import {ResourceSearchRestrictions} from '../../resource-search/resource-search-restrictions';
import {ProjectService} from '../../project/project.service';
import {projectAllocationsSaveRequestAction, projectLinesSaveRequestAction} from '../../project/project.actions';
import {ResourceInfoDay} from '../resource-info-day';
import {selectProjectTeItems} from '../../te-item/te-item.selectors';
import {selectCurrentProjectAllocations, selectCurrentProjectLines} from '../../project/project.selectors';
import {Alloc} from '../alloc';
import {ProjectLineInfo} from './project-line-info';
import {ProjectAllocationInfo} from './project-allocation-info';
import {AllocationInfoDay} from '../allocation-info-day';

@Component({
  selector: 'psa-alloc-project',
  templateUrl: './alloc-project.component.html',
  styleUrls: ['./alloc-project.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AllocProjectComponent
  extends Alloc implements OnInit, OnDestroy {

  /** Resource Info List */
  resourceInfoList: ResourceInfo[] = [];
  /** Project Lines */
  projectInfoList: ProjectLineInfo[] = [];

  editProjectLine?: DataRecordI;

  popupEvent?: MouseEvent;
  popupHeading: string = '';
  popupBody: string = '';

  /** Resource Restrict */
  private resourceRestrict: ResourceSearchRestrictions = new ResourceSearchRestrictions();

  /** Mutable Allocations */
  private projectAllocations: DataRecordI[] = [];
  /** Mutable Project Lines */
  private projectLines: DataRecordI[] = [];
  /** Resource (all) Allocations */
  private resourcesAllocations: DataRecordI[] = [];

  /** Resources Allocation Query Parameters */
  private resourcesAllocQueryParameters: string = '';

  /**
   * Project Detail Allocation
   */
  constructor(route: ActivatedRoute,
              router: Router,
              store: Store<AppState>,
              conf: AccortoService,
              private service: ProjectService,
              private modalService: ModalService) {
    super('AllocProject', 'P', '/alloc-project', 'Project Detail Allocation',
      route, router, store, conf);
  }

  doRefresh(): void {
    this.log.debug('doRefresh', (this.project ? this.project.id : ''))();
    if (this.project != null && this.project.id) {
      this.loadProject(this.project.id, true);
    }
  }

  public ngOnDestroy(): void {
    super.ngDestroy();
  }

  public ngOnInit(): void {
    super.ngInit(); // projectAll currentProject resourceMap resourceAll currentResource

    // lines of selected project
    this.busyPlus('pl');
    this.subscriptions.push(this.store.pipe(select(selectCurrentProjectLines))
      .subscribe((projectLines) => {
        // this.log.debug('selectCurrentProjectLines', projectLines)();
        this.setProjectLines(projectLines);
        this.busyMinus('pl');
      }));

    // allocations of selected project
    this.busyPlus('pa');
    this.subscriptions.push(this.store.pipe(select(selectCurrentProjectAllocations))
      .subscribe((projectAllocations) => {
        // this.log.debug('selectCurrentProjectAllocations', projectAllocations)();
        this.setProjectAllocations(projectAllocations);
        this.busyMinus('pa');
      }));

    // actuals (items)
    this.subscriptions.push(this.store.pipe(select(selectProjectTeItems))
      .subscribe((projectTeItems) => {
        this.log.debug('ngInit selectProjectTeItems', projectTeItems)();
      }));

    this.store.dispatch(appStatus({ status: 'alloc-project' }));
  } // ngOnInit

  onChangeGranularity(event: Event): void {
    super.onChangeGranularity(event); // buildDateRange
    this.buildResources();
    this.buildProjectLines();
  }

  onDateFromSelected(dateFrom: Date): void {
    super.onDateFromSelected(dateFrom); // buildDateRange
    this.buildResources();
    this.buildProjectLines();
  }

  onDateToSelected(dateTo: Date): void {
    super.onDateToSelected(dateTo); // buildDateRange
    this.buildResources();
    this.buildProjectLines();
  }

  /**
   * Open PL Editor
   * @param pl project line info
   */
  onEditProjectLine(pl: ProjectLineInfo): void {
    this.log.debug('onEditProjectLine', pl)();
    this.editProjectLine = pl.pl;
  } // onEditProjectLine

  /**
   * Close PL Editor
   */
  onEditProjectLineClose(): void {
    this.log.debug('onEditProjectClose', this.editProjectLine)();
    this.editProjectLine = undefined;
  }

  /**
   * Allocation Input Change
   */
  onInputChange(event: Event, pl: ProjectLineInfo, pa: ProjectAllocationInfo, ad: AllocationInfoDay): void {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const numValue = Number(value);
    target.value = pa.setValueDay(numValue, ad);
    pa.updateSum();
    pl.updateSum();
    this.updateResourceInfo(pa, ad);
    this.log.debug('onInputChange', value, numValue, target.value)();
    this.isSaveDisabled = false;
  } // onInputChange

  /**
   * Allocation DoubleClick
   */
  onInputDblClick(event: Event, pl: ProjectLineInfo, pa: ProjectAllocationInfo, ad: AllocationInfoDay): void {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const numValue = Number(value);
    target.value = pa.toggleDay(ad);
    pa.updateSum();
    pl.updateSum();
    this.updateResourceInfo(pa, ad);
    this.log.debug('onInputDblClick', value, numValue, target.value)();
    this.isSaveDisabled = false;
  } // onInputDblClick

  /**
   * Add Allocation Line
   * @param event click
   * @param pi project line info
   */
  onProjectLineResourceAdd(event: Event, pi: ProjectLineInfo): void {
    const target = event.target as HTMLSelectElement;
    const resourceId = target.value;
    const res = this.resourceMap[resourceId];
    if (res) {
      let alloc = this.findAllocation(resourceId, pi.projectLineSfId, false);
      if (alloc) {
        alloc.isActive = true;
        alloc.changeMap[ProjectLineSharingD.isActive.n] = 'true';
        this.log.debug('onProjectLineResourceAdd (inactive)', alloc)();
      } else {
        alloc = DataRecordF.newDataRecord(ProjectLineSharingD);
        alloc.changeMap[ ProjectLineSharingD.projectLineSfId.n ] = pi.projectLineSfId;
        alloc.changeMap[ ProjectLineSharingD.resourceSfId.n ] = resourceId;
        this.log.debug('onProjectLineResourceAdd (new)', alloc)();
        this.projectAllocations.push(alloc);
      }
      this.buildResources();
      this.buildProjectLines(); // builds resource options
      this.isSaveDisabled = false;
    } else {
      this.log.debug('onProjectLineResourceAdd NoResource', resourceId, pi)();
    }
  } // onProjectLineResourceAdd

  /**
   * Change Allocation Line
   * @param pl project line info
   * @param pa allocation info
   */
  onProjectLineResourceChange(pl: ProjectLineInfo, pa: ProjectAllocationInfo): void {
    this.allocationFormSet(pa.sum, pa.capacityDay, pa.daysWeek,
      DataRecordF.valueNumber(pl.pl, ProjectLineD.plannedEffort.n, 0));
    //
    const modal = new ModalInfo(pa.label ? pa.label : '?', 'Planned Effort for ' + pl.label);
    this.modalService.doConfirm(modal, () => {
      this.allocationFormProcess(pa);
      pl.updateSum();
      this.updateResourceInfo(pa, undefined);
      this.isSaveDisabled = false;
    }, () => {
    });
  } // onProjectLineResourceChange

  /**
   * Delete Allocation Line
   * @param pl project line info
   * @param pa allocation info
   */
  onProjectLineResourceDelete(pl: ProjectLineInfo, pa: ProjectAllocationInfo): void {
    this.log.debug('onProjectLineResourceDelete', pl, pa)();
    const allocId = pa.allocation.id;
    if (allocId) { // saved
      // find by id | pl/res as it could be changed
      for (const aa of this.projectAllocations) {
        if (aa.id === allocId) {
          aa.isActive = false;
          aa.changeMap[ProjectLineSharingD.isActive.n] = 'false';
          aa.changeMap[ProjectLineSharingD.plannedEffort.n] = '0';
          aa.changeMap[ProjectLineSharingD.plannedDetails.n] = '{}';
          aa.details = {};
          break;
        }
      }
    } else { // not saved
      const allocResourceId = DataRecordF.value(pa.allocation, ProjectLineSharingD.resourceSfId.n);
      const allocProjectLineId = DataRecordF.value(pa.allocation, ProjectLineSharingD.projectLineSfId.n);
      this.findAllocation(allocResourceId, allocProjectLineId, true);
    }
    // project line
    pl.removeAllocation(pa);
    pl.buildResourceOptions(this.resourceRecords);
    this.updateResourceInfo(pa, undefined);
    this.isSaveDisabled = false;
  } // onProjectLineResourceDelete

  /**
   * Reset - does not reset Date From/To, Granularity, Resource
   */
  onReset(): void {
    this.log.debug('onReset')();
    // changes
    for (const alloc of this.projectAllocations) {
      DataRecordF.reset(alloc);
    }
    for (const pl of this.projectLines) {
      DataRecordF.reset(pl);
    }
    //
    this.buildDateRange();
    this.buildResources();
    this.buildProjectLines();
    this.isSaveDisabled = true;
  } // onResource

  /**
   * Show Popup
   * @param event mouse event
   * @param rd resource day info
   */
  onResourceDayClick(event: MouseEvent, rd: ResourceInfoDay): void {
    // this.log.debug('onResourceDayClick', rd)();
    this.popupEvent = event;
    this.popupHeading = rd.titleBase;
    this.popupBody = rd.projectLines.join('\n');
  }

  /**
   * Restrict Resource Records
   * @param restrict restriction
   */
  onResourceRestriction(restrict: ResourceSearchRestrictions): void {
    this.resourceRestrict = restrict;
    this.buildResources();
  } // onResourceRestriction

  /**
   * Save
   */
  onSave(event: Event): void {
    // Allocations
    const projectAllocations: DataRecordI[] = [];
    for (const alloc of this.projectAllocations) {
      if (DataRecordF.isChanged(alloc)) {
        const aa = DataRecordF.clone(alloc, true);
        projectAllocations.push(aa);
      }
    }
    this.log.debug('onSave - allocations', projectAllocations)();
    if (projectAllocations.length > 0) {
      this.store.dispatch(projectAllocationsSaveRequestAction({ projectAllocations }));
    }

    // Project Lines
    const projectLines: DataRecordI[] = [];
    for (const pl of this.projectLines) {
      if (DataRecordF.isChanged(pl)) {
        projectLines.push(pl);
      }
    }
    this.log.debug('onSave - lines', projectLines)();
    if (projectLines.length > 0) {
      this.store.dispatch(projectLinesSaveRequestAction({ projectLines }));
    }
  } // onSave

  /**
   * Sync Scroll
   */
  onScroll(event: Event, otherEle: HTMLElement): void {
    const target = event.target as HTMLElement;
    const scrollLeft = target.scrollLeft;
    if (scrollLeft) {
      otherEle.scrollLeft = scrollLeft;
    }
    // this.log.debug('onScroll', scrollLeft)();
  } // onScroll

  /**
   * Set dateFirstMs/dateLastMs from records
   * (called from buildDateRange)
   */
  protected buildDateRangeFromRecords(): void {
    for (const pl of this.projectLines) {
      const ps = DataRecordF.valueNumberOpt(pl, ProjectLineD.plannedStart.n);
      if (ps && (!this.dateFirstMs || this.dateFirstMs > ps)) {
        this.dateFirstMs = ps;
      }
      const pe = DataRecordF.valueNumberOpt(pl, ProjectLineD.plannedEnd.n);
      if (pe && (!this.dateLastMs || this.dateLastMs < pe)) {
        this.dateLastMs = pe;
      }
    }
  } // buildDateRangeFromRecords

  /**
   * Find allocation
   * @param allocResourceId resource id
   * @param allocProjectLineId project line id
   * @param remove remove from list
   * @return allocation record if found
   */
  protected findAllocation(allocResourceId: string | undefined | null,
                           allocProjectLineId: string | undefined | null,
                           remove: boolean = false): DataRecordI | undefined {
    if (allocProjectLineId && allocProjectLineId) {
      let index = 0;
      for (const aa of this.projectAllocations) {
        const resourceId = DataRecordF.value(aa, ProjectLineSharingD.resourceSfId.n);
        if (resourceId === allocResourceId) {
          const projectLineId = DataRecordF.value(aa, ProjectLineSharingD.projectLineSfId.n);
          if (projectLineId === allocProjectLineId) {
            if (remove) {
              this.projectAllocations.splice(index, 1); // only of no id
            }
            return aa;
          }
        }
        index++;
      }
    }
    return undefined;
  } // findAllocation

  /**
   * Load Resources Allocations
   * (called from buildDateRange)
   */
  protected loadResourcesAllocations(): void {
    const queryParameters = 'f' + this.dateFirstMs + 'l' + this.dateLastMs;
    if (this.dateFirstMs !== this.dateLastMs && this.resourcesAllocQueryParameters !== queryParameters) {
      this.log.debug('loadResourcesAllocations', Trl.formatDateJdbc(this.dateFirstMs), Trl.formatDateJdbc(this.dateLastMs))();
      this.resourcesAllocQueryParameters = queryParameters;
      this.busyPlus('res alloc');
      this.service.loadResourcesAllocations() // all
        .subscribe((records) => {
          // this.log.debug('loadResourcesAllocations', records.length)();
          this.setResourcesAllocations(records);
          this.busyMinus('res alloc');
        });
    }
  } // loadResourcesAllocations

  protected setProjectAllocations(records: DataRecordI[]): void {
    this.log.debug('setProjectAllocations', records)();
    this.projectAllocations = DataRecordF.cloneArray(records);
    this.buildResources();
    this.buildProjectLines();
  } // setProjectAllocation

  protected setProjectLines(projectLines: DataRecordI[]): void {
    this.log.debug('setProjectLines', projectLines)();
    this.projectLines = projectLines ? DataRecordF.cloneArray(projectLines) : [];
    this.buildDateRange();
    this.buildProjectLines();
    this.store.dispatch(appStatus({status: 'alloc-project-lines-' + projectLines.length}));
  } // setProjectLines

  protected setResources(resources: DataRecordI[]): void {
    super.setResources(resources);
    this.buildResources();
  }

  protected setResourcesAllocations(records: DataRecordI[]): void {
    this.log.debug('setResourcesAllocations', records.length)();
    this.resourcesAllocations = records;
    this.buildResources();
  } // setResourceAllocation

  /**
   * Build Project Line Info = projectInfoList
   * from projectLines, projectAllocations
   */
  private buildProjectLines(): void {
    this.projectInfoList = [];

    const projectLineMap: { [key: string]: ProjectLineInfo } = {};
    for (const pl of this.projectLines) {
      if (pl.name === ProjectService.PROJECT) {
        continue;
      }
      const projectLineId = pl.id;
      if (projectLineId) {
        const pi = new ProjectLineInfo(pl);
        projectLineMap[projectLineId] = pi;
        this.projectInfoList.push(pi);
      }
    }

    for (const alloc of this.projectAllocations) {
      if (alloc.isActive) {
        const projectLineId = DataRecordF.value(alloc, ProjectLineSharingD.projectLineSfId.n);
        const pi = projectLineId && projectLineMap[projectLineId];
        if (pi) {
          const resourceId = DataRecordF.value(alloc, ProjectLineSharingD.resourceSfId.n);
          const res = resourceId && this.resourceMap[resourceId];
          if (res) {
            if (pi.addAllocation(alloc, res)) {
              this.log.warn('buildProjectLines duplicate allocation', alloc);
            }
          } else {
            this.log.warn('buildProjectLines NotFound resourceId=' + resourceId);
          }
        } else {
          this.log.warn('buildProjectLines NotFound projectLineId=' + projectLineId);
        }
      } // active
    } // allocations

    // build days
    const weekly = this.granularity === this.granularityWeek;
    for (const pi of this.projectInfoList) {
      const resourceAlloc = pi.buildResourceOptions(this.resourceRecords);
      if (resourceAlloc) {
        this.projectAllocations.push(resourceAlloc);
        this.log.info('buildProjectLines', pi.label + ' * ResourceLine added')();
        this.buildResources(); // update
      }
      pi.buildDayList(weekly, this.headerInfoList);
    }
    // sort lines
    this.projectInfoList.sort((one, two) => {
      return DataRecordF.codeLabel(one.pl).localeCompare(DataRecordF.codeLabel(two.pl));
    });

    this.log.debug('buildProjectLines #' + this.projectInfoList.length)();
  } // buildProjectLines

  /**
   * Build Resource Info = resourceInfoList
   * from resourceRecords, projectAllocations
   */
  private buildResources(): void {
    // resource ids used in project
    const projectResourceIds: string[] = [];
    for (const pl of this.projectLines) {
      const resourceId = DataRecordF.value(pl, ProjectLineD.resourceSfId.n);
      if (resourceId && !projectResourceIds.includes(resourceId)) {
        projectResourceIds.push(resourceId);
      }
    }
    for (const alloc of this.projectAllocations) {
      if (alloc.isActive) {
        const resourceId = DataRecordF.value(alloc, ProjectLineSharingD.resourceSfId.n);
        if (resourceId && !projectResourceIds.includes(resourceId)) {
          projectResourceIds.push(resourceId);
        }
      }
    }

    // Resources
    this.resourceInfoList = [];
    const resourceInfoMap: { [ key: string ]: ResourceInfo } = {};
    for (const res of this.resourceRecords) {
      // filter
      let isMatched = res.id && projectResourceIds.includes(res.id);
      if (!isMatched && this.resourceRestrict.isRestricted) {
        isMatched = this.resourceRestrict.matches(res);
      }
      if (isMatched && res.id) {
        const ri = new ResourceInfo(res);
        resourceInfoMap[res.id] = ri;
        this.resourceInfoList.push(ri);
      }
    } // resources

    // Plan (Resource Allocations)
    for (const alloc of this.resourcesAllocations) {
      const resourceId = DataRecordF.value(alloc, ProjectLineSharingD.resourceSfId.n);
      const ri = resourceId && resourceInfoMap[resourceId];
      if (ri) {
        ri.addAllocation(alloc);
      }
    } // allocations

    // Plan (Project Allocations)
    for (const alloc of this.projectAllocations) {
      const resourceId = DataRecordF.value(alloc, ProjectLineSharingD.resourceSfId.n);
      const ri = resourceId && resourceInfoMap[resourceId];
      if (ri) {
        ri.addAllocation(alloc); // overwrite
      } else {
        this.log.info('buildResources NotFound ' + resourceId);
      }
    } // allocations


    // build days
    const weekly = this.granularity === this.granularityWeek;
    for (const ri of this.resourceInfoList) {
      ri.buildDayList(weekly, this.headerInfoList);
    }
    this.log.debug('buildResources #'
      + this.resourceInfoList.length + ' of ' + this.resourceRecords.length)();
  } // buildResources

  /**
   * Update Resource sum/total
   * @param pa allocation
   * @param ad optional day
   */
  private updateResourceInfo(pa: ProjectAllocationInfo, ad: AllocationInfoDay | undefined): void {
    for (const ri of this.resourceInfoList) {
      if (ri.resourceId === pa.resourceId) {
        if (ad) {
          ri.update(ad.ms); // single day
        } else {
          ri.updateSum();
        }
        break;
      }
    }
  } // updateResourceInfo

} // AllocProject
