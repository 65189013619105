/**
 * Bar Chart Source - value accumulator
 * extracted info from various data sources
 */
export interface BarSourceI {

  /** Project Line Id, Resource Id, ...  */
  name: string;
  /** Label e.g. Project Line Name, Resource Name, ... */
  label: string;

  /**
   * key: Name/Type e.g. Plan Total, Plan Billable, Current Total, ... -> value
   */
  values: { [ key: string ]: number };

} // BarSourceI

/**
 * Bar Chart Source Functions
 */
export class BarSourceF {

  static readonly PLAN_B = 'planB';
  static readonly PLAN_T = 'planT';
  static readonly PLAN_B_LABEL = 'Plan Billable';
  static readonly PLAN_T_LABEL = 'Plan Total';

  static readonly CURRENT_B = 'curB';
  static readonly CURRENT_T = 'curT';
  static readonly CURRENT_B_LABEL = 'Current Billable';
  static readonly CURRENT_T_LABEL = 'Current Total';

  static readonly INVOICED_T = 'invT';
  static readonly INVOICED_T_LABEL = 'Invoiced';

  /**
   * Add value for key
   * @param barSource bar source
   * @param key key/type
   * @param value value
   */
  static add(barSource: BarSourceI, key: string, value: number): void {
    if (barSource) {
      const entry: number = barSource.values[key];
      if (entry) {
        barSource.values[key] = value + entry; // update
      } else {
        barSource.values[key] = value; // new
      }
    }
  } // add

  /**
   * Get Value of key
   * @param barSource bat source
   * @param key key/type
   */
  static get(barSource: BarSourceI, key: string): number {
    if (barSource && key) {
      const entry: number = barSource.values[ key ];
      if (entry) {
        return entry;
      }
    }
    return 0;
  } // get

  static labelFor(type: string): string {
    switch (type) {
      case this.PLAN_T:
        return this.PLAN_T_LABEL;
      case this.PLAN_B:
        return this.PLAN_B_LABEL;
      case this.CURRENT_T:
        return this.CURRENT_T_LABEL;
      case this.CURRENT_B:
        return this.CURRENT_B_LABEL;
      case this.INVOICED_T:
        return this.INVOICED_T_LABEL;
      default:
        return type;
    }
  } // labelFor

  /**
   * Reset value for key
   * @param barSource bar source
   * @param keys key/type list
   */
  static reset(barSource: BarSourceI, keys: string[]): void {
    if (barSource && keys) {
      keys.forEach((key) => {
        barSource.values[key] = 0;
      });
    }
  } // reset

} // BarSourceF
