import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';

import {
  AccortoService,
  appStatus,
  DataRecordF,
  DataRecordI,
  ModalInfo,
  ModalService,
  NotificationService,
  ProjectLineD
} from 'accorto';
import {AppState} from '../reducers';
import {selectCurrentProjectLines} from '../project/project.selectors';
import {SheetCol} from './sheet-col';
import {Sheet} from './sheet';
import {PsaBase} from '../psa-base';
import {SheetRow} from './sheet-row';
import {projectLineDeleteRequestAction, projectLinesSaveRequestAction} from '../project/project.actions';
import {ProjectService} from '../project/project.service';

/**
 * Project Sheet
 * TODO get resource prices
 */
@Component({
  selector: 'psa-project-overview',
  templateUrl: './project-sheet.component.html',
  styleUrls: ['./project-sheet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectSheetComponent
  extends PsaBase implements OnInit, OnDestroy {

  static readonly P_PLANNED_EFFORT = 'plannedEffort';
  static readonly P_PLANNED_RATE = 'plannedRate';
  static readonly F_RESOURCE_PRICE = 'resourcePrice';
  static readonly P_UNIT_PRICE = 'unitPrice';
  static readonly P_FIXED_AMOUNT = 'fixedAmount';
  static readonly F_PLANNED_REVENUE = 'plannedRevenue';
  static readonly P_PLANNED_COST_RATE = 'plannedCostRate';
  static readonly F_RESOURCE_COST = 'resourceCost';
  static readonly P_PLANNED_COST_BUDGET = 'plannedCostBudget';
  static readonly F_PLANNED_COSTS = 'plannedCosts';
  static readonly F_PLANNED_MARGIN = 'plannedMargin';
  static readonly F_PLANNED_MARGIN_PCT = 'plannedMarginPct';

  /** The Sheet */
  sheet: Sheet = new Sheet();

  projectLine: DataRecordI | undefined = undefined;
  projectLineTab?: string;
  showProjectLineEditor: boolean = false;

  //
  constructor(route: ActivatedRoute,
              router: Router,
              store: Store<AppState>,
              private notificationService: NotificationService,
              private modalService: ModalService,
              conf: AccortoService) {
    super('ProjectSheet', 'P', '/project', 'Project Sheet',
      route, router, store, conf);
    this.createSheet();
  }

  get isSaveDisabled(): boolean {
    return !this.sheet.isChanged;
  }

  doRefresh(): void {
    this.log.debug('doRefresh', (this.project ? this.project.id : ''))();
    if (this.project != null && this.project.id) {
      this.busy = true;
      this.loadProject(this.project.id, true);
    }
  }

  ngOnDestroy(): void {
    super.ngDestroy();
  }

  ngOnInit(): void {
    this.busy = true;
    super.ngInit(); // projectAll currentProject resourceMap resourceAll currentResource

    // lines of selected project
    this.busyPlus('pl');
    this.subscriptions.push(this.store.pipe(select(selectCurrentProjectLines))
      .subscribe((projectLines) => {
        this.setProjectLines(projectLines); // updateNodes + layout
        this.busyMinus('pl');
      }));
    this.store.dispatch(appStatus({ status: 'project-sheet' }));
  } // ngOnInit

  /**
   * Project Line delete
   * @param row sheet row
   */
  onProjectLineDelete(row: SheetRow): void {
    this.log.debug('onProjectLineDelete', row, this.sheet.isChanged)();
    const modalInfo = new ModalInfo('Delete ' + row.projectLineName + '?',
      'If you have actual values, you loose all references - it is better to de-activate it!',
      'Do you want to delete the project line?'
    );
    modalInfo.setYesDestructive('Delete ' + row.projectLineName);
    this.modalService.doConfirm(modalInfo,
      () => {
        const projectLine = DataRecordF.clone(row.record);
        this.store.dispatch(projectLineDeleteRequestAction({projectLine}));
      },
      () => {
      });
  } // onProjectLineEdit

  /**
   * Project Line - opens editor
   * @param row sheet row
   */
  onProjectLineEdit(row: SheetRow): void {
    this.log.debug('onProjectLineEdit', row, this.sheet.isChanged)();
    if (this.sheet.isChanged) {
      const modalInfo = new ModalInfo('Save changes?',
        'Do you want to save the changes in the sheet?');
      this.modalService.doConfirmCancel(modalInfo,
        () => {
          this.onSave();
          this.onProjectLineEditExec(row);
        },
        () => {
          this.onProjectLineEditExec(row);
        },
        () => {
        });
    } else {
      this.onProjectLineEditExec(row);
    }
  } // onProjectLineEdit

  /**
   * Close Project Line Editor
   * - Editor updates store - calls setProjectLines
   * - reloads, changes lost
   */
  onProjectLineEditComplete(): void {
    this.showProjectLineEditor = false;
  }

  onProjectLineEditExec(row: SheetRow): void {
    this.projectLine = row.record;
    this.projectLineTab = 'Plan';
    this.showProjectLineEditor = true;
  } // onProjectLineEditExec

  /**
   * New Project Line - opens editor
   * - changes are lost when editor saves
   */
  onProjectLineNew(): void {
    this.log.debug('onProjectLineNew', this.project?.id, this.sheet.isChanged)();
    if (this.sheet.isChanged) {
      const modalInfo = new ModalInfo('Save changes?',
        'Do you want to save the changes in the sheet?');
      this.modalService.doConfirmCancel(modalInfo,
        () => {
          this.onSave();
          this.onProjectLineNewExec();
        },
        () => {
          this.onProjectLineNewExec();
        },
        () => {
        });
    } else {
      this.onProjectLineNewExec();
    }
  } // onProjectLineNew
  onProjectLineNewExec(): void {
    this.projectLine = DataRecordF.newDataRecord(ProjectLineD);
    this.projectLineTab = undefined;
    DataRecordF.setValue(this.projectLine, ProjectLineD.projectSfId.n, this.project?.id);
    this.showProjectLineEditor = true;
  } // onProjectLineNewExec

  /**
   * Select Project
   * @param project project to load [close(true)=undefined clear(false)=null]
   */
  onProjectSelected(project: DataRecordI | undefined | null): void {
    super.onProjectSelected(project, false);
  }

  onReset(): void {
    this.sheet.resetValues();
  }

  /**
   * Save Records
   */
  onSave(): void {
    const projectLines: DataRecordI[] = [];
    for (const row of this.sheet.rows) {
      const record = row.record;
      if (record.changeMap && Object.keys(record.changeMap).length > 0) {
        projectLines.push(DataRecordF.clone(record, true));
      }
    }
    this.log.info('onSave', projectLines)();
    if (projectLines.length > 0) {
      this.store.dispatch(projectLinesSaveRequestAction({projectLines}));
    }
  } // onSave

  trackByCol(index: number, col: SheetCol): string {
    return col.propertyName;
  }

  trackByRow(index: number, row: SheetRow): string {
    return row.projectLineId;
  }

  /**
   * Create Sheet - called from constructor
   */
  private createSheet(): void {
    const colorRevenue = 'lightgreen';
    const colorCosts = 'lightblue';

    // Columns
    let col = new SheetCol(this.sheet, ProjectSheetComponent.P_PLANNED_EFFORT,
      'Plan Effort', 'Planned Effort',
      SheetCol.T_SUM, SheetCol.D_H);
    this.sheet.addColumn(col);

    col = new SheetCol(this.sheet, ProjectSheetComponent.P_PLANNED_RATE,
      'Plan Price', 'Planned Price Rate (average)',
      SheetCol.T_AVG, SheetCol.D_A);
    col.withHdrColor(colorRevenue);
    this.sheet.addColumn(col);
    col = new SheetCol(this.sheet, ProjectSheetComponent.F_RESOURCE_PRICE,
      'Resource Price', 'Resource Price for the project',
      SheetCol.T_AVG, SheetCol.D_A);
    col.withHdrColor(colorRevenue);
    col.show = false;
    this.sheet.addColumn(col);
    col = new SheetCol(this.sheet, ProjectSheetComponent.P_UNIT_PRICE,
      'Unit Price', 'Unit Price overwriting resource price',
      SheetCol.T_AVG, SheetCol.D_A);
    col.withHdrColor(colorRevenue);
    this.sheet.addColumn(col);
    //
    col = new SheetCol(this.sheet, ProjectSheetComponent.P_FIXED_AMOUNT,
      'Fixed Amount', 'Fixed Amount/Sum for the Project Line',
      SheetCol.T_SUM, SheetCol.D_A);
    col.withHdrColor(colorRevenue);
    this.sheet.addColumn(col);

    // fixedAmount unitPrice resourcePrice plannedRate OR3 plannedEffort * OR2
    col = new SheetCol(this.sheet, ProjectSheetComponent.F_PLANNED_REVENUE,
      'Plan Revenue', 'Planned Revenue',
      SheetCol.T_SUM, SheetCol.D_F);
    col.withFormula([ ProjectSheetComponent.P_FIXED_AMOUNT,
      ProjectSheetComponent.P_UNIT_PRICE, ProjectSheetComponent.F_RESOURCE_PRICE, ProjectSheetComponent.P_PLANNED_RATE, 'OR3',
      ProjectSheetComponent.P_PLANNED_EFFORT, '*', 'OR2' ]);
    col.withHdrColor(colorRevenue);
    this.sheet.addColumn(col);

    // Costs
    col = new SheetCol(this.sheet, ProjectSheetComponent.P_PLANNED_COST_RATE,
      'Plan Cost', 'Planned Cost Rate (average)',
      SheetCol.T_AVG, SheetCol.D_A);
    col.withHdrColor(colorCosts);
    this.sheet.addColumn(col);
    col = new SheetCol(this.sheet, ProjectSheetComponent.F_RESOURCE_COST,
      'Resource Cost', 'Resource Cost for the project',
      SheetCol.T_AVG, SheetCol.D_A);
    col.withHdrColor(colorCosts);
    col.show = false;
    this.sheet.addColumn(col);
    //
    col = new SheetCol(this.sheet, ProjectSheetComponent.P_PLANNED_COST_BUDGET,
      'Plan Budget', 'Planned Budget (fixed amount)',
      SheetCol.T_SUM, SheetCol.D_A);
    col.withHdrColor(colorCosts);
    this.sheet.addColumn(col);

    // plannedCostBudget plannedCostRate resourceCost OR2 plannedEffort * OR2
    col = new SheetCol(this.sheet, ProjectSheetComponent.F_PLANNED_COSTS,
      'Plan Costs', 'Planned Costs',
      SheetCol.T_SUM, SheetCol.D_F);
    col.withFormula([ ProjectSheetComponent.P_PLANNED_COST_BUDGET,
      ProjectSheetComponent.P_PLANNED_COST_RATE, ProjectSheetComponent.F_RESOURCE_COST, 'OR2',
      ProjectSheetComponent.P_PLANNED_EFFORT, '*', 'OR2' ]);
    col.withHdrColor(colorCosts);
    this.sheet.addColumn(col);

    col = new SheetCol(this.sheet, ProjectSheetComponent.F_PLANNED_MARGIN,
      'Plan Margin', 'Planned Margin',
      SheetCol.T_SUM, SheetCol.D_F);
    col.withFormula([ ProjectSheetComponent.F_PLANNED_REVENUE, ProjectSheetComponent.F_PLANNED_COSTS, '-' ]);
    this.sheet.addColumn(col);

    // (plannedRevenue - plannedCosts) * 100 / plannedRevenue
    col = new SheetCol(this.sheet, ProjectSheetComponent.F_PLANNED_MARGIN_PCT,
      '% Plan Margin', 'Planned Margin Percent',
      SheetCol.T_FUN, SheetCol.D_P);
    col.withFormula([ ProjectSheetComponent.F_PLANNED_REVENUE, ProjectSheetComponent.F_PLANNED_COSTS, '-',
      '100', '*',
      ProjectSheetComponent.F_PLANNED_REVENUE, '/' ]);
    this.sheet.addColumn(col);

    // initial
    this.sheet.createFormGroup('init');
  } // createSheet

  /**
   * Initialize Sheet
   * @param projectLines new project lines
   */
  private setProjectLines(projectLines: DataRecordI[]): void {
    this.log.debug('setProjectLines', projectLines)();

    this.sheet.resetRows();
    // this.data.resetKey1Plan(this.projectId);
    if (projectLines) {
      projectLines.forEach((record: DataRecordI) => {
        if (record.name !== ProjectService.PROJECT) {
          this.sheet.addRow(DataRecordF.clone(record));
        }
      });
    }
    // set rows
    this.sheet.createFormGroup('plLines');
    this.store.dispatch(appStatus({ status: 'project-sheet-lines-' + projectLines.length }));
  } // setProjectLines

} // ProjectSheetComponent
