
/* GenTsInterface client: com.accorto.domain.TzInfo */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: TzInfo
 */
export interface TzInfoI {

  /**
   * e.g. British Time - Central European Time - Pacific Time
   * label: Timezone Name
   */
  fullName: string;

  /**
   * e.g. Europe/London - Europe/Brussels - America/Los_Angeles
   * label: Timezone Id
   */
  id?: string;

  /**
   * e.g. Z - +01:00 - -08:00
   * label: Offset Id
   */
  offsetId: string;

  /**
   * e.g. 0 - 36000 - -28800
   * label: Offset in Seconds
   */
  offsetSeconds: number;

  /**
   * e.g. BT - CET - PT
   * label: Short Name
   */
  shortName: string;

  /**
   * time in ms
   * label: Timezone change
   */
  tzChange?: number;

  /**
   * e.g. 0 - 36000 - -28800
   * label: Offset in Seconds after Change
   */
  tzChangeSeconds?: number;

} // TzInfo

/** TzInfo Definition */
export const TzInfoD = {
  recordTypeName: 'TzInfo',
  fullName: {
    n: 'fullName',
    t: 'string'
  },
  id: {
    n: 'id',
    t: 'string'
  },
  offsetId: {
    n: 'offsetId',
    t: 'string'
  },
  offsetSeconds: {
    n: 'offsetSeconds',
    t: 'number'
  },
  shortName: {
    n: 'shortName',
    t: 'string'
  },
  tzChange: {
    n: 'tzChange',
    t: 'number'
  },
  tzChangeSeconds: {
    n: 'tzChangeSeconds',
    t: 'number'
  },
}; // TzInfo
