import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectLoginLoggedIn } from './login.selectors';
import { ClientStorage } from '../utils/client-storage';

/**
 * Navigate to /login of not logged in
 */
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  /** Logged In */
  private loggedIn: boolean = false;

  constructor(private store: Store<object>, private router: Router) {
    this.store.pipe(select(selectLoginLoggedIn))
      .subscribe((val) => {
        this.loggedIn = val;
      });
  } // constructor


  public canActivate(next: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.loggedIn) {
      ClientStorage.set('redirect', undefined);
      return true;
    }
    this.router.navigateByUrl('/login');
    return false;
  } // canActivate

} // LoginGuard
