import { CalendarDay } from './calendar-day';

export class CalendarWeek {

  days: CalendarDay[] = [];

  constructor() {
  }

  toString(): string {
    return 'Week #' + this.days.length;
  }

}
