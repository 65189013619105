import {SheetRow} from './sheet-row';
import {DataRecordF, DataRecordI, Logger, Trl} from 'accorto';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Calculator} from './calculator';
import {Sheet} from './sheet';

/**
 * Column
 */
export class SheetCol {

  /** Top Type - SUM */
  static readonly T_SUM = 'SUM';
  /** Top Type - AVG */
  static readonly T_AVG = 'AVG';
  /** Top Type - FUN */
  static readonly T_FUN = 'FUN';
  /** Detail Type Amount */
  static readonly D_A = 'A';
  /** Detail Type Hour */
  static readonly D_H = 'H';
  /** Detail Type Function */
  static readonly D_F = 'F';
  /** Detail Type Percent */
  static readonly D_P = 'P';

  /** Top line (sum, avg) */
  top: number = 0;
  /** Delta */
  delta: number = 0;
  /** Sum of details */
  sum: number = 0;
  /** Average of details */
  avg: number = 0;

  /** header background color */
  hdrColor: string = '';

  /** Show Column */
  show: boolean = true;

  /** Formula - reverse polish notation */
  public rpn: string[] = [];

  /** Decimal positions */
  private decimals: number = 0;
  private roundMulti: number = 1;

  private log: Logger = new Logger('SheetColumn');

  /**
   * Sheet Column
   * @param sheet parent
   * @param propertyName name
   * @param label label
   * @param title title
   * @param topType SUM or AVG or FUN
   * @param detailType detail type - H hour - A amount - F function
   */
  constructor(private sheet: Sheet,
              public propertyName: string,
              public label: string,
              public title: string,
              private topType: string = SheetCol.T_SUM,
              private detailType: string = SheetCol.D_A) {
    this.log.setSubName(propertyName);
  }

  /** are the adjust buttons disabled */
  get adjustDisabled(): boolean {
    return this.delta === 0; // no diff
  }

  /** Detail Icon */
  get detailIcon(): string {
    switch (this.detailType) {
      case SheetCol.D_A:
        return '\u00A4'; // currency
      case SheetCol.D_H:
        return '\u29D6'; // hour glass
      case SheetCol.D_P:
        return '%';
      case SheetCol.D_F:
        return '\u0192'; // small f with hook
      default:
        return '';
    }
  } // detailIcon

  get isReadOnly(): boolean {
    return this.rpn && this.rpn.length > 0;
  }

  /** Type Icon */
  get typeIcon(): string {
    switch (this.topType) {
      case SheetCol.T_AVG:
        return '\u2205'; // empty set
      // return '\u2300'; // diameter
      case SheetCol.T_FUN:
        return '\u0192'; // small f with hook
      default:
        return '\u2211'; // SUM
    }
  } // typeIcon

  /** Type Title */
  get typeTitle(): string {
    switch (this.topType) {
      case SheetCol.T_AVG:
        return 'Average: ';
      case SheetCol.T_FUN:
        return 'Formula: ';
      default:
        return 'Sum: ';
    }
  } // typeTitle

  getId(rowInfo: string): string {
    return rowInfo + '_' + this.propertyName;
  }

  /**
   * Distribute SUM/AVG delta to details
   * @param formGroup controls
   */
  adjustDetails(formGroup: FormGroup): string {
    if (this.topType === SheetCol.T_SUM) {
      return this.adjustDetailsSum(formGroup);
    }
    // Average
    let info = this.propertyName + ' delta=' + this.delta + ' avg=' + this.top;
    this.sheet.rows.forEach((row) => {
      DataRecordF.setValue(row.record, this.propertyName, String(this.top));
      formGroup.controls[ this.key(row) ].setValue(Trl.formatNumber(this.top, this.decimals));
    });
    this.sum = this.calculateSum();
    this.avg = this.calculateAverage();
    this.delta = this.top - this.avg;
    formGroup.controls[ this.keyDelta() ].setValue(Trl.formatNumber(this.delta, this.decimals));
    info += ' -> delta=' + this.delta + ' sum=' + this.sum;
    this.log.debug('adjustDetails', info)();

    return info;
  } // adjustDetails

  /**
   * Distribute SUM delta to details
   * @param formGroup controls
   */
  adjustDetailsSum(formGroup: FormGroup): string {
    let info = this.propertyName + ' delta=' + this.delta;
    if (this.delta !== 0 && this.sheet.rows.length > 0) {
      let aCtl: AbstractControl | undefined;
      let record: DataRecordI | undefined;
      if (this.sum === 0) { // distribute evenly
        const each = Math.round(this.delta / this.sheet.rows.length * this.roundMulti) / this.roundMulti;
        info += ' each=' + each;
        this.sheet.rows.forEach((row) => {
          record = row.record;
          DataRecordF.setValue(record, this.propertyName, String(each));
          aCtl = formGroup.controls[this.key(row)];
          aCtl.setValue(Trl.formatNumber(each, this.decimals));
        });
      } else { // prorate
        const multi = 1 + (this.delta / this.sum);
        info += ' multi=' + multi;
        this.sheet.rows.forEach((row) => {
          const valueString = DataRecordF.value(row.record, this.propertyName);
          if (valueString) {
            const valueNo = Number(valueString);
            if (!Number.isNaN(valueNo)) {
              const newValue = Math.round(multi * valueNo * this.roundMulti) / this.roundMulti;
              record = row.record;
              info += '; ' + record.id + '(' + valueString + ')=' + newValue;
              DataRecordF.setValue(record, this.propertyName, String(newValue));
              aCtl = formGroup.controls[ this.key(row) ];
              aCtl.setValue(Trl.formatNumber(newValue, this.decimals));
            }
          }
        });
      }
      // adjust last row
      this.sum = this.calculateSum();
      this.delta = this.top - this.sum;
      if (aCtl && record && this.delta !== 0) {
        const value = DataRecordF.valueNumber(record, this.propertyName, 0);
        const newValue = value + this.delta;
        DataRecordF.setValue(record, this.propertyName, String(newValue));
        aCtl.setValue(Trl.formatNumber(newValue, this.decimals));
        this.sum = this.calculateSum();
        info += '| delta=' + this.delta + ' to ' + record.id + '(' + value + ')=' + newValue;
      }
    } // delta
    this.avg = this.calculateAverage();
    this.delta = this.top - this.sum;
    formGroup.controls[ this.keyDelta() ].setValue(Trl.formatNumber(this.delta, this.decimals));
    this.log.debug('adjustDetailsSum', info)();
    return info;
  } // adjustDetailsSum

  /**
   * Add delta to total (set total to sum)
   * @param formGroup controls
   */
  adjustTop(formGroup: FormGroup): string {
    this.sum = this.calculateSum();
    this.avg = this.calculateAverage();
    let info = this.propertyName;
    if (this.topType === SheetCol.T_AVG) {
      this.top = this.avg;
      info += ' avg=' + this.avg;
    } else if (this.topType === SheetCol.T_FUN) {
      this.top = -1;
      info += ' fun=' + this.top;
    } else {
      this.top = this.sum;
      info += ' sum=' + this.sum;
    }
    formGroup.controls[ this.keyTop() ].setValue(Trl.formatNumber(this.top, this.decimals));
    this.delta = 0;
    formGroup.controls[ this.keyDelta() ].setValue(Trl.formatNumber(this.delta, this.decimals));
    this.log.debug('adjustTop', info)();
    return info;
  } // adjustTop

  /**
   * Calculate Average of Rows
   */
  calculateAverage(): number {
    let sum = 0;
    let count = 0;
    this.sheet.rows.forEach((row) => {
      const valueString = DataRecordF.value(row.record, this.propertyName);
      if (valueString) {
        const valueNo = Number(valueString);
        if (!Number.isNaN(valueNo)) {
          sum += valueNo;
          count++;
        }
      }
    });
    if (count === 0) {
      return sum;
    }
    return Math.round(sum / count * this.roundMulti) * this.roundMulti;
  } // calculateSum

  /**
   * Calculate Sum of Rows
   */
  calculateSum(): number {
    let sum = 0;
    this.sheet.rows.forEach((row) => {
      const valueString = DataRecordF.value(row.record, this.propertyName);
      if (valueString) {
        const valueNo = Number(valueString);
        if (!Number.isNaN(valueNo)) {
          sum += valueNo;
        }
      }
    });
    return sum;
  } // calculateSum

  /**
   * Dependent Changed
   * @param row sheet row
   * @param formGroup form group
   */
  changedDependent(row: SheetRow, formGroup: FormGroup): void {
    const rowValue = this.getRecordValueRow(row); // updates record
    const rowFormatted = rowValue ? Trl.formatNumber(rowValue, this.decimals) : '';
    formGroup.controls[this.key(row)].setValue(rowFormatted); // update form
    this.log.debug('changedDependent', rowValue)();
    //
    formGroup.controls[this.keyTop()].setValue(this.getTopFormatted()); // updateForm
  } // changedDependent

  /**
   * Detail changed - reformat value and update top/delta
   * @param newValue new value
   * @param row row
   * @param changedControl control
   * @param formGroup controls
   */
  changedDetail(newValue: number, row: SheetRow | undefined,
                changedControl: AbstractControl, formGroup: FormGroup): void {
    if (Number.isNaN(newValue)) {
      changedControl.setValue(this.getTopFormatted());
    } else if (row) {
      changedControl.setValue(Trl.formatNumber(newValue, this.decimals)); // reformat
      DataRecordF.setValue(row.record, this.propertyName, String(newValue)); // update record
      this.sum = this.calculateSum();
      this.avg = this.calculateAverage();
      this.delta = this.topType === SheetCol.T_AVG
        ? this.top - this.avg
        : this.top - this.sum;
      const deltaFormatted = (this.delta && this.delta !== 0)
        ? Trl.formatNumber(this.delta, this.decimals) : '';
      formGroup.controls[this.keyDelta()].setValue(deltaFormatted);
    }
  } // changedDetail

  /**
   * Top changed
   * @param newValue new value
   * @param changedControl control
   * @param formGroup controls
   */
  changedTop(newValue: number, changedControl: AbstractControl, formGroup: FormGroup): void {
    if (Number.isNaN(newValue)) {
      changedControl.setValue(this.getTopFormatted());
    } else {
      changedControl.setValue(Trl.formatNumber(newValue, this.decimals)); // reformat
      this.top = newValue;
      this.sum = this.calculateSum();
      this.avg = this.calculateAverage();
      this.delta = this.topType === SheetCol.T_AVG
        ? this.top - this.avg
        : this.top - this.sum;
      formGroup.controls[ this.keyDelta() ].setValue(Trl.formatNumber(this.delta, this.decimals));
    }
  } // changedTop

  /**
   * Return record value - undefined if not set or invalid
   * @param rowId row id
   */
  getRecordValueId(rowId: string): number | undefined {
    const theRow = this.sheet.rows.find((row) => {
      return row.record.id === rowId;
    });
    return this.getRecordValueRow(theRow);
  }

  /**
   * Return record value
   * - undefined if not set or invalid
   * - for formulas, calculate + store
   * @param row row
   */
  getRecordValueRow(row: SheetRow | undefined): number | undefined {
    if (row != null) {
      if (this.rpn && this.rpn.length > 0) {
        // look up detail value
        function evaluate(colName: string): number | undefined {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const sValue = DataRecordF.valueOpt(row!.record, colName);
          if (sValue) {
            const nValue = Number(sValue);
            if (!Number.isNaN(nValue)) {
              return nValue;
            }
          } else if (colName) { // number constant
            const cc = Number(colName);
            if (!Number.isNaN(cc)) {
              return cc;
            }
          }
          return undefined;
        } // detail value

        const calc = new Calculator(this.rpn);
        const calcResult = calc.calc(evaluate);
        // this.log.debug('getRecordValueRow calc', calcResult)();
        if (!Number.isNaN(calcResult)) {
          DataRecordF.setValue(row.record, this.propertyName, String(calcResult)); // update
        } else {
          //  this.log.debug('getRecordValueRow calc', calcResult)();
          //  calc.calc(evaluate, true);
        }
        return calcResult;
      }
      const stringValue = DataRecordF.value(row.record, this.propertyName);
      if (stringValue) {
        const value = Number(stringValue);
        if (!Number.isNaN(value)) {
          //  this.log.debug('getRecordValueRow', value)();
          return value;
        }
      }
    } // row
    // this.log.debug('getRecordValueRow -', row)();
    return undefined;
  } // getRecordValueRow

  /**
   * Get formatted Value
   * @param row sheet row
   */
  getRecordValueRowFormatted(row: SheetRow): string {
    const value = this.getRecordValueRow(row);
    return value ? Trl.formatNumber(value, this.decimals) : '';
  } // getRecordValueRowFormatted

  /**
   * Top Sum/Average Formatted
   */
  getTopFormatted(): string {
    if (this.rpn && this.rpn.length > 0) {
      this.sum = 0;
      let count = 0;
      this.sheet.rows.forEach((row) => {
        const vv = this.getRecordValueRow(row);
        if (vv) {
          this.sum += vv;
          count++;
        }
      });
      if (this.topType === SheetCol.T_AVG) {
        this.top = this.sum / (count === 0 ? 1 : count);
      } else if (this.topType === SheetCol.T_FUN) {
        // look up top value
        const theSheet = this.sheet;

        // CalcValue
        function evaluate(colName: string): number | undefined {
          const col = theSheet.getColumn(colName);
          if (col) {
            return col.top;
          } else if (colName) { // constant
            const cc = Number(colName);
            if (!Number.isNaN(cc)) {
              return cc;
            }
          }
          return undefined;
        } // top value
        const calc = new Calculator(this.rpn);
        this.top = calc.calc(evaluate) ?? 0;
      } else {
        this.top = this.sum;
      }
    }
    return this.top ? Trl.formatNumber(this.top, this.decimals) : '';
  } // getTopFormatted

  key(row: SheetRow): string {
    return row.projectLineId + '_' + this.propertyName;
  }

  keyDelta(): string {
    return 'Delta_' + this.propertyName;
  }

  keyTop(): string {
    return 'Top_' + this.propertyName;
  }

  /**
   * Initialize
   */
  init(): void {
    this.sum = this.calculateSum();
    this.avg = this.calculateAverage();
    if (this.topType === SheetCol.T_AVG) {
      this.top = this.avg;
    } else if (this.topType === SheetCol.T_FUN) {
      this.top = 0; // undefined
    } else {
      this.top = this.sum;
    }
    this.delta = 0;
  } // setRows

  toString(): string {
    return 'SheetCol@' + this.propertyName;
  }

  /**
   * Set Decimal precision
   * @param dec precision
   */
  withDecimals(dec: number): SheetCol {
    if (dec === 1) {
      this.decimals = 1;
      this.roundMulti = 10;
    } else if (dec === 2) {
      this.decimals = 2;
      this.roundMulti = 100;
    } else { // none
      this.decimals = 0;
      this.roundMulti = 1;
    }
    return this;
  }

  /**
   * Set Formula
   * @param rpn reverse polish notation array
   */
  withFormula(rpn: string[]): SheetCol {
    this.rpn = rpn;
    return this;
  }

  /**
   * Set Background Color
   * @param hdrColor color
   */
  withHdrColor(hdrColor: string): SheetCol {
    this.hdrColor = hdrColor;
    return this;
  }

} // SheetCol
