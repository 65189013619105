import {PsaBase} from '../psa-base';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../reducers';
import {AccortoService, AUtil, DateUtil, Trl} from 'accorto';
import {HeaderInfoDay} from './header-info-day';
import {FormControl, FormGroup} from '@angular/forms';
import {AllocationInfo} from './allocation-info';

/**
 * Common Allocation
 */
export class Alloc extends PsaBase {

  /** actual slots (ms) */
  headerInfoList: HeaderInfoDay[] = [];
  timeFrameInfo: string = '';
  timeFrameError?: string;
  isSaveDisabled: boolean = true;
  /** entry granularity */
  protected granularity: string = 'plan-day5';
  protected readonly granularityDay5 = 'plan-day5';
  protected readonly granularityDay7 = 'plan-day7';
  protected readonly granularityWeek = 'plan-week';
  // data based
  protected dateFirstMs?: number;
  protected dateLastMs?: number;
  // user selected
  private dateFrom?: Date;
  private dateTo?: Date;

  // allocation form
  allocationForm: FormGroup = new FormGroup({
    effort: new FormControl(),
    distribution: new FormControl(),
    capacityDay: new FormControl(),
    daysWeek: new FormControl(),
    total: new FormControl({value: 0, disabled: true})
  });

  /** Number of days/weeks */
  private readonly LIMIT = 90;

  /**
   * Common Allocation
   */
  constructor(logName: string,
              type: string,
              path: string,
              title: string,
              route: ActivatedRoute,
              router: Router,
              store: Store<AppState>,
              conf: AccortoService) {
    super(logName, type, path, title, route, router, store, conf);
  }

  get dateSlotCount(): number {
    return this.headerInfoList.length;
  }

  onChangeGranularity(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.granularity = target.value;
    this.log.info('onChangeGranularity', this.granularity)();
    this.buildDateRange();
  }

  onDateFromSelected(dateFrom: Date): void {
    this.log.info('onDateFromSelected', dateFrom)();
    this.dateFrom = dateFrom;
    this.buildDateRange();
  }

  onDateToSelected(dateTo: Date): void {
    this.log.info('onDateToSelected', dateTo)();
    this.dateTo = dateTo;
    this.buildDateRange();
  }

  protected allocationFormProcess(ai: AllocationInfo): void {
    this.log.debug('allocationFormProcess', this.allocationForm.value)();
    const effort: number = this.allocationForm.value.effort;
    const distribution: string = this.allocationForm.value.distribution;
    const capacityDay: number = this.allocationForm.value.capacityDay;
    const daysWeek: number = this.allocationForm.value.daysWeek;
    const total: number = this.allocationForm.value.total;
    //
    let remaining = effort;
    for (const day of ai.dayList) {
      if (day.isDisplayed) {
        // value
        let vv: number = capacityDay;
        if (day.isWeekly) {
          vv *= daysWeek;
        }
        if (remaining < vv) {
          vv = remaining;
        }
        ai.setValueDay(vv, day); // day/week
        //
        remaining -= vv;
        // if (remaining <= 0) {
        //   break;
        // }
      } // displayed day
    }
    ai.updateSum();
  } // allocationFormProcess

  /**
   * Set dateFirstMs/dateLastMs from records
   * (called from buildDateRange)
   */
  protected buildDateRangeFromRecords(): void {
  }

  /**
   * Load Resources Allocations
   * (called from buildDateRange)
   */
  protected loadResourcesAllocations(): void {
  }

  protected allocationFormSet(effort: number, capacityDay: number, daysWeek: number,
                              total: number | undefined | null): void {
    this.allocationForm.setValue({
      effort,
      distribution: 'first',
      capacityDay,
      daysWeek,
      total
    });
  } // allocationFormSet

  /**
   * Build Date Range
   * from ProjectLines, Granularity, dateFrom, DateTo
   *  = dateFirstMs, dateLastMs, dateSlots, headerInfo - dateFrameInfo, dateFrameError
   * calls buildDateRangeFromRecords, loadResourcesAllocations
   */
  protected buildDateRange(): void {
    this.dateFirstMs = undefined;
    this.dateLastMs = undefined;

    this.buildDateRangeFromRecords(); // sets dateFirstMs/dateLastMs
    if (!this.dateFirstMs) {
      if (this.dateLastMs) {
        this.dateFirstMs = this.dateLastMs;
      } else {
        this.dateFirstMs = DateUtil.today().getTime();
      }
    }
    if (!this.dateLastMs) {
      this.dateLastMs = this.dateFirstMs;
    }
    let dayCount = ((this.dateLastMs - this.dateFirstMs) / DateUtil.ONEDAY) + 1;
    let weekCount = Math.round(dayCount / 7) + 1;
    let monthCount = Math.round(dayCount / 30) + 1;

    this.timeFrameInfo = Trl.formatDateUtcMs(this.dateFirstMs)
      + '-' + Trl.formatDateUtcMs(this.dateLastMs);
    if (this.granularity === this.granularityDay5 || this.granularity === this.granularityDay7) {
      this.timeFrameInfo += '\ndays: ' + dayCount;
    } else { // if (this.granularity === this.granularityWeek) {
      this.timeFrameInfo += '\nweeks: ' + weekCount;
    }
    const projectInfo = 'd=' + dayCount + ' wk=' + weekCount + ' m=' + monthCount;
    // date range
    if (this.dateFrom) {
      this.dateFirstMs = this.dateFrom.getTime();
    }
    if (this.dateTo) {
      this.dateLastMs = this.dateTo.getTime();
    }
    dayCount = ((this.dateLastMs - this.dateFirstMs) / DateUtil.ONEDAY) + 1;
    weekCount = Math.round(dayCount / 7) + 1;
    monthCount = Math.round(dayCount / 30) + 1;
    //
    this.loadResourcesAllocations(); // loads allocations if required
    //
    this.timeFrameError = undefined;
    this.headerInfoList = [];
    let dateSlotCount = 0;
    if (this.granularity === this.granularityDay5 || this.granularity === this.granularityDay7) {
      if (dayCount > this.LIMIT) {
        dateSlotCount = this.LIMIT;
        this.timeFrameError = 'Too many days, switch Granularity \nor restrict date range (limit: ' + this.LIMIT + ')';
      } else {
        dateSlotCount = dayCount > 0 ? dayCount : 1;
      }
      let ms = this.dateFirstMs;
      let lastDayStart: boolean = false;
      while (this.headerInfoList.length < dateSlotCount) {
        const isWeekStartDay = DateUtil.isWeekStartDay(new Date(ms));
        if (this.granularity === this.granularityDay7) {
          this.headerInfoList.push(new HeaderInfoDay(ms, false, isWeekStartDay));
        } else {
          if (DateUtil.isWeekend(new Date(ms))) {
            lastDayStart = isWeekStartDay;
          } else {
            this.headerInfoList.push(new HeaderInfoDay(ms, false, isWeekStartDay || lastDayStart));
            lastDayStart = false;
          }
        }
        ms += DateUtil.ONEDAY;
      }
    } else if (this.granularity === this.granularityWeek) { // week
      if (weekCount > this.LIMIT) {
        dateSlotCount = this.LIMIT;
        this.timeFrameError = 'Too many weeks, switch Granularity or restrict date range (limit: ' + this.LIMIT + ')';
      } else {
        dateSlotCount = weekCount > 0 ? weekCount : 1;
      }
      let ms = this.dateFirstMs; // startOfWeek
      while (this.headerInfoList.length < dateSlotCount) {
        this.headerInfoList.push(new HeaderInfoDay(ms, true, false));
        ms += DateUtil.ONEWEEK;
      }
    }
    this.log.debug('buildDateRange', this.granularity + ' #' + this.dateSlotCount,
      projectInfo, AUtil.dateStringMs(this.dateFirstMs), AUtil.dateStringMs(this.dateLastMs),
      'd=' + dayCount + ' wk=' + weekCount + ' m=' + monthCount, 'headers=' + this.headerInfoList.length)();
  } // buildDateRange

} // Alloc
