import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {AccortoService, appStatus, DataRecordF, DataRecordI, Trl} from 'accorto';
import {AppState} from '../reducers';
import {PsaBase} from '../psa-base';
import {TimeLineStackedSource} from '../graphs/time-line-stacked/time-line-stacked-source';
import {TimeLineStacked} from '../graphs/time-line-stacked/time-line-stacked';
import {TEItemD} from '../../../projects/track4d/src/app/model/t-e-item-i';
import {TeItemService} from '../te-item/te-item.service';

@Component({
  selector: 'psa-resource-overview',
  templateUrl: './resource-overview.component.html',
  styleUrls: ['./resource-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResourceOverviewComponent
  extends PsaBase implements OnInit, OnDestroy {

  /** Data for all Resources */
  timeSources: TimeLineStackedSource[] = [];
  /** Data for selected Resources */
  timeSourcesResource: TimeLineStackedSource[] = [];

  dayWeekMonth: string = 'month';
  hoursDefinition: TimeLineStacked = {
    domainAttribute: 'invoiced',
    domainLabelMap: {
      true: 'Billable',
      false: 'Not Billable'
    },
    valueAttribute: 'hours',
    marginName: 'Utilization',
    marginValueTop: 'true',
    marginValueBottom: 'false'
  };
  projectsDefinition: TimeLineStacked = {
    domainAttribute: 'projectId',
    domainLabelMap: {},
    valueAttribute: 'hours'
  };

  private fromTimeMs?: number;
  private toTimeMs?: number;

  constructor(private service: TeItemService,
              store: Store<AppState>,
              conf: AccortoService) {
    super('ResourceOvr', 'R', '/resources', 'Resource Overview',
      undefined, undefined, store, conf);
  }

  public ngOnDestroy(): void {
    super.ngDestroy();
  }

  public ngOnInit(): void {
    super.ngInit(); // projectAll currentProject resourceMap resourceAll currentResource

    this.onRefresh();
    this.store.dispatch(appStatus({ status: 'resource-ov' }));
  } // ngOnInit


  onDateFromSelected(dateFrom: Date): void {
    this.fromTimeMs = dateFrom ? dateFrom.getTime() : undefined;
    this.onRefresh();
  }

  onDateToSelected(dateTo: Date): void {
    this.toTimeMs = dateTo ? dateTo.getTime() : undefined;
    this.onRefresh();
  }

  /**
   * Switch scope
   * @param event mouse click
   */
  onDayWeekMonthClick(event: MouseEvent): void {
    const target = event.target as HTMLInputElement;
    // this.log.debug('onDayWeekMonthClick', target.value)();
    this.dayWeekMonth = target.value; // month|week -- causes re-display
  }

  /**
   * Refresh
   */
  onRefresh(): void {
    this.log.debug('onRefresh', Trl.formatDateUtcMs(this.fromTimeMs), Trl.formatDateUtcMs(this.toTimeMs))();

    // items of all resources - initiated above
    this.busyPlus('items');
    this.service.loadResourceItems(undefined, this.fromTimeMs, this.toTimeMs)
      .subscribe((items) => {
        this.loadTeItems(items);
        this.busyMinus('items');
      });
  } // onRefresh

  /**
   * Header: Select Resource
   * @param resource (null to clear)
   */
  onResourceSelected(resource: DataRecordI | undefined | null): void {
    super.onResourceSelected(resource, false); // global
    this.setTimeSourceResource();
  } // onResourceSelected

  /**
   * Show Resource Selection
   */
  onSelectResource(): void {
    this.log.debug('onSelectResource')();
    this.showResourceSelect = true;
    this.conf.inIFrameConfirmed = true;
  }

  /**
   * Load Items - timeSources, timeSourcesResource
   * @param items items
   */
  protected loadTeItems(items: DataRecordI[]): void {
    this.log.debug('loadTeItems ' + items.length)();

    this.timeSources = [];
    items.forEach((item) => {
      const resourceId = DataRecordF.value(item, TEItemD.resourceSfId.n);
      const projectId = DataRecordF.value(item, TEItemD.projectSfId.n);
      const projectLineId = DataRecordF.value(item, TEItemD.projectLineSfId.n);

      const hours = DataRecordF.valueNumber(item, TEItemD.hours.n, 0);
      const invoiced = DataRecordF.valueBoolean(item, TEItemD.invoiced.n);

      const date = DataRecordF.valueDate(item, TEItemD.teDate.n) || new Date();
      const costRate = DataRecordF.valueNumber(item, TEItemD.costRate.n, 0);
      const billingRate = DataRecordF.valueNumber(item, TEItemD.billingRate.n, 0);

      const domains: { [type: string]: string } = {};
      domains.resourceId = resourceId ?? '';
      domains.projectId = projectId ?? '';
      domains.invoiced = String(invoiced);

      const values: { [type: string]: number } = {};
      values.hours = hours;
      values.revenue = invoiced ? billingRate * hours : 0;
      values.costs = costRate * hours;

      const source: TimeLineStackedSource = {
        date,
        domains,
        values
      };
      this.timeSources.push(source);
    });
    this.setTimeSourceResource();
  } // loadTeItems

  /**
   * Set Projects - create domainLabelMap
   * @param projects all projects
   */
  protected setProjects(projects: DataRecordI[]): void {
    super.setProjects(projects); // clone + load pProjectId
    const dl: { [key: string]: string } = {};
    projects.forEach((pj) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dl[pj.id!] = DataRecordF.codeLabel(pj);
    });
    this.projectsDefinition.domainLabelMap = dl;
  }

  protected setResource(resource: DataRecordI): void {
    super.setResource(resource);
    this.setTimeSourceResource();
  }

  /**
   * Set Sources for Resource
   */
  private setTimeSourceResource(): void {
    if (this.resource) {
      this.timeSourcesResource = this.timeSources
        .filter((src) => {
          // {resourceId: "a0G8000000LNUbbEAH", projectId: "a0F1E00000ovO16UAE", invoiced: "true"}
          return src.domains.resourceId === this.resource?.id;
        });
      this.log.debug('setTimeSourceResource', this.timeSourcesResource.length + ' of ' + this.timeSources.length)();
    } else {
      this.timeSourcesResource = [];
    }
  } // setTimeSourceResource


} // ResourceOverviewComponent
