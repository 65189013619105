import {GraphContainer, GraphEdge, Margin, ProjectLineD, Trl} from 'accorto';
import {BoxLine} from './box-line';
import {PalletLayout} from './pallet-layout';
import {Crate} from './crate';

/**
 * Carton (Container) containing (e.g. ProjectLine)
 * - content line
 * - box lines (e.g. Resource Line)
 * - footer line
 * contained in Crates
 * types: PL
 */
export class Carton extends GraphEdge {

  /** Label (2) Content */
  public subLabel: string = '';
  /** Footer Content */
  public footer: string = '';
  /** Footer right Content */
  public subFooter: string = '';

  /** parent Phase|Project */
  parentCrate: Crate;

  /** The Lines */
  private pLines: BoxLine[] = [];

  /**
   * Carton
   * @param parent Graph Container
   * @param crate parent crate
   * @param x carton x
   * @param y carton y
   * @param w carton width
   * @param h carton height
   * @param margin carton margin
   * @param d carton rounding
   */
  constructor(parent: GraphContainer, crate: Crate,
              x: number = 0, y: number = 0, w: number = 0, h: number = 0, public margin?: Margin, d?: number) {
    super(x, y, w, h, margin, d);
    this.parent = parent;
    this.parentCrate = crate;
  }

  /** date info plan | current */
  get dateInfo(): string {
    return 'Plan ' + this.getDateInfo(PalletLayout.ShowPlan)
      + ' | Current ' + this.getDateInfo(PalletLayout.ShowCurrent);
  }

  /** effort info */
  get effortInfo(): string {
    return this.formatValue(this.getValue(PalletLayout.ShowPlan)) + 'h'
      + ' » ' + this.formatValue(this.getValue(PalletLayout.ShowCurrent)) + 'h';
  } // effortInfo

  /** displayed lines */
  get lines(): BoxLine[] {
    const lines = this.pLines.filter((line) => {
      return line.show;
    });
    // sort lines by plan|current, startMs
    lines.sort((one, two) => {
      let cmp = one.type.localeCompare(two.type); // C|P
      if (cmp === 0) {
        cmp = one.startMs ? one.startMs : 0 - two.startMs ? two.startMs : 0;
      }
      return cmp;
    });
    return lines;
  }

  /** override 0 width */
  get ww(): number {
    const w = super.ww;
    if (w < 25) {
      return 25;
    }
    return w;
  }

  /**
   * Find / create Line with type/id
   * @param type the type
   * @param id the id (can be undefined)
   */
  public findCreateLine(type: string,
                        id: string | undefined | null): BoxLine {
    const theId = id ?? '';
    let ll = this.pLines.find((l) => {
      return l.type === type && l.id === theId;
    });
    if (!ll) {
      ll = new BoxLine().setTypeId(type, theId);
      ll.label = type === PalletLayout.LineTypeCurrent ? 'Current' : 'Plan';
      this.pLines.push(ll);
    }
    return ll;
  } // findCreateLine

  /**
   * Date info for type
   * @param type line type P/C
   */
  getDateInfo(type: string): string {
    const start = this.getStartMs(type);
    const end = this.getEndMs(type);
    let info = '-';
    if (start && start !== 0) {
      info = Trl.formatDateUtcMs(start);
    }
    if (end && end !== 0) {
      info += ' → ';
      info += Trl.formatDateUtcMs(end);
    }
    return info;
  }

  /**
   * End Info
   * @param type type P/C
   */
  getEndMs(type: string): number {
    if (this.pLines.length === 0) {
      return this.endMs;
    }
    let endMs: number | undefined = undefined;
    this.pLines.forEach((line) => {
      if (line.type === type) {
        if (endMs === undefined || endMs < line.endMs) {
          endMs = line.endMs;
        }
      }
    });
    return endMs ?? 0;
  } // getEndMs

  /**
   * Start Info
   * @param type type P/C
   */
  getStartMs(type: string): number {
    if (this.pLines.length === 0) {
      return this.startMs;
    }
    let startMs: number | undefined;
    this.pLines.forEach((line) => {
      if (line.type === type) {
        if (startMs === undefined || startMs > line.startMs) {
          startMs = line.startMs;
        }
      }
    });
    return startMs ?? 0;
  } // getStartMs

  /**
   * Get Value
   * @param type line type
   */
  getValue(type: string): number {
    if (this.pLines.length === 0) {
      // console.debug('carton-getValue ' + type + ' 0=' + this.value, this.toString());
      return this.value ? this.value : 0;
    }
    // console.debug('carton-getValue ' + type);
    let value = 0;
    this.pLines.forEach((line) => {
      if (line.type === type) {
        value += line.getValue(PalletLayout.BoxTypeMs);
      } else {
        // console.debug('carton-getValue-line ' + type, line.toString());
      }
    });
    // console.debug('carton-getValue ' + type + ' =' + value, this.toString());
    return value;
  } // getValue

  /**
   * Calculate startMs/endMs, duration, footer (effort)
   * @param showPlanCurrent (P|C|B)
   * @return value min, max, sum
   */
  initStartEnd(showPlanCurrent: string): void {
    // console.debug('carton-initStartEnd(' + showPlanCurrent + ') ' + this, '#' + this.pLines.length);
    if (this.pLines.length > 0) {
      this.startMs = 0;
      this.endMs = 0;
    }
    this.pLines.forEach((line) => {
      line.initStartEnd(showPlanCurrent);
      if (showPlanCurrent === PalletLayout.ShowPlan) {
      }
      if (line.show) {
        if (line.startMs !== 0) {
          if (this.startMs === 0 || this.startMs > line.startMs) {
            this.startMs = line.startMs;
          }
        }
        if (line.endMs !== 0) {
          if (this.endMs === 0 || this.endMs < line.endMs) {
            this.endMs = line.endMs;
          }
        }
      }
    });
    this.setDurationDays();
    this.subFooter = String(this.durationDays);
    //
    this.footer = this.effortInfo;
    if (showPlanCurrent === PalletLayout.ShowCurrent) {
      this.footer += ' - ' + this.getDateInfo(PalletLayout.ShowCurrent);
    } else if (showPlanCurrent === PalletLayout.ShowPlan) {
      this.footer += ' - ' + this.getDateInfo(PalletLayout.ShowPlan);
    } else {
      this.footer += ' - ' + this.dateInfo;
    }
    if (!this.label && !this.id) {
      this.label = '--No Line--';
    }
    // console.debug('carton-initStartEnd(' + showPlanCurrent + ')=' + this, '#' + this.pLines.length, this.effortInfo);
  } // initStartEnd

  /**
   * Create summary info
   * - lines show
   * - startMs/endMs durationDays
   * @param pLayout parameters
   */
  initialize(pLayout: PalletLayout): void {
    // console.debug('carton-initialize(' + pLayout.showPlanCurrent + '|' + pLayout.zoomLevel + ') ' + this, '#' + this.pLines.length);
    this.pLines.forEach((line) => {
      line.initialize(pLayout, this);
    });
    // console.debug('carton-initialize(' + pLayout.showPlanCurrent + '|' + pLayout.zoomLevel + ')=' + this,
    // '#' + this.pLines.length, this.effortInfo);
  } // initialize

  /**
   * Layout Carton (e.g. ProjectLine)
   * @param startY start y position
   * @param pLayout parameters
   * @return last y position
   */
  layout(startY: number, pLayout: PalletLayout): number {
    this.margin = pLayout.marginCarton;
    this.setRadius(2);
    this.stroke = pLayout.colorCarton();
    this.strokeDefault = pLayout.colorCarton();
    //
    this.setXW(pLayout.startMs, pLayout.xDayFactor);
    //
    this.y = startY;
    if (this.show) {
      startY += (this.margin ? this.margin.t : 0);
      // top line
      if (this.label || this.subLabel) {
        startY += pLayout.lineHeight + pLayout.lineGap;
      }
      // lines
      this.pLines.forEach((line) => {
        startY = line.layout(startY, pLayout, this);
        // console.log('layout-carton ' + line, 'show=' + line.show);
        if (line.show) {
          startY += pLayout.lineGap;
        }
      }); // lines

      // footer
      if (this.footer || this.subFooter) {
        startY += pLayout.lineHeight + pLayout.lineGap;
      }
      //
      startY += (this.margin ? this.margin.b : 0);
      this.h = startY - this.y;
    } else {
      this.h = 0;
    }
    return startY;
  } // layout

  /**
   * Set Type and Id
   * @param type the type
   * @param id the id
   * @return this
   */
  setTypeId(type: string, id: string): Carton {
    this.type = type;
    this.id = id;
    return this;
  }

  /** text width */
  wwText(reduce: number): number {
    const w = super.ww - reduce;
    if (w > 0) {
      return w;
    }
    return 0;
  }

  namePrerequisiteId(): string {
    return ProjectLineD.prerequisiteSfId.n;
  }

  namePrerequisiteIdList(): string | undefined {
    return ProjectLineD.prerequisiteIdList.n;
  }

} // Carton
