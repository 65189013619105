import {DataRecordF, DataRecordI, ProjectLineD, ProjectLineSharingD, Trl} from 'accorto';
import {ProjectAllocationInfo} from './project-allocation-info';
import {HeaderInfoDay} from '../header-info-day';

/**
 * Project Line - Table Line
 * - total line
 * - allocation List
 * - add resource line
 */
export class ProjectLineInfo {

  public name: string;
  public label: string;
  public title: string;

  public dateRange: string;

  /** Sum of Allocation Lines (date range) */
  public sum: number = 0;
  /** Sum of Allocation Lines (date range) */
  public total: number = 0;

  public plannedEffort: number = 0;
  public sumDelta: number = 0;

  resourceOptions: DataRecordI[] = [];
  allocationList: ProjectAllocationInfo[] = [];
  /** Project Line Resource */
  resourceId: string | undefined | null;
  /** Allocation Resource id's */
  resourceIdList: string[] = [];

  /** Project Line Record */
  constructor(public pl: DataRecordI) {
    this.name = pl.name ? pl.name : '?';
    this.label = DataRecordF.codeLabel(pl);
    this.title = DataRecordF.codeLabel(pl);
    // project line
    this.resourceId = DataRecordF.value(pl, ProjectLineD.resourceSfId.n);
    this.plannedEffort = DataRecordF.valueNumber(pl, ProjectLineD.plannedEffort.n, 0);
    //
    const from = DataRecordF.valueNumberOpt(pl, ProjectLineD.plannedStart.n);
    const to = DataRecordF.valueNumberOpt(pl, ProjectLineD.plannedEnd.n);
    this.dateRange = Trl.formatDateUtcMs(from) + ' - ' + Trl.formatDateUtcMs(to);
    if (this.dateRange === ' - ') {
      this.dateRange = 'add planned start/end';
    }
  }

  get projectLineSfId(): string | undefined {
    return this.pl.id;
  }

  /**
   * Add Allocation
   * @param alloc allocation
   * @param res resource
   * @return true if duplicate
   */
  addAllocation(alloc: DataRecordI, res: DataRecordI): boolean {
    const resourceId = DataRecordF.value(alloc, ProjectLineSharingD.resourceSfId.n);
    if (resourceId && this.resourceIdList.includes(resourceId)) { // duplicate
      alloc.isActive = false;
      alloc.changeMap[ProjectLineSharingD.isActive.n] = 'false';
      alloc.changeMap[ProjectLineSharingD.description.n] = 'duplicate';
      return true;
    }
    this.resourceIdList.push(resourceId ?? '');
    //
    if (alloc.isActive) {
      this.allocationList.push(new ProjectAllocationInfo(alloc, res, this.pl, resourceId === this.resourceId));
    } else {
      if (resourceId === this.resourceId) { // line resource
        alloc.isActive = true;
        alloc.changeMap[ ProjectLineSharingD.isActive.n ] = 'true';
        this.allocationList.push(new ProjectAllocationInfo(alloc, res, this.pl, true));
      }
    }
    return false;
  } // addAllocation

  /**
   * Build Allocation Day List
   */
  buildDayList(weekly: boolean, headerInfoList: HeaderInfoDay[]): void {
    this.sum = 0;
    this.total = 0;
    for (const alloc of this.allocationList) {
      alloc.buildDayList(weekly, headerInfoList);
      this.sum += alloc.sum;
      this.total += alloc.total;
    }
    this.sumDelta = this.total - this.plannedEffort;
  } // buildDayList

  /**
   * Resource Options (not including already assigned), sort
   * @param resources all resources
   * @return missing allocation for project line resource
   */
  buildResourceOptions(resources: DataRecordI[]): DataRecordI | undefined {
    const resourceIdList: string[] = this.allocationList.map((alloc) => {
      return DataRecordF.value(alloc.allocation, ProjectLineSharingD.resourceSfId.n) ?? '';
    });
    // add missing project line resource allocation
    let resourceAllocation: DataRecordI | undefined;
    if (this.resourceId && !resourceIdList.includes(this.resourceId)) {
      let res: DataRecordI | undefined;
      for (const rr of resources) {
        if (rr.id === this.resourceId) {
          res = rr;
          break;
        }
      }
      if (res) {
        resourceAllocation = DataRecordF.newDataRecord(ProjectLineSharingD);
        resourceAllocation.changeMap[ ProjectLineSharingD.projectLineSfId.n ] = this.projectLineSfId;
        resourceAllocation.changeMap[ ProjectLineSharingD.resourceSfId.n ] = this.resourceId;
        resourceAllocation.details = {};
        this.allocationList.push(new ProjectAllocationInfo(resourceAllocation, res, this.pl, true));
        resourceIdList.push(this.resourceId);
      } else {
        console.warn('ProjectLineInfo.buildResourceOptions * NoResource ' + this.resourceId);
      }
    }
    // filter resource list
    this.resourceOptions = resources.filter((res) => {
      return res.id && !resourceIdList.includes(res.id);
    });
    // console.log('ProjectInfo.buildResourceOptions ' + this.label,
    //   'allocs=' + this.allocationList.length, 'idList=' + idList.length, idList, 'opts=' + this.resourceOptions.length);

    // sort allocations
    this.allocationList.sort((one: ProjectAllocationInfo, two: ProjectAllocationInfo) => {
      return (one.label ? one.label : '').localeCompare(two.label ? two.label : '');
    });

    return resourceAllocation;
  } // buildResourceOptions

  /**
   * Remove Allocation Info
   * @param ai allocation info
   */
  removeAllocation(ai: ProjectAllocationInfo): DataRecordI | undefined {
    const index = this.allocationList.indexOf(ai);
    if (index !== -1) {
      this.allocationList.splice(index, 1);
      this.updateSum();
      return ai.allocation;
    }
    return undefined;
  } // removeAllocation

  /**
   * Update Project Line Sum and Delta
   */
  updateSum(): void {
    this.sum = 0;
    this.total = 0;
    for (const day of this.allocationList) {
      day.updateSum();
      this.sum += day.sum;
      this.total += day.total;
    }
    this.sumDelta = this.total - this.plannedEffort;
  }

} // ProjectInfo
