import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';

import {AccortoService, DataRecordF, DataRecordI, Logger} from 'accorto';
import {AppState} from './reducers';
import {selectCurrentProject, selectProjectsAll} from './project/project.selectors';
import {selectCurrentResource, selectResourcesAll, selectResourcesMap} from './resource/resource.selector';
import {projectSelectedAction} from './project/project.actions';
import {resourceSelectedAction} from './resource/resource.actions';

/**
 * Project Base
 * - load projects, resource map
 * - select/load project
 */
export class PsaBase {

  message: string | undefined = undefined;
  error: string | undefined = undefined;

  project: DataRecordI | undefined;
  pProjectId: string | undefined; // parameter
  pResourceId: string | undefined; // parameter

  showProjectSelect: boolean = false;
  showProjectEdit: boolean = false;
  /** Resource list (mutable) */
  projectRecords: DataRecordI[] = [];
  /** project selection dialog columns */
  projectSelectColumns: string[] = ['name', 'code', 'status'];

  resource: DataRecordI | undefined = undefined;
  showResourceSelect: boolean = false;
  /** resourceId -> Resource (frozen) */
  resourceMap: { [ key: string ]: DataRecordI } = {};
  /** Resource list (mutable) */
  resourceRecords: DataRecordI[] = [];
  /** resource selection dialog columns */
  resourceSelectColumns: string[] = [ 'name', 'code', 'resourceType', 'resourceGroup', 'resourceLocation' ];

  busy: boolean = false;
  busyReset: boolean = true;

  protected log: Logger;
  protected subscriptions: Subscription[] = [];
  private busyCount: number = 0;

  /**
   * @param logName log name
   * @param type 'P'roject 'R'esource
   * @param path path
   * @param title optional title
   * @param route optional route to listen to parameters
   * @param router optional router to update url
   * @param store the store
   * @param conf configuration
   */
  constructor(logName: string,
              protected type: string,
              protected path: string,
              public title: string | undefined,
              protected route: ActivatedRoute | undefined,
              protected router: Router | undefined,
              protected store: Store<AppState>,
              protected conf: AccortoService) {
    this.log = new Logger(logName);

  } // constructor


  // project id
  get projectId(): string | undefined {
    if (this.project) {
      this.pProjectId = this.project.id;
    }
    return this.pProjectId;
  } // projectId

  // project label
  get projectLabel(): string {
    if (this.project) {
      return DataRecordF.codeLabel(this.project);
    }
    return '';
  }

  // project name
  get projectName(): string {
    if (this.project?.name) {
      return this.project.name;
    }
    return 'select project ...';
  }

  // resource id
  get resourceId(): string | undefined {
    if (this.resource) {
      this.pResourceId = this.resource.id;
    }
    return this.pResourceId;
  }

  // project name
  get resourceName(): string {
    if (this.resource?.name) {
      return this.resource.name;
    }
    return 'select resource ...';
  }

  // remove/check busy
  busyMinus(why: string): void {
    // console.debug('busy - ' + why + ' ' + this.busyCount);
    if (this.busyCount > 0) {
      this.busyCount--;
    } else {
      this.busyCount = 0;
    }
    if (this.busyReset) {
      this.busy = (this.busyCount > 0);
    }
  } // busyMinus

  // set busy
  busyPlus(why: string): void {
    // console.debug('busy + ' + why + ' ' + this.busyCount);
    this.busyCount++;
    this.busy = true;
  } // busyPlus

  // debug
  isDebug(): boolean {
    return this.conf.isDebug;
  }

  /**
   * Describe - destroy
   */
  public ngDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  } // ngDestroy

  /**
   * Initialize - subscribe: projectAll currentProject resourceMap resourceAll currentResource
   */
  public ngInit(): void {
    // all projects
    this.busyPlus('pj all');
    this.subscriptions.push(this.store.pipe(select(selectProjectsAll))
      .subscribe((records: DataRecordI[]) => { // loaded via resolver
        this.setProjects(records);
        this.busyMinus('pj all');
      }));

    // selected project
    this.busyPlus('pj cur');
    this.subscriptions.push(this.store.pipe(select(selectCurrentProject))
      .subscribe((project) => {
        if (project) {
          this.setProject(project);
        }
        this.busyMinus('pj cur');
      }));

    // resource map
    this.busyPlus('res map');
    this.subscriptions.push(this.store.pipe(select(selectResourcesMap))
      .subscribe((resourceMap) => {
        this.setResourceMap(resourceMap);
        this.busyMinus('res map');
      }));
    // resource list
    this.busyPlus('res all');
    this.subscriptions.push(this.store.pipe(select(selectResourcesAll))
      .subscribe((resources) => {
        this.setResources(resources);
        this.busyMinus('res all');
      }));
    // selected resource
    this.busyPlus('res cur');
    this.subscriptions.push(this.store.pipe(select(selectCurrentResource))
      .subscribe((resource) => {
        if (resource) {
          this.setResource(resource);
        }
        this.busyMinus('res cur');
      }));

    // parameters
    if (this.route) {
      this.subscriptions.push(
        this.route.params.subscribe((params: Params) => {
          const paramId = params.id;
          this.log.debug('ngInit params id=' + paramId + ' ' + this.type)();
          if (paramId) {
            if (this.type === 'P') {
              this.loadProject(paramId);
            } else if (this.type === 'R') {
              this.loadResource(paramId);
            }
          }
        })
      );
    }
  } // ngInit

  /**
   * Header open Project Select
   */
  onProjectSelectClick(): void {
    this.showProjectSelect = true;
  } // onProjectSelectClick

  /**
   * Header: Select Project
   * @param project project or [close(true)=undefined clear(false)=null]
   * @param direct set selection directly - no url update nor action
   */
  onProjectSelected(project: DataRecordI | undefined | null, direct: boolean = false): void {
    this.log.debug('onProjectSelected ' + direct, project)();
    this.showProjectSelect = false;
    if (project !== undefined) { // = null
      if (direct) {
        this.project = project ? project : undefined;
      }
      if (project == null) { // clear url
        this.pProjectId = undefined;
        if (this.router) {
          const u = this.router.createUrlTree([this.path], {});
          this.router.navigateByUrl(u, {replaceUrl: true});
        }
        this.store.dispatch(projectSelectedAction({project: undefined}));
      } else if (!direct) {
        this.store.dispatch(projectSelectedAction({ project: DataRecordF.clone(project) }));
      }
    } // not undefined
  } // onProjectSelected

  /**
   * Header open Resource Select
   */
  onResourceSelectClick(): void {
    this.showResourceSelect = true;
  } // onResourceSelectClick


  /**
   * Header: Select Resource
   * @param resource (null to clear)
   * @param direct set selection directly - no url update nor action
   */
  onResourceSelected(resource: DataRecordI | undefined | null, direct: boolean = false): void {
    this.log.debug('onResourceSelected ' + direct, resource)();
    this.showResourceSelect = false;
    if (resource !== undefined) { // = null
      if (direct) {
        this.resource = resource ? resource : undefined;
      }
      if (resource == null) { // clear url
        this.pResourceId = undefined;
        if (this.router) {
          const u = this.router.createUrlTree([this.path]);
          this.router.navigateByUrl(u, {replaceUrl: true});
        }
        this.store.dispatch(resourceSelectedAction({resource: undefined}));
      } else if (!direct) {
        this.store.dispatch(resourceSelectedAction({ resource: DataRecordF.clone(resource) }));
      }
    } // not undefined
  } // onResourceSelected

  /**
   * Reset
   */
  protected reset(): void {
    this.project = undefined;
    this.resource = undefined;
  }

  /**
   * Select Project
   * @param projectId project id to be loaded
   * @param force loading
   */
  protected loadProject(projectId: string, force: boolean = false): void {
    if (!force && this.project && this.project.id === projectId) {
      return;
    }
    const projects: DataRecordI[] = this.projectRecords
      .filter((project: DataRecordI) => {
        return project.id === projectId;
      });
    if (projects && projects.length > 0) {
      this.log.debug('loadProject', projects[0])();
      const pp = DataRecordF.clone(projects[0]); // locks
      this.store.dispatch(projectSelectedAction({ project: pp }));
    } else if (this.projectRecords.length > 0) {
      this.log.debug('loadProject', 'NotFound=' + projectId, '#' + this.projectRecords.length)();
    }
    this.pProjectId = projectId; // in case projectRecords are not loaded yet
  } // loadProject

  /**
   * Set Projects and load pProjectId if set
   * @param projects projects
   */
  protected setProjects(projects: DataRecordI[]): void {
    this.log.debug('setProjects', projects)();
    this.projectRecords = DataRecordF.cloneArray(projects);
    if (this.pProjectId) {
      this.loadProject(this.pProjectId);
    }
  } // setProjects

  /**
   * Select Resource
   * @param resourceId resource id to be loaded
   * @param force loading
   */
  protected loadResource(resourceId: string, force: boolean = false): void {
    if (!force && this.resource && this.resource.id === resourceId) {
      return;
    }
    const resources: DataRecordI[] = this.resourceRecords
      .filter((resource: DataRecordI) => {
        return resource.id === resourceId;
      });
    if (resources && resources.length > 0) {
      this.log.debug('loadResource', resources[0])();
      const rr = DataRecordF.clone(resources[0]); // locks
      this.store.dispatch(resourceSelectedAction({ resource: rr }));
    } else if (this.resourceRecords.length > 0) {
      this.log.debug('loadResource', 'NotFound=' + resourceId, '#' + this.resourceRecords.length)();
    }
    this.pResourceId = resourceId; // in case resourceRecords are not loaded yet
  } // loadProject

  /**
   * Set Resource Map
   * @param resourceMap resourceId -> Resource record
   */
  protected setResourceMap(resourceMap: { [key: string]: DataRecordI }): void {
    this.log.debug('setResourceMap', resourceMap)();
    this.resourceMap = resourceMap;
  }

  /**
   * Set Resources
   * @param resources resource list
   */
  protected setResources(resources: DataRecordI[]): void {
    this.log.debug('setResources', resources)();
    this.resourceRecords = DataRecordF.cloneArray(resources) // sorted
      .map((record) => {
        record.isSelected = true;
        if (!record.label) {
          record.label = record.name;
        }
        return record;
      });
    if (this.pResourceId) {
      this.loadResource(this.pResourceId);
    }
  } // setResources

  /**
   * Set Current Project
   * @param project new project called via effect - selectCurrentProject
   * @param updateRoute update route if necessary
   */
  protected setProject(project: DataRecordI, updateRoute: boolean = true): void {
    const oldId = this.project ? this.project.id : '';
    const newId = project ? project.id : '';
    this.log.info('setProject', 'change=' + (oldId !== newId), project)();
    if (oldId !== newId) {
      this.reset();
    }
    this.project = DataRecordF.clone(project);

    // const locId = location.pathname.replace(this.path, '').replace('/', '');
    // update path parameter if set - { queryParams: { id: project.id } }
    if (this.type === 'P' && updateRoute && this.router && project && project.id) {
      if (!location.pathname.includes(project.id)) {
        this.log.debug('setProject url', 'from=' + location.pathname, newId)();
        const u = this.router.createUrlTree([ this.path + '/' + project.id ]);
        this.router.navigateByUrl(u, { replaceUrl: true });
      }
    }
  } // setProject

  /**
   * Set Resource
   * @param resource new resource
   * @param updateRoute upudate route (default: true)
   */
  protected setResource(resource: DataRecordI, updateRoute: boolean = true): void {
    const oldId = this.resource ? this.resource.id : '';
    const newId = resource ? resource.id : '';
    this.log.info('setResource', 'change=' + (oldId !== newId), resource)();
    if (oldId !== newId) {
      this.reset();
    }
    this.resource = DataRecordF.clone(resource);

    // const locId = location.pathname.replace(this.path, '').replace('/', '');
    // update path parameter if set - { queryParams: { id: project.id } }
    if (updateRoute && this.type === 'R' && this.router && resource && resource.id) {
      if (!location.pathname.includes(resource.id)) {
        this.log.debug('setResource url', 'from=' + location.pathname, newId)();
        const u = this.router.createUrlTree([ this.path + '/' + resource.id ]);
        this.router.navigateByUrl(u, { replaceUrl: true });
        // this.location.replaceState(path, '', {...state, navigationId: id});
      }
    }
  } // setResource

} // PsaBase

