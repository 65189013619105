import { createFeatureSelector, createSelector } from '@ngrx/store';
import { invoiceFeatureKey, InvoiceState } from './invoice.reducer';
import { DataRecordI } from 'accorto';

// the State
export const selectInvoiceState = createFeatureSelector<InvoiceState>(invoiceFeatureKey);


export const selectInvoiceLines = createSelector(
  selectInvoiceState,
  (state): DataRecordI[] => state.invoiceLines
);

