/**
 * Time Source Data
 */
export interface TimeSourceI {

  // date
  readonly date: Date;

  // values
  values: { [ type: string ]: number };

  // Data Point
  no?: number;
}

/**
 * Functions
 */
export class TimeSourceF {

  static readonly HOURS_T = 'hoursT';
  static readonly HOURS_B = 'hoursB';
  static readonly COSTS = 'costs';
  static readonly REVENUE = 'revenue';

  /**
   * Add Value to Time source
   * @param source time source
   * @param typeT total type
   * @param value total value
   * @param typeB billable type
   * @param billable true if billable
   */
  static add(source: TimeSourceI, typeT: string, value: number,
             typeB?: string, billable: boolean = false): void {
    if (source && typeT && value) {
      source.values[typeT] = value;
      if (typeB && billable) {
        source.values[typeB] = value;
      }
    }
  } // add

  /**
   * Get/Create Time source
   * @param date the date
   */
  static create(date: Date): TimeSourceI {
    const ts: TimeSourceI = {
      date,
      values: {}
    };
    return ts;
  }

  /**
   * Get value of type
   */
  static get(source: TimeSourceI, type: string): number {
    if (source && type) {
      const value = source.values[ type ];
      if (value) {
        return value;
      }
    }
    return 0;
  }


} // TimeSourceF
