import { createFeatureSelector, createSelector } from '@ngrx/store';
import { teItemFeatureKey, TeItemState } from './te-item.reducer';
import { DataRecordI } from 'accorto';

// the State
export const selectTeItemState = createFeatureSelector<TeItemState>(teItemFeatureKey);

// DataRecord[]
export const selectProjectTeItems = createSelector(
  selectTeItemState,
  (state): DataRecordI[] => state.projectItems
);


export const selectResourceTeItems = createSelector(
  selectTeItemState,
  (state): DataRecordI[] => state.resourceItems
);
