/**
 * Rectangle
 * https://dxr.mozilla.org/mozilla-beta/source/toolkit/modules/Geometry.jsm
 */
import { Point2d } from './point2d';

export class Rect {

  constructor(public x: number = 0, public y: number = 0, public w: number = 0, public h: number = 0) {
  }

  // y + h
  get bottom(): number {
    return this.y + this.h;
  }

  // x + (w/2)
  get center(): number {
    return this.x + (this.w / 2);
  }

  get centerBottom(): Point2d {
    return new Point2d(this.center, this.bottom);
  }

  get centerTop(): Point2d {
    return new Point2d(this.center, this.top);
  }

  // x
  get left(): number {
    return this.x;
  }

  get leftMiddle(): Point2d {
    return new Point2d(this.left, this.middle);
  }

  // y + (h/2)
  get middle(): number {
    return this.y + (this.h / 2);
  }

  // x + w
  get right(): number {
    return this.x + this.w;
  }

  get rightMiddle(): Point2d {
    return new Point2d(this.right, this.middle);
  }

  // y
  get top(): number {
    return this.y;
  }

  toString(): string {
    return '[' + this.x + ',' + this.y + ' ' + this.w + 'x' + this.h + ']';
  }

} // Rect
