import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DateUtil } from '../utils/date-util';
import { Logger } from '../log/logger';
import { Trl } from '../utils/trl';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

/**
 * Date Form Element
 * add to component
 * - class="slds-form-element slds-dropdown-trigger"
 *    | slds-form-element_stacked | slds-form-element_horizontal
 * Value:
 * - (a) set value via theDate - listen to dateSelected
 * - (b) set value in form
 */
@Component({
  selector: 'acc-date-element',
  templateUrl: './date-element.component.html',
  styleUrls: ['./date-element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateElementComponent implements OnChanges, OnDestroy {

  @Input() theId: string = 'date';
  @Input() theName: string = 'date';
  @Input() theTitle?: string;

  /** Optional Label with description */
  @Input() label?: string;
  @Input() description?: string;

  @Input() isRequired: boolean = false;
  @Input() isReadOnly: boolean = false;

  /** (a) initial date */
  @Input() theDate?: Date;
  /** (b) submit utc date */
  @Output() dateSelected = new EventEmitter<Date>();

  /** (b) form group */
  @Input() formGroup?: FormGroup;
  /** (b) form control name */
  @Input() controlName?: string;
  /** Dropdown Alignment left|right */
  @Input() align: string = 'slds-dropdown_right';
  //
  @ViewChild('inp', {static: true}) inputElement?: ElementRef;
  //
  errorMessage?: string;
  showDatePicker: boolean = false;
  //
  @HostBinding('class.slds-form-element') formElement: boolean = true;
  @HostBinding('class.slds-has-error') hasError: boolean = false;
  //
  private subscription?: Subscription;
  //
  private log: Logger = new Logger('DateElement');

  constructor() {
  }

  get inputValue(): string {
    if (this.inputElement) {
      return this.inputElement.nativeElement.value;
    }
    return '';
  }

  get valid(): boolean {
    return this.errorMessage == null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.theTitle && this.label) {
      this.theTitle = this.label;
    }
    // (a)
    if (this.theDate) {
      this.setDate(this.theDate);
    }
    // (b)
    if (this.formGroup && this.controlName) {
      this.theName = this.controlName;
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      const ctrl = this.formGroup.get(this.controlName);
      const ms = ctrl ? ctrl.value : undefined;
      if (ms) {
        this.theDate = new Date(ms);
        this.setDate(this.theDate); // initial
      }
      if (ctrl?.valueChanges) {
        this.subscription = ctrl.valueChanges
          .subscribe((value: number) => {
            this.theDate = new Date(value);
            this.log.debug('valueChanges', this.theDate)();
            this.setDate(this.theDate); // updates
          });
      }
    }
  } // ngOnChanges

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onBlur(event: Event): void {
    if (!this.showDatePicker) {
      const newDate = DateUtil.parseDate(this.inputValue);
      this.log.debug('onBlur', this.inputValue, newDate)();
      this.setDate(newDate);
    } else {
      this.log.debug('onBlur showing', this.inputValue)();
    }
  }

  onClearClick(): void {
    this.log.debug('onClearClick ' + this.showDatePicker)();
    this.setDate(undefined);
    this.showDatePicker = true;
  }

  onClick(event: MouseEvent): void {
    if (!this.showDatePicker) {
      // theDate used by DatePicker
      this.theDate = DateUtil.parseDate(this.inputValue);
    }
    this.showDatePicker = !this.showDatePicker; // calls dateSelected
  }

  onDateSelected(utcDate: Date): void {
    this.log.debug('onDateSelected', utcDate ? utcDate.toISOString() : utcDate)();
    this.setDate(utcDate);
    this.emitValue();
  }

  /**
   * KeyUp - parse for errors - update via blur
   */
  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Clear') {
      this.setDate(undefined);
      this.emitValue();
    } else {
      const newDate = DateUtil.parseDate(this.inputValue);
      this.log.debug('onKeyUp ' + event.key, this.inputValue, newDate)();
      if (this.inputValue === '') {
        this.errorMessage = undefined;
      } else if (newDate) {
        this.errorMessage = undefined;
        this.showDatePicker = false;
      } else {
        this.errorMessage = 'Invalid';
        this.showDatePicker = true;
      }
      this.hasError = this.errorMessage != null;
      if (event.key === 'Enter' && !this.errorMessage) {
        this.setDate(newDate);
        this.emitValue();
        if (this.inputElement) {
          this.inputElement.nativeElement.blur();
        }
      }
    }
  } // onKeyUp

  /**
   * Emit Value
   */
  private emitValue(): void {
    // (a)
    this.dateSelected.emit(this.theDate);
    // (b)
    if (this.formGroup) {
      const dateMs = this.theDate ? this.theDate.getTime() : null;
      if (this.controlName) {
        const patch: { [key: string]: number | null } = {};
        patch[this.controlName] = dateMs;
        this.formGroup.patchValue(patch);
      }
    }
  } // emitValue

  /**
   * Set Date - update control (and display)
   * - called when blur, DatePicker (inside)
   */
  private setDate(newDate?: Date): void {
    // this.log.debug('setDate', newDate)();
    this.showDatePicker = false;
    this.theDate = newDate;
    this.errorMessage = undefined;
    if (this.inputElement) {
      const ele = this.inputElement.nativeElement as HTMLInputElement;
      ele.value = newDate ? Trl.formatDateUtc(this.theDate) : '';
    }
    if (!newDate && this.isRequired) {
      this.errorMessage = 'required';
    }
    this.hasError = this.errorMessage != null;
  } // setDate

} // DateElementComponent
