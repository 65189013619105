import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs/operators';

import {
  itemLoadProjectRequestAction,
  itemLoadProjectResultAction,
  itemLoadResourceRequestAction,
  itemLoadResourceResultAction
} from './te-item.actions';
import { TeItemService } from './te-item.service';


@Injectable()
export class TeItemEffects {


  loadProjectTeItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemLoadProjectRequestAction),
      concatMap((action) => this.service.loadProjectItems(action.projectId)),
      map(result => itemLoadProjectResultAction({ items: result }))
    ), {}
  );

  loadResourceTeItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(itemLoadResourceRequestAction),
      concatMap((action) => this.service.loadResourceItems(action.resourceId, action.dateFrom, undefined)),
      map(result => itemLoadResourceResultAction({ items: result }))
    ), {}
  );


  constructor(private actions$: Actions,
              private service: TeItemService) {
  }

} // TeItemEffects
