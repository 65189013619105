import {DataRecordF, DataRecordI, GraphContainer, GraphVertex, Margin, Trl} from 'accorto';
import {PalletLayout} from './pallet-layout';
import {Crate} from './crate';
import {BoxLine} from './box-line';

/**
 * Pallet (summary) managing Crates containing Cartons containing Box Lines containing Boxes,
 * collapsible, e.g. Project, Resource
 * types = PJ | R
 */
export class Pallet extends GraphContainer {

  /** footer info */
  public footer: string = '';
  /** footer info */
  public subFooter: string = '';

  /** Expanded */
  public isExpanded: boolean = true;

  /** List of Crates */
  private pCrates: Crate[] = [];

  /** Summary Lines */
  private sumLines: BoxLine[] = [];

  /**
   * Pallet
   * @param x x pallet header
   * @param y y pallet header
   * @param w w pallet header
   * @param h h pallet header
   * @param margin margin pallet header
   */
  constructor(x: number = 0, y: number = 0, w: number = 0, h: number = 0, public margin?: Margin) {
    super(x, y, w, h, margin, 0);
  }

  /**
   * Visible Creates
   */
  public get crates(): Crate[] {
    const cc = this.pCrates.filter((c) => c.show);
    // sort crates by startMs
    cc.sort((one, two) => {
      // let cmp = one.type.localeCompare(two.type); // PJ
      return one.startMs ? one.startMs : 0 - two.startMs ? two.startMs : 0;
    });
    return cc;
  } // crates

  /**
   * The links which can be drawn
   */
  public get links(): GraphVertex[] {
    return this.linkList;
  }

  /** date info plan | current */
  get dateInfo(): string {
    return 'Plan ' + this.getDateInfo(PalletLayout.ShowPlan)
      + ' | Current ' + this.getDateInfo(PalletLayout.ShowCurrent);
  }

  /** effort info */
  get effortInfo(): string {
    return this.formatValue(this.getValue(PalletLayout.ShowPlan)) + 'h'
      + ' » ' + this.formatValue(this.getValue(PalletLayout.ShowCurrent)) + 'h';
  } // effortInfo

  public get isCollapsed(): boolean {
    return !this.isExpanded;
  }

  /**
   * Summaries
   */
  public get summaries(): BoxLine[] {
    const ss = this.sumLines.filter((s) => s.show);
    // sort by type
    ss.sort((one, two) => {
      return one.type.localeCompare(two.type);
    });
    return ss;
  } // summaries

  /**
   * Add Summary Value
   * @param value the value
   * @param timeMs time in ms
   * @param type value type, e.g. PalletLayout.LineTypeCurrent
   */
  addSummaryValue(value: number, timeMs: number, type: string): void {
    if (value) {
      const theLine = this.findCreateLine(type); // line for resource
      theLine.addValueMs(value, timeMs);
    }
  } // addSummaryValue

  /**
   * Find / create Cate with type/id
   * @param type the type
   * @param id the id (can be undefined)
   * @param record data record
   */
  public findCreateCrate(type: string, id: string | undefined | null,
                         record: DataRecordI | undefined): Crate {
    const theId = id ?? '';
    let cc = this.pCrates.find((c) => {
      return c.type === type && c.id === theId;
    });
    if (!cc) {
      cc = new Crate(this).setTypeId(type, theId);
      this.pCrates.push(cc);
    }
    if (record && !cc.record) {
      cc.record = record;
      cc.name = record.name ?? '';
      cc.label = DataRecordF.codeLabel(record);
    }
    return cc;
  }

  /**
   * Date info for type
   * @param type line type P/C
   */
  getDateInfo(type: string): string {
    const start = this.getStartMs(type);
    const end = this.getEndMs(type);
    let info = '-';
    if (start && start !== 0) {
      info = Trl.formatDateUtcMs(start);
    }
    if (end && end !== 0) {
      info += ' → ';
      info += Trl.formatDateUtcMs(end);
    }
    return info;
  }

  /**
   * End Info
   * @param type type P/C
   */
  getEndMs(type: string): number {
    if (this.pCrates.length === 0) {
      return this.endMs;
    }
    let endMs: number | undefined;
    this.pCrates.forEach((crate) => {
      const end = crate.getEndMs(type);
      if (endMs === undefined || endMs < end) {
        endMs = end;
      }
    });
    return endMs ?? 0;
  } // getEndMs

  /**
   * Start Info
   * @param type type P/C
   */
  getStartMs(type: string): number {
    if (this.pCrates.length === 0) {
      return this.startMs;
    }
    let startMs: number | undefined;
    this.pCrates.forEach((crate) => {
      const start = crate.getStartMs(type);
      if (startMs === undefined || startMs > start) {
        startMs = start;
      }
    });
    return startMs ?? 0;
  } // getStartMs

  /**
   * Get Value
   * @param type line type
   */
  getValue(type: string): number {
    if (this.pCrates.length === 0) {
      return this.value ? this.value : 0;
    }
    let v = 0;
    this.pCrates.forEach((crate) => {
      v += crate.getValue(type);
    });
    return v;
  } // getValue

  /**
   * Calculate startMs/endMs, duration, footer (effort)
   * @param showPlanCurrent (P|C|B)
   */
  initStartEnd(showPlanCurrent: string): void {
    this.startMs = 0;
    this.endMs = 0;
    this.pCrates.forEach((crate) => {
      crate.initStartEnd(showPlanCurrent);
      if (crate.startMs) {
        if (this.startMs === 0 || this.startMs === 0 || this.startMs > crate.startMs) {
          this.startMs = crate.startMs;
        }
      }
      if (crate.endMs) {
        if (this.endMs === 0 || this.endMs < crate.endMs) {
          this.endMs = crate.endMs;
        }
      }
    });
    this.setDurationDays();
    this.subFooter = String(this.durationDays);
    //
    this.footer = this.effortInfo;
    if (showPlanCurrent === PalletLayout.ShowCurrent) {
      this.footer += ' - ' + this.getDateInfo(PalletLayout.ShowCurrent);
    } else if (showPlanCurrent === PalletLayout.ShowPlan) {
      this.footer += ' - ' + this.getDateInfo(PalletLayout.ShowPlan);
    } else {
      this.footer += ' - ' + this.dateInfo;
    }
    // console.debug('pallet-initStartEnd(' + showPlanCurrent + ')=' + this, this.effortInfo);
  } // initStartEnd

  /**
   * Create summary info
   * - startMs/endMs durationDays effortInfo
   * @param pLayout parameters
   */
  initialize(pLayout: PalletLayout): void {
    for (const crate of this.pCrates) {
      crate.initialize(pLayout);
    }
    for (const sum of this.sumLines) {
      sum.initialize(pLayout, this);
    }
  } // initialize

  /**
   * Layout Pallet (e.g. Project, Resource)
   * @param startY start y position
   * @param palletLayout parameters
   * @return last y position
   */
  layout(startY: number, palletLayout: PalletLayout): number {
    this.setXW(palletLayout.startMs, palletLayout.xDayFactor);
    // y
    this.y = startY;
    if (this.show) {
      if (this.label) {
        //  startY += palletLayout.lineHeight + palletLayout.lineGap;
      }
      // Summaries
      for (const sum of this.sumLines) {
        startY = sum.layout(startY, palletLayout, this);
      }
      startY += palletLayout.lineHeight + palletLayout.lineGap;
      this.h = startY - this.y;

      // Crates
      for (const crate of this.pCrates) {
        if (this.isExpanded) {
          startY = crate.layout(startY, palletLayout);
        } else {
          crate.y = startY;
          crate.h = 0;
        }
      }
    } else {
      this.h = 0;
    }
    return startY;
  } // layout

  /**
   * Set Type and Id
   * @param type the type
   * @param id the id
   * @return this
   */
  setTypeId(type: string, id: string): Pallet {
    this.type = type;
    this.id = id;
    return this;
  }

  /**
   * Find Summary line
   * @param type type C|P
   */
  private findCreateLine(type: string): BoxLine {
    let ll = this.sumLines.find((l) => {
      return l.type === type;
    });
    if (!ll) {
      ll = new BoxLine().setTypeId(type, '');
      ll.label = this.labelName + ': '
        + (type === PalletLayout.LineTypeCurrent ? 'Current' : 'Plan') + ' Summary';
      this.sumLines.push(ll);
    }
    return ll;
  } // findCreateLine

} // Pallet
