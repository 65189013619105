import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {AccortoService, CRequestDataI, CResponseDataI, DataOperation, DataRecordI, Logger, ServiceBase} from 'accorto';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends ServiceBase {

  private log: Logger = new Logger('InvoiceService');


  constructor(private http: HttpClient,
              private config: AccortoService) {
    super();
  }

  loadInvoiceLines(projectId: string): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'InvoiceLine',
      operation: DataOperation.QUERY,
      dataView: {
        name: '',
        whereList: [
          {
            columnName: 'projectId',
            value: projectId
          }
        ]
      },
      params: {}
    };
    return this.send(request);
  } // loadInvoice

  loadInvoices(projectId: string): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'Invoice',
      operation: DataOperation.QUERY,
      dataView: {
        name: '',
        whereList: [
          {
            columnName: 'projectId',
            value: projectId
          }
        ]
      },
      params: {}
    };
    return this.send(request);
  } // loadInvoice

  /**
   * Send/Query - return DataRecords
   * @param request request to send
   */
  send(request: CRequestDataI): Observable<DataRecordI[]> {
    return this.submit(request).pipe(
      map(response => response.records ? response.records : [])
    );
  } // sendI

  /**
   * Submit Request - return response
   * @param request request
   */
  submit(request: CRequestDataI): Observable<CResponseDataI> {
    const start = new Date();
    const url = this.config.server + '/data';
    this.log.info('submit ' + url + ' ' + request.tableName, request)();
    this.config.setCRequestI(request);
    const body = JSON.stringify(request);
    //
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseDataI>(url, body, httpOptions)
      .pipe(
        tap(response => {
          const msg = this.markI(start, response, response.tableName);
          this.log.info('submit.response ' + msg)();
          this.markSuccess(start);
        }),
        catchError((err) => {
          this.log.error('submit', err)();
          const error: CResponseDataI = {
            error: 'Connection Error'
          };
          if (err instanceof HttpErrorResponse) {
            error.error = err.message;
          }
          return of(error);
        })
      );
  } // submit

} // InvoiceService
