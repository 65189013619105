import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {PalletLayout} from '../draw/pallet-layout';
import {Workbench} from './workbench';
import {GraphVertex, Logger, NotificationService} from 'accorto';
import {Carton} from '../draw/carton';
import {Pallet} from '../draw/pallet';
import {Crate} from '../draw/crate';

/**
 * Common Workbench Display
 */
@Component({
  selector: 'psa-workbench',
  templateUrl: './workbench.component.html',
  styleUrls: ['./workbench.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkbenchComponent implements OnChanges {

  @Input() layout: PalletLayout = new PalletLayout(0);
  @Input() wb: Workbench = new Workbench();
  @Input() version: number = 0;

  /** Clicked on Project Line box */
  @Output() projectLineClicked = new EventEmitter<Carton>();
  /** Clicked on GraphVertex box */
  @Output() linkClicked = new EventEmitter<GraphVertex>();

  /** svg top g element */
  workbenchG?: SVGGElement;
  /** tooltip g element */
  tooltip?: SVGGElement;
  /** tooltip text element */
  tooltipText?: SVGTextElement;
  /** tooltip rect element */
  tooltipRect?: SVGRectElement;

  /** Selected Carton */
  selectedCarton?: Carton;
  /** Selected Carton */
  selectedLink?: GraphVertex;

  private log: Logger = new Logger('WorkbenchC');
  /** Zoom Scale */
  private zommScale: number = 1;

  constructor(private note: NotificationService) {
  }

  /**
   * SVG Pan
   * @param direction L|U|D|R
   */
  doPan(direction: string): void {
    this.log.info('doPan', direction)();
    let dx: number = 0;
    let dy: number = 0;
    if (direction === 'R') {
      dx = Math.round(this.layout.pageWidth * 0.10);
    } else if (direction === 'L') {
      dx = -Math.round(this.layout.pageWidth * 0.10);
    } else if (direction === 'U') {
      dy = 100;
    } else if (direction === 'D') {
      dy = -100;
    }
    this.layout.svgPan(dx, dy);
  } // doPan

  /**
   * SVG
   * @param inOut I|O
   */
  doZoomFactor(inOut: string): void {
    const direction = (inOut === 'I' ? 0.1 : -0.1);
    const scale = this.zommScale + direction;
    this.layout.svgZoom(scale);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.log.setSubName(this.wb.getSubName());
    // this.log.debug('ngOnChanges', this.wb)();
  }

  /**
   *  (lint should have mouseout and blur)
   */
  onBlur(event: Event): void {
    // console.debug('onBlur', event.target);
  }

  /**
   * Click Project Line
   * @param carton project line
   * @param crate phase
   * @param pallet project
   */
  onClickCarton(carton: Carton, crate: Crate, pallet: Pallet): void {
    this.log.info('onClickCarton', carton)();
    if (this.selectedCarton) {
      if (this.selectedCarton.id === carton.id) { // click on same
        carton.setSelect(false);
        this.selectedCarton = undefined;
      } else { // click on other
        // create line
        const subject = 'GraphVertex ' + this.selectedCarton.label + ' -> ' + carton.label;
        const msg = pallet.addLink(this.selectedCarton, carton);
        if (msg.startsWith(pallet.MSG_ADDED)) {
          this.note.addInfo(subject, msg);
          // TODO save link
        } else {
          this.note.addWarning(subject, msg, undefined, 15);
        }
        this.selectedCarton.setSelect(false);
        this.selectedCarton = undefined;
      }
    } else { // nothing selected
      carton.setSelect(true);
      this.selectedCarton = carton;
    }
    // set cursor
  } // onClickCarton

  /**
   * Double Click Project Line
   * @param carton project line
   */
  onClickDoubleCarton(carton: Carton): void {
    this.log.info('onClickDoubleCarton', carton)();
    this.projectLineClicked.emit(carton);
  }

  /**
   * Click on GraphVertex - select/de-select
   * @param link the link
   * @param parent parent pallet
   */
  onClickLink(link: GraphVertex, parent: Pallet): void {
    this.log.info('onClickLink', link)();
    if (link.isSelected) {
      link.setSelect(false);
      this.selectedLink = undefined;
    } else {
      link.parent = parent;
      link.setSelect(true);
      this.selectedLink = link;
    }
    this.linkClicked.emit(this.selectedLink);
  } // onClickLink

  /**
   * Mouse Move - show tool tip
   * - content from data-display
   * @param event mouse event
   */
  onMouseMove(event: MouseEvent): void {
    // find info
    let info: string | undefined;
    let carton: SVGGElement | undefined;
    let target = event.target as SVGElement;
    let debugInfo = '';
    for (let i = 0; i < 6; i++) {
      // info
      if (!info && target.dataset.display) {
        info = target.dataset.display;
        debugInfo += 'Info' + i;
      }
      if (target instanceof SVGGElement) {
        if (!carton) {
          if (target.classList.contains('w-carton')) {
            carton = target;
            debugInfo += 'Carton' + i;
          }
        } // carton
        if (target.id === 'workbench') {
          if (!this.workbenchG) {
            this.workbenchG = target;
            debugInfo += 'Wb' + i;
            const tts = this.workbenchG.getElementsByClassName('tooltip');
            for (const tt of Array.from(tts)) {
              if (tt.id === 'tooltip') {
                this.tooltip = tt as SVGGElement;
                debugInfo += 'Tooltip';
                for (const cc of Array.from(this.tooltip.children)) {
                  if (cc instanceof SVGRectElement) {
                    this.tooltipRect = cc;
                    debugInfo += 'Rect';
                  } else if (cc instanceof SVGTextElement) {
                    this.tooltipText = cc;
                    debugInfo += 'Text';
                  }
                }
              } // tooltip
            }
          } // set workbench
          break;
        } // workbench
      } // g
      if (info && this.tooltipText) {
        break;
      }
      target = target.parentNode as SVGElement;
    }
    // console.debug('onMouseMove', info, group); // , event, event.target);
    if (info && this.tooltipText && this.workbenchG) {
      const CTM = this.workbenchG.getScreenCTM(); // a(1) b | c d(1) | e(dx) f(dy)
      if (CTM) {
        const x = (event.clientX - CTM.e - 10) / CTM.a; // cursor start
        const y = (event.clientY - CTM.f + 16) / CTM.d;
        const translate = 'translate(' + x + ' ' + y + ')';
        if (this.tooltip) {
          this.tooltip.setAttribute('transform', translate);
          this.tooltip.setAttribute('visibility', 'visible');
        }
      }
      this.tooltipText.textContent = info;
      const rect = this.tooltipText.getBBox();
      if (this.tooltipRect) {
        this.tooltipRect.setAttribute('width', String(rect.width + 20));
      }
      // console.debug('--', info, CTM, x, y, rect);
    }
    // if (debugInfo.length > 0) {
    //  this.log.debug('onMouseMove', debugInfo)();
    // }
  } // onMouseMove

  /**
   * Mouse Out - hide tool tip
   */
  onMouseOut(event: MouseEvent): void {
    // console.debug('onMouseOut', event.target);
    if (this.tooltip) {
      this.tooltip.setAttribute('visibility', 'hidden');
    }
  } // onMouseOut

  /**
   * @param zoomLevel A|D|W|M
   */
  setZoomLevel(zoomLevel: string): void {
    this.log.info('setZoomLevel', zoomLevel)();
    this.layout.zoomLevel = zoomLevel;
    this.layout.layout(this.wb);
  }

} // WorkbenchComponent
