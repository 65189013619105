import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, filter, map, withLatestFrom} from 'rxjs/operators';
import {ProjectService} from './project.service';
import {
  projectAddAction,
  projectAllocationsLoadResultAction,
  projectAllocationsSaveRequestAction,
  projectAllocationsSaveResultAction,
  projectLineDeleteRequestAction,
  projectLineDeleteResultAction,
  projectLineSaveRequestAction,
  projectLineSaveResultAction,
  projectLinesLoadResultAction,
  projectLinesSaveRequestAction,
  projectLinesSaveResultAction,
  projectLoadAllRequestAction,
  projectLoadAllResultAction,
  projectPhasesLoadResultAction,
  projectSaveRequestAction,
  projectSaveResultAction,
  projectSelectedAction,
} from './project.actions';
import {itemLoadProjectRequestAction} from '../te-item/te-item.actions';
import {invoiceLoadRequestAction} from '../invoice/invoice.actions';
import {NotificationService} from 'accorto';
import {Store} from '@ngrx/store';
import {AppState} from '../reducers';
import {selectCurrentProject} from './project.selectors';


@Injectable()
export class ProjectEffects {

  // -- project load
  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectLoadAllRequestAction),
      concatMap(action => this.service.loadProjects()),
      map(projects => projectLoadAllResultAction({ projects }))
    ), {}
  );


  // -- project selected -- (1) lines
  selectProjectLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectSelectedAction),
      map(action => action.project?.id),
      filter(projectId => !!projectId),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      concatMap(projectId => this.service.loadProjectLines(projectId!)),
      map(projectLines => projectLinesLoadResultAction({projectLines}))
    ), {}
  );

  // -- project selected -- (2) phases
  selectProjectPhases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectSelectedAction),
      map(action => action.project?.id),
      filter(projectId => !!projectId),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      concatMap(projectId => this.service.loadProjectPhases(projectId!)),
      map(projectPhases => projectPhasesLoadResultAction({projectPhases}))
    ), {}
  );

  // -- project selected -- (3) line allocations
  selectProjectAllocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectSelectedAction),
      map(action => action.project?.id),
      filter(projectId => !!projectId),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      concatMap(projectId => this.service.loadProjectAllocations(projectId!)),
      map(projectAllocations => projectAllocationsLoadResultAction({projectAllocations}))
    ), {}
  );

  // -- project selected -- (4) items
  selectProjectItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectSelectedAction),
      map(action => action.project?.id),
      filter(projectId => !!projectId),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map(projectId => itemLoadProjectRequestAction({projectId: projectId!}))
    ), {}
  );

  // -- project selected -- (5) invoices
  selectProjectInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectSelectedAction),
      map(action => action.project?.id),
      filter(projectId => projectId != null),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map(projectId => invoiceLoadRequestAction({projectId: projectId!}))
    ), {}
  );

  // -- save project
  projectSaveRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectSaveRequestAction),
      concatMap(action => this.service.save(action.project)),
      map(responseData => projectSaveResultAction({ responseData }))
    ), {}
  );
  projectSaveResult1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectSaveResultAction),
      // tap(action => console.log('projectSaveResult$', action)),
      map(action => action.responseData.records),
      filter(records => records != null && records.length > 0),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map(records => projectSelectedAction({project: records![0]}))
    ), {}
  );
  projectSaveResult2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectSaveResultAction),
      map(action => action.responseData.records),
      filter(records => records != null && records.length > 0),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map(records => projectAddAction({project: records![0]}))
    ), {}
  );


  // -- save project line
  projectLineSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectLineSaveRequestAction),
      concatMap(action => this.service.save(action.projectLine)),
      filter(responseData => !responseData.error),
      map(responseData => projectLineSaveResultAction({ responseData }))
    ), {}
  );

  // -- save project lines
  projectLinesSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectLinesSaveRequestAction),
      concatMap(action => this.service.saveList(action.projectLines)),
      map(responseData => projectLinesSaveResultAction({ responseData }))
    ), {}
  );
  // -- save project lines result
  projectLinesSaveResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectLinesSaveResultAction),
      withLatestFrom(this.store.select(selectCurrentProject)),
      concatMap(([ action, project ]) => {
        this.notification.addResponseData('Save Project Lines', action.responseData);
        // console.log('ProjectFacts.projectLinesSaveResult$--', action, project);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.service.loadProjectLines(project!.id!);
      }),
      map(projectLines => projectLinesLoadResultAction({ projectLines }))
    ), {}
  );

  projectLineDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectLineDeleteRequestAction),
      concatMap(action => this.service.delete(action.projectLine)),
      filter(responseData => !responseData.error),
      map(responseData => projectLineDeleteResultAction({ responseData }))
    ), {}
  );
  projectLineDeleteResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectLineDeleteResultAction),
      withLatestFrom(this.store.select(selectCurrentProject)),
      concatMap(([ action, project ]) => {
        this.notification.addResponseData('Deleted Project Line', action.responseData);
        // console.log('ProjectFacts.projectLineDeleteResult$--', action, project);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.service.loadProjectLines(project!.id!);
      }),
      map(projectLines => projectLinesLoadResultAction({ projectLines }))
    ), {}
  );


  // -- save project allocations
  projectAllocationsSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectAllocationsSaveRequestAction),
      concatMap(action => this.service.saveList(action.projectAllocations)),
      map(responseData => projectAllocationsSaveResultAction({ responseData }))
    ), {}
  );
  // -- save project allocations result
  projectAllocationsSaveResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectAllocationsSaveResultAction),
      withLatestFrom(this.store.select(selectCurrentProject)),
      concatMap(([ action, project ]) => {
        this.notification.addResponseData('Save Allocations', action.responseData);
        console.log('ProjectFacts.projectAllocationsSaveResult$--', action, project);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.service.loadProjectAllocations(project!.id!);
      }),
      map(projectAllocations => projectAllocationsLoadResultAction({ projectAllocations }))
    ), {}
  );


  /**/


  constructor(private actions$: Actions,
              private service: ProjectService,
              private store: Store<AppState>,
              private notification: NotificationService) {
  }

} // ProjectEffects
