import {Box, DateUtil, Margin} from 'accorto';
import {PalletLayout} from './pallet-layout';

/**
 * Line of boxes, e.g. Resource Line
 * contained in Cartons
 * types:  LineTypePlan | LineTypeCurrent
 * id: null | resource id
 * detailIds: TEItems
 * -- box --
 * types: ms | T
 * id: ms
 */
export class BoxLine extends Box {

  /** Detail ids */
  public detailIds: string[] = [];
  /** Overwrite StartMs */
  tempStartMs?: number;
  /** The list of boxes */
  private pBoxes: Box[] = [];
  /** Calculated values */
  private calculated: string[] = [];

  /**
   * List of boxes
   * @param x x position of line
   * @param y y default for boxes (and line)
   * @param wD width default for boxes
   * @param h h default for boxes (snd line)
   * @param marginD margin default for boxes
   * @param d rounding default for boxes (and line)
   */
  constructor(x: number = 0, y: number = 0, wD: number = 0, h: number = 0, private marginD?: Margin, d?: number) {
    super(x, y, 0, h, undefined, d);
  }

  /* Boxes sorted, showing */
  public get boxes(): Box[] {
    if (this.type) {
      this.sortAll();
      const bb = this.pBoxes.filter((box) => {
        return box.show;
      });
      let btypes = '';
      bb.forEach((b) => {
        btypes += ' ' + b.type + b.value;
      });
      // console.debug('line ' + this.type, btypes, bb);
      return bb;
    } else {
      return this.pBoxes;
    }
  } // boxes

  // add box
  add(box: Box): void {
    this.pBoxes.push(box);
    this.calculated = [];
  }

  // add detail id
  addDetailId(detailId: string): void {
    this.detailIds.push(detailId);
  }

  /**
   * Add Value (Box)
   * @param value value
   * @param ms time
   */
  addValueMs(value: number, ms: number): void {
    const box = this.findCreateBox(PalletLayout.BoxTypeMs, ms ? String(ms) : '');
    box.addValue(value);
    if (ms) {
      box.startMs = ms;
      if (this.startMs === undefined || this.startMs === 0 || this.startMs > ms) {
        this.startMs = ms;
      }
      if (this.endMs === undefined || this.endMs < ms) {
        this.endMs = ms;
      }
      // console.debug('addValueMs ' + this, ' v=' + value + ' ms=' + ms + ' s=' + this.startMs, 'box=' + box);
    }
  } // addValueMs

  /**
   * Find/create Box
   * @param type e.g. ms
   * @param id e.g. ms
   */
  findCreateBox(type: string, id: string): Box {
    let bb = this.pBoxes.find((b) => {
      return b.type === type && b.id === id;
    });
    if (!bb) {
      bb = new Box().setTypeId(type, id);
      this.add(bb);
    }
    return bb;
  }

  /**
   * Get Value of boxes
   */
  getValue(type: string): number {
    if (this.pBoxes.length === 0) { // no detail record
      return this.value ? this.value : 0;
    }
    let value = 0;
    let count = 0;
    const ignoredTypes: string[] = [];
    this.pBoxes.forEach((box) => {
      if (type === box.type) {
        count++;
        if (box.value) {
          value += box.value;
        }
      } else {
        if (!ignoredTypes.includes(box.type)) {
          ignoredTypes.push(box.type);
        }
      }
    });
    if (count === 0) { // no detail record of type
      value = this.value ? this.value : 0;
    }
    // console.debug('line-getValue ' + type + ' count=' + count + '/' + this.pBoxes.length, ignoredTypes, value);
    return value;
  } // getValue

  /**
   * Calculate startMs/endMs, duration, show (level 0)
   * @param showPlanCurrent (P|C|B)
   */
  initStartEnd(showPlanCurrent: string): void {
    // show - level 0
    if (showPlanCurrent === PalletLayout.ShowBoth) {
      this.show = true;
    } else {
      this.show = showPlanCurrent === this.type;
    }
    const retValue = [0, 0, 0]; // min,max,sum
    this.pBoxes.forEach((box) => {
      box.show = this.show;
      if (box.value) {
        if (retValue[0] > box.value) {
          retValue[0] = box.value;
        }
        if (retValue[1] < box.value) {
          retValue[1] = box.value;
        }
        retValue[2] += box.value;
      }
    });
    if (this.pBoxes.length === 0 && this.value) {
      if (retValue[ 0 ] > this.value) {
        retValue[ 0 ] = this.value;
      }
      if (retValue[ 1 ] < this.value) {
        retValue[ 1 ] = this.value;
      }
      retValue[ 2 ] += this.value;
    }
    this.setDurationDays();
    // console.debug('line-initStartEnd(' + showPlanCurrent + ') ' + this, '#' + this.pBoxes.length);
  } // initStartEnd

  /**
   * Create summary info
   * - show
   * - min/max value
   * @param pLayout parameters
   */
  initialize(pLayout: PalletLayout, parent: Box): void {
    if (pLayout.showResourceLines) {
      // showPlanCurrent (P|C|B)
      if (pLayout.showPlanCurrent === PalletLayout.ShowBoth) {
        this.show = true;
      } else {
        this.show = pLayout.showPlanCurrent === this.type;
      }
    } else {
      this.show = false;
    }
    // day-week-month
    if (pLayout.zoomLevel === PalletLayout.ZoomLevelDay) {
      this.pBoxes.forEach((box) => {
        box.show = box.type === PalletLayout.BoxTypeMs || box.type === PalletLayout.BoxTypeTotal;
      });
    } else if (pLayout.zoomLevel === PalletLayout.ZoomLevelWeek) {
      if (!this.calculated.includes(PalletLayout.BoxTypeWeek)) {
        this.calculateWeek(parent.startMs, parent.endMs);
      }
      this.pBoxes.forEach((box) => {
        box.show = box.type === PalletLayout.BoxTypeWeek || box.type === PalletLayout.BoxTypeTotal;
      });
    } else { // month
      if (!this.calculated.includes(PalletLayout.BoxTypeMonth)) {
        this.calculateMonth(parent.startMs, parent.endMs);
      }
      this.pBoxes.forEach((box) => {
        box.show = box.type === PalletLayout.BoxTypeMonth || box.type === PalletLayout.BoxTypeTotal;
      });
    }
    // this.pBoxes.forEach((box) => {
    //   console.debug('line-initialize ' + box);
    // });
    //
    // console.debug('line-initialize(' + showPlanCurrent + '|' + zoomLevel + ')=' + this, '#' + this.pBoxes.length, 'show=' + this.show);
    if (this.show) {
      this.pBoxes.forEach((box) => {
        pLayout.addValue(box.type, box.value); // min/max
      });
    }
  } // initialize

  /**
   * Layout Line
   * @param startY start y position
   * @param pLayout parameters
   * @param parent parent (width updated)
   * @return last y position
   */
  layout(startY: number, pLayout: PalletLayout, parent: Box): number {
    this.setXW(pLayout.startMs, pLayout.xDayFactor);
    this.marginD = pLayout.marginLine;
    //
    this.y = startY;
    if (this.show) {
      this.h = pLayout.lineHeight;
      // Handle (plan) Totals
      if (this.pBoxes.length === 0 && this.value) {
        const bb = new Box().setTypeId(PalletLayout.BoxTypeTotal, '');
        bb.value = this.value;
        // no start but value
        if (this.type === PalletLayout.LineTypePlan && !this.startMs && this.value) {
          this.tempStartMs = parent.startMs ? parent.startMs : DateUtil.today().getTime();
          // console.debug('layout-line-0 ' + this, pLayout.startMs, pLayout.xDayFactor);
        }
        this.add(bb);
      }
      if (this.tempStartMs) { // no start but value
        let startDays = 0;
        if (pLayout.startMs) {
          this.tempStartMs = parent.startMs ? parent.startMs : pLayout.startMs;
          startDays = Math.trunc((this.tempStartMs - pLayout.startMs) / DateUtil.ONEDAY);
        }
        this.x = Math.round(startDays * pLayout.xDayFactor);
        this.w = pLayout.xDayFactor; // 1 day
        if (parent.w < this.w) {
          parent.w = this.w; // make carton bigger too
        }
        // console.debug('layout-line-1 ' + this, 'pLayoutStartMs=' + pLayout.startMs, 'cartonStartMs=' + carton.startMs,
        // 'startDays=' + startDays);
      }
      // console.debug('---', this.pBoxes);
      this.pBoxes.forEach((box) => {
        box.fill = pLayout.colorBox(this.type, box.type, box.value);
        box.setXW(pLayout.startMs, pLayout.xDayFactor);

        const ms = box.id ? Number(box.id) : 0;
        if (ms && !Number.isNaN(ms)) {
          //  const startDays = Math.trunc((ms - pLayout.startMs) / DateUtil.ONEDAY);
          //  box.x = Math.round(startDays * pLayout.xDayFactor);
          //  box.w = pLayout.xDayFactor;
        } else {
          box.x = this.x;
          box.w = this.w;
          // console.log('line-layout=0 ' + box); // Total
        }
        //
        box.y = startY;
        box.h = pLayout.lineHeight;
        // value label
        box.label = this.formatValue(box.value);
        box.margin = this.marginD;
        box.stroke = box.fill;
      }); // box

      if (this.w === 0 && this.pBoxes.length === 0) {
        //  console.log('layout-line w0 ' + this, this.pBoxes);
        this.show = false;
        this.h = 0;
      }
    } else { // not show
      this.h = 0;
    }
    // console.log('layout-line X ' + this, this.pBoxes);
    return startY + this.h;
  } // layout

  /**
   * Set Type and Id
   * @param type the type
   * @param id the id
   * @return this
   */
  setTypeId(type: string, id: string): BoxLine {
    this.type = type;
    this.id = id;
    return this;
  }

  /** Calculate Month summaries */
  private calculateMonth(startMs: number, endMs: number): void {
    const newBoxes: Box[] = [];
    this.sortAll();
    let newMonth: Box;
    this.pBoxes.forEach((box) => {
      if (box.type === PalletLayout.BoxTypeMs) {
        newBoxes.push(box);
        const monthStart: number = this.getMonthStart(box.startMs, startMs);
        if (!newMonth || newMonth.startMs !== monthStart) {
          newMonth = new Box().setTypeId(PalletLayout.BoxTypeMonth, String(monthStart));
          newMonth.startMs = monthStart;
          newMonth.endMs = this.getMonthEnd(monthStart, endMs);
          newMonth.setDurationDays();
          newBoxes.push(newMonth);
        }
        newMonth.addValue(box.value);
        newMonth.addStartEndMs(box.startMs, box.endMs);
        // console.log('calculateMonth ' + newMonth);
      } else if (box.type !== PalletLayout.BoxTypeMonth) {
        newBoxes.push(box);
      }
    });
    // console.debug('calculateMonth #' + this.pBoxes.length + '->' + newBoxes.length, newBoxes);
    this.pBoxes = newBoxes;
    this.calculated.push(PalletLayout.BoxTypeMonth);
  } // calculateMonth

  /** Calculate Week summaries */
  private calculateWeek(startMs: number, endMs: number): void {
    const newBoxes: Box[] = [];
    this.sortAll();
    let newWeek: Box;
    this.pBoxes.forEach((box) => {
      if (box.type === PalletLayout.BoxTypeMs) {
        newBoxes.push(box);
        const weekStart: number = this.getWeekStart(box.startMs, startMs);
        if (!newWeek || newWeek.startMs !== weekStart) {
          newWeek = new Box().setTypeId(PalletLayout.BoxTypeWeek, String(weekStart));
          newWeek.startMs = weekStart;
          newWeek.endMs = this.getMonthEnd(weekStart, endMs);
          newWeek.setDurationDays();
          newBoxes.push(newWeek);
        }
        newWeek.addValue(box.value);
        newWeek.addStartEndMs(box.startMs, box.endMs);
      } else if (box.type !== PalletLayout.BoxTypeWeek) {
        newBoxes.push(box);
      }
    });
    // console.debug('calculateWeek #' + this.pBoxes.length + '->' + newBoxes.length, newBoxes);
    this.pBoxes = newBoxes;
    // this.pBoxes.forEach((box) => {
    //   console.debug('calculateWeek ' + box);
    // });
    this.calculated.push(PalletLayout.BoxTypeWeek);
  } // calculateWeek

  /** Month End or last */
  private getMonthEnd(startMs: number, parentEndMs: number): number {
    const dd = new Date(startMs);
    const mm = dd.getUTCMonth(); // 0..11
    const last = new Date(Date.UTC(dd.getUTCFullYear(), mm + 1, 0)); // last
    const lastMs = last.getTime();
    return lastMs > parentEndMs ? parentEndMs : lastMs;
  } // getMonthEnd

  /** Month Start or first */
  private getMonthStart(ms: number, parentStartMs: number): number {
    const dd = new Date(ms);
    const first = new Date(Date.UTC(dd.getUTCFullYear(), dd.getUTCMonth(), 1));
    const firstMs = first.getTime();
    return firstMs < parentStartMs ? parentStartMs : firstMs;
  } // getMonthStart

  /** Week End or last */
  private getWeekEnd(startMs: number, parentEndMs: number): number {
    const dd = new Date(startMs);
    const last = DateUtil.toEndOfWeek(dd); // 0..6 Sun..Sat
    const lastMs = last.getTime();
    return lastMs > parentEndMs ? parentEndMs : lastMs;
  } // getWeekEnd;

  /** Week Start or first */
  private getWeekStart(ms: number, parentStartMs: number): number {
    const dd = new Date(ms);
    const first = DateUtil.toStartOfWeek(dd); // 0..6 Sun..Sat
    const firstMs = first.getTime();
    return firstMs < parentStartMs ? parentStartMs : firstMs;
  } // getWeekStart

  /** sorted all by type and startMs */
  private sortAll(): void {
    this.pBoxes.sort((one, two) => {
      let cmp = one.type.localeCompare(two.type); // C|P
      if (cmp === 0) {
        cmp = (one.startMs ? one.startMs : 0) - (two.startMs ? two.startMs : 0);
      }
      return cmp;
    });
  } // sort

} // BoxLine
