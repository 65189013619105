/**
 * Create Ticket
 * https://developers.freshdesk.com/api/#create_ticket
 */
export class FreshdeskTicket {

  public name: string = '';
  public email: string = '';
  public subject: string = '';
  public description: string = '';

  // Mobihelp=8
  public source: number = 8;
  public type: string = 'Question';

  // Open=2
  public status: number = 2;
  // Low=1
  public priority: number = 1;
  // eslint-disable  @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public email_config_id: number = 0;
  public product_id: number = 0;
  public group_id: number = 0;

}

export class Freshdesk {

  static ACCORTO = new Freshdesk('https://support.accorto.com',
    1000069005, 1000005253, 1000190831,
    'Accorto');

  static TRACK4D = new Freshdesk('https://support.track4d.com',
    1000073967, 1000007024, 1000198975,
    'Track 4D');

  static CHECK4SF = new Freshdesk('https://support.checklist4sf.com',
    1000073916, 1000006993, 1000198974,
    'Checklist for Sf');

  static PICK4D = new Freshdesk('https://support.pick4d.com',
    1000073917, 1000007006, 1000198976,
    ''); // Accorto Shift

  constructor(
    public url: string,
    public email_config_id: number,
    public product_id: number,
    public group_id: number,
    public category: string) {
  }

  createTicket(): FreshdeskTicket {
    const ticket = new FreshdeskTicket();
    ticket.email_config_id = this.email_config_id;
    ticket.product_id = this.product_id;
    ticket.group_id = this.group_id;
    return ticket;
  }

}
