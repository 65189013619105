import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {
  AccortoService,
  CRequestDataI,
  CResponseDataI,
  DataOperation,
  DataRecordI,
  Logger,
  NotificationService,
  ServiceBase,
  WhereOp
} from 'accorto';

@Injectable({
  providedIn: 'root'
})
export class TeItemService extends ServiceBase {

  private log: Logger = new Logger('TeItemService');


  constructor(private http: HttpClient,
              private config: AccortoService,
              private note: NotificationService) {
    super();
  }

  /**
   * Load Items grouped by date
   * @param projectIds project ids
   * @param resourceIds resource ids (ignored)
   */
  loadItems(projectIds: string[], resourceIds: string[] | undefined): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'TEItem',
      operation: DataOperation.QUERY,
      dataView: {
        name: '',
        whereList: []
      },
      params: {}
    };
    //
    if (request.dataView?.whereList) {
      if (projectIds) {
        if (projectIds.length === 1) {
          request.dataView.whereList.push(
            {
              columnName: 'projectId',
              value: projectIds[0]
            }
          );
        } else if (projectIds.length > 1) {
          request.dataView.whereList.push(
            {
              columnName: 'projectId',
              op: WhereOp.IN,
              valueList: projectIds
            }
          );
        }
      }
      // CALENDAR_YEAR(accorto__TE_Date__c), CALENDAR_MONTH(accorto__TE_Date__c), WEEK_IN_YEAR(accorto__TE_Date__c),
      request.dataView.selectColumns = ['SUM(hours)', 'SUM(x1)', 'SUM(x2)', 'SUM(x3)', 'SUM(x4)', 'SUM(x5)', 'SUM(x6)', 'SUM(x7)',
        'MAX(billingRate)', 'MAX(costRate)'];
      request.dataView.groupBys = ['teDate', 'projectSfId', 'projectLineSfId', 'invoiced', 'resourceSfId'];
      request.dataView.orderBys = ['teDate'];
    }
    request.limit = 2000;

    return this
      .submit(request, 'QueryS')
      .pipe(
        tap((response) => {
          if (response.recordCount === 2000) {
            console.log('loadItems', response);
            this.note.addWarning('T+E Item data incomplete',
              'Displaying only info from first 2000 records of ' + response.totalCount);
          }
        }),
        map(response => response.records ? response.records : [])
      );
  } // loadItems

  /**
   * Load all items for Project
   * @param projectId the project id
   */
  loadProjectItems(projectId: string): Observable<DataRecordI[]> {
    return this.loadItems([ projectId ], undefined);
  }

  /**
   * Load Item for Resource(s) grouped by date
   * @param resourceId optional resource id
   * @param dateFromMs optional ms date from
   * @param dateToMs optional ms date to
   */
  loadResourceItems(resourceId: string | undefined,
                    dateFromMs: number | undefined, dateToMs: number | undefined): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'TEItem',
      operation: DataOperation.QUERY,
      dataView: {
        name: '',
        whereList: []
      },
      params: {}
    };
    if (request.dataView?.whereList) {
      // resource
      if (resourceId && resourceId.length > 0) {
        request.dataView.whereList.push(
          {
            columnName: 'resourceSfId',
            value: resourceId
          }
        );
      }
      // date
      if (dateFromMs && dateFromMs > 0) {
        request.dataView.whereList.push(
          {
            columnName: 'teDate',
            op: WhereOp.GE,
            value: String(dateFromMs)
          }
        );
      }
      if (dateToMs && dateToMs > 0) {
        request.dataView.whereList.push(
          {
            columnName: 'teDate',
            op: WhereOp.LE,
            value: String(dateToMs)
          }
        );
      }
      // CALENDAR_YEAR(accorto__TE_Date__c), CALENDAR_MONTH(accorto__TE_Date__c), WEEK_IN_YEAR(accorto__TE_Date__c),
      request.dataView.selectColumns = ['SUM(hours)', 'SUM(x1)', 'SUM(x2)', 'SUM(x3)', 'SUM(x4)', 'SUM(x5)', 'SUM(x6)', 'SUM(x7)',
        'MAX(billingRate)', 'MAX(costRate)'];
      request.dataView.groupBys = ['teDate', 'projectSfId', 'invoiced', 'resourceSfId'];
      request.dataView.orderBys = ['teDate'];
    }
    request.limit = 2000;

    return this
      .submit(request, 'QueryS')
      .pipe(
        tap((response) => {
          if (response.recordCount === 2000) {
            console.log('loadResourceItems', response);
            this.note.addWarning('T+E Item data incomplete',
              'Displaying only info from first 2000 records of ' + response.totalCount);
          }
        }),
        map(response => response.records ? response.records : [])
      );
  } // loadResourceItems


  /**
   * Send/Query - return DataRecords
   * @param request request to send
   */
  send(request: CRequestDataI): Observable<DataRecordI[]> {
    return this
      .submit(request, 'Query')
      .pipe(
        map(response => response.records ? response.records : [])
      );
  } // sendI

  /**
   * Submit Request - return response
   * @param request request
   * @param type info
   */
  submit(request: CRequestDataI, type: string): Observable<CResponseDataI> {
    const start = new Date();
    const url = this.config.server + '/data';
    this.log.info('submit ' + url + ' ' + request.tableName, request)();
    this.config.setCRequestI(request);
    const body = JSON.stringify(request);
    //
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseDataI>(url, body, httpOptions)
      .pipe(
        tap(response => {
          const msg = this.markI(start, response, response.tableName);
          if (response.error) {
            this.note.addResponseData('T+E Item ' + type + ' failed', response);
            this.markError(start);
            this.log.info('submit.response', response)();
          } else {
            this.log.info('submit.response ' + msg)();
            this.markSuccess(start);
          }
        }),
        catchError((err) => {
          this.log.error('submit', err)();
          const error: CResponseDataI = {
            error: 'Connection Error'
          };
          if (err instanceof HttpErrorResponse) {
            error.error = err.message;
          }
          this.note.addError('T+E Item ' + type + ' error', error.error);
          return of(error);
        })
      );
  } // submit

} // TeItemService
