import { DateUtil, Trl } from 'accorto';

/**
 * Header Cell
 */
export class HeaderInfoDay {

  label: string;
  title: string;
  isWeekend: boolean;

  /**
   * Header Cell
   * @param ms the date
   * @param isWeekly is this weekly
   * @param isWeekStartDay week start day (for weekend)
   */
  constructor(public ms: number, public isWeekly: boolean, public isWeekStartDay: boolean) {
    if (isWeekly) {
      this.label = Trl.formatDate(new Date(ms), true,
        { year: '2-digit', month: '2-digit', day: 'numeric' });
      this.isWeekend = false;
    } else {
      this.label = Trl.formatDate(new Date(ms), true,
        { month: '2-digit', day: 'numeric' });
      const dd = new Date(ms);
      this.isWeekend = DateUtil.isWeekend(dd);
    }
    this.title = Trl.formatDate(new Date(ms), true,
      { year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' });
  }

  toString(): string {
    return JSON.stringify(this);
  }

} // HeaderInfoDay
