import { createAction, props } from '@ngrx/store';
import { DataRecordI } from 'accorto';


export const invoiceLoadRequestAction = createAction(
  '[Invoice] Load Project Request',
  props<{ projectId: string }>()
);

export const invoiceLoadResultAction = createAction(
  '[Invoice/api] Loaded Results',
  props<{ invoiceLines: DataRecordI[] }>()
);
