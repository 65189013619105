import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DataRecordI } from '../model/data-record-i';
import { DataRecordF } from '../utils/data-record-f';
import { Logger } from '../log/logger';

@Component({
  selector: 'acc-data-record-selector',
  templateUrl: './data-record-selector.component.html',
  styleUrls: [ './data-record-selector.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class DataRecordSelectorComponent implements OnChanges {

  /** The records to be selected from */
  @Input() records: DataRecordI[] = [];
  /** The column/property names to display */
  @Input() columnNames: string[] = [];

  /** Callback with selected record */
  @Output() recordSelected = new EventEmitter<DataRecordI>();

  summary: string | undefined;

  private log: Logger = new Logger('DataRecordSelector');

  constructor() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.records) {
      try {
        for (const record of this.records) {
          try {
            record.isSelected = true;
          } catch (ex) {
            this.log.warn('ngOnChange record=' + record.name, ex, record,
              'frozen=' + Object.isFrozen(record), 'extensible=' + Object.isExtensible(record),
              'sealed=' + Object.isSealed(record))();
          }
        }
      } catch (ex2) {
        this.log.warn('ngOnChange records', ex2, this.records, this.columnNames)();
      }
      this.summary = '#' + this.records.length;
    } else {
      this.log.info('ngOnChange NoRecords', this.records, this.columnNames)();
    }
  }

  /**
   * Get Record Value
   */
  getValue(record: DataRecordI, propertyName: string): string | undefined | null {
    return DataRecordF.value(record, propertyName);
  }

  onClick(record: DataRecordI): void {
    this.recordSelected.emit(record);
  }

  onKeyUp(event: KeyboardEvent): void {
    const source = event.target as HTMLInputElement;
    const term = source.value;
    // console.log(term);
    const searchTerm = term.toLocaleLowerCase();
    let selectedCount = 0;
    this.records.forEach((record) => {
      let selected = false;
      if (searchTerm && term.length > 0) {
        for (const cn of this.columnNames) {
          const value = DataRecordF.value(record, cn);
          if (value && value.length > 0) {
            if (value.toLocaleLowerCase().includes(searchTerm)) {
              selected = true;
              selectedCount++;
              break;
            }
          }
        }
      } else { // no searchTerm
        selected = true;
        selectedCount++;
      }
      record.isSelected = selected;
    });
    if (selectedCount === this.records.length) {
      this.summary = '#' + this.records.length;
    } else {
      this.summary = String(selectedCount) + '/' + this.records.length;
    }
  } // onKeyUp

} // DataRecordSelectorComponent
