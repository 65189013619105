import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {FormControl, FormGroup} from '@angular/forms';
import {
  AccortoService,
  appStatus,
  DataRecordF,
  DataRecordI,
  DateUtil,
  ModelUtil,
  ProjectLineD,
  ProjectLineSharingD
} from 'accorto';
import {Workbench} from '../workbench';
import {WorkbenchComponent} from '../workbench.component';
import {PsaBase} from '../../psa-base';
import {AppState} from '../../reducers';
import {ProjectWorkbenchComponent} from '../project-workbench/project-workbench.component';
import {selectResourceTeItems} from '../../te-item/te-item.selectors';
import {selectProjectsMap} from '../../project/project.selectors';
import {PalletLayout} from '../../draw/pallet-layout';
import {BoxLine} from '../../draw/box-line';
import {Pallet} from '../../draw/pallet';
import {
  selectCurrentResourceProjectAllocations,
  selectCurrentResourceProjectLines
} from '../../resource/resource.selector';
import {DataPickOption} from '../../../../projects/accorto/src/lib/model/data-pick-option';
import {TEItemD} from '../../../../projects/track4d/src/app/model/t-e-item-i';
import {ProjectService} from '../../project/project.service';

@Component({
  selector: 'psa-resource-workbench',
  templateUrl: './resource-workbench.component.html',
  styleUrls: ['./resource-workbench.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResourceWorkbenchComponent
  extends PsaBase implements OnInit, OnDestroy {

  version: number = 0;

  resourceEffortInfo?: string;
  resourcePlanInfo?: string;
  resourceCurrentInfo?: string;
  resourceCountInfo?: string;
  resourceCountInfoAll?: string;

  /** Graph Containers */
  wb: Workbench = new Workbench();
  layout: PalletLayout = new PalletLayout(0);

  /** Plan, Current, Both */
  showPlanCurrent: string = 'P';

  // Workbench
  @ViewChild(WorkbenchComponent, {static: true}) workbench !: WorkbenchComponent;
  svgForm: FormGroup = new FormGroup({zoomLevel: new FormControl()});
  /** Zoom Options */
  svgZoomOptions: DataPickOption[] = [];

  private projectMap: { [ key: string ]: DataRecordI } = {};
  private projectLineMap: { [ key: string ]: DataRecordI } = {};
  //
  private rawProjectLines: DataRecordI[] = [];
  private rawAllocations: DataRecordI[] = [];
  private rawItems: DataRecordI[] = [];

  //
  constructor(route: ActivatedRoute,
              router: Router,
              store: Store<AppState>,
              conf: AccortoService,
              private elementRef: ElementRef) {
    super('ResourceWorkbench', 'R', '/resource-wb', 'Resource Workbench',
      route, router, store, conf);
    /** Zoom Options */
    this.svgZoomOptions.push(ModelUtil.createOption('A', '-all-'));
    this.svgZoomOptions.push(ModelUtil.createOption('M', 'Month'));
    this.svgZoomOptions.push(ModelUtil.createOption('W', 'Week'));
    this.svgZoomOptions.push(ModelUtil.createOption('D', 'Day'));
    //
    this.showPlanCurrent = ProjectWorkbenchComponent.prefShowPlanCurrent.value ?? 'P';
  } // constructor

  ngOnDestroy(): void {
    super.ngDestroy();
  }

  ngOnInit(): void {
    super.ngInit(); // projectAll currentProject resourceMap resourceAll currentResource

    this.busyPlus('pjMap');
    this.subscriptions.push(this.store.pipe(select(selectProjectsMap))
      .subscribe((pjmap: { [key: string]: DataRecordI }) => {
        this.projectMap = pjmap;
        this.busyMinus('pjMap');
      }));

    // project lines of the selected resource
    this.busyPlus('pl');
    this.subscriptions.push(this.store.pipe(select(selectCurrentResourceProjectLines))
      .subscribe((projectLines) => {
        this.setProjectLines(projectLines); // updateNodes + layout
        this.busyMinus('pl');
      }));

    // planned project allocations of the selected resource
    this.busyPlus('alloc');
    this.subscriptions.push(this.store.pipe(select(selectCurrentResourceProjectAllocations))
      .subscribe((projectAllocations) => {
        this.setAllocations(projectAllocations); // updateNodes + layout
        this.busyMinus('alloc');
      }));

    // actual items of the selected resource
    this.busyPlus('items');
    this.subscriptions.push(this.store.pipe(select(selectResourceTeItems))
      .subscribe((items) => {
        this.setItems(items); // updateNodes + layout
        this.busyMinus('items');
      }));

    this.store.dispatch(appStatus({ status: 'resource-wb' }));
  } // ngOnInit

  onDateFromSelected(dateFrom: Date): void {
    this.log.debug('onDateFromSelected', dateFrom)();

  }

  onDateToSelected(dateTo: Date): void {
    this.log.debug('onDateToSelected', dateTo)();

  }

  /** Change Plan-Current-Both */
  onPlanCurrentClick(value: string): void {
    this.log.debug('onPlanCurrentClick', value)();
    this.showPlanCurrent = value;
    ProjectWorkbenchComponent.prefShowPlanCurrent.save(value);
    this.updateLayout();
  }

  onRefresh(): void {
    this.log.debug('onRefresh')();
    this.wb.reset();
    super.onResourceSelected(this.resource, false);
  }

  onResourceSelected(resource: DataRecordI | undefined | null): void {
    super.onResourceSelected(resource, false);
  }

  /**
   * @param direction L|U|D|R
   */
  onSvgPan(direction: string): void {
    // this.log.info('onSvgPan ' + direction)();
    this.workbench.doPan(direction);
  }

  /**
   * @param inOut zoom I|O
   */
  onSvgZoomFactor(inOut: string): void {
    this.workbench.doZoomFactor(inOut);
  } // onSvgZoom

  /**
   * Change Zoom Level A|D|W|M
   */
  onSvgZoomLevel(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const svgZoomValue = target.value;
    // this.log.info('onSvgZoom ' + svgZoomValue, this.svgForm.controls.zoomLevel.value)();
    this.workbench.setZoomLevel(svgZoomValue);
    // this.svgForm.controls.zoomLevel.setValue(this.layout.zoomLevel);
  } // onSvgZoom

  // called when resource changed
  reset(): void {
    super.reset();
    this.wb.reset();

    this.resourceEffortInfo = undefined;
    this.resourcePlanInfo = undefined;
    this.resourceCurrentInfo = undefined;
    this.resourceCountInfo = undefined;
  } // reset

  /**
   * Set Resource Project Allocations
   * @param allocations allocations
   */
  protected setAllocations(allocations: DataRecordI[]): void {
    this.rawAllocations = allocations;
    this.buildPage('allocations');
  }

  /**
   * Set Resource Actual Items
   * @param items items
   */
  protected setItems(items: DataRecordI[]): void {
    this.rawItems = items ?? [];
    this.buildPage('items');
  }

  /**
   * Set Resource Project Lines
   * @param projectLines lines
   */
  protected setProjectLines(projectLines: DataRecordI[]): void {
    this.rawProjectLines = projectLines;
    this.buildPage('projectLines');
  }

  /**
   * Set Resource (1)
   * @param resource new resource
   */
  protected setResource(resource: DataRecordI): void {
    this.reset();
    super.setResource(resource, true);
    this.log.setSubName(resource ? resource.name : undefined);
    this.wb.setSubName(resource ? resource.name : undefined);
    // update path parameter
    if (resource && resource.id) { // { queryParams: { id: project.id } }
      // Pallet...Box
      this.wb.findCreateResourcePallet(resource.id, resource);
    }
    this.buildPage('resource');
  } // setResource

  /**
   * Build Page
   * @param why change
   */
  private buildPage(why: string): void {
    const countPL = this.buildPage1ProjectLines();
    const countAl = this.buildPage2Allocations();
    const countIt = this.buildPage3Items();

    this.log.debug('buildPage', why,
      'projectLines=' + this.rawProjectLines.length + '/' + countPL,
      'alloc=' + this.rawAllocations.length + '/' + countAl,
      'items=' + this.rawItems.length + '/' + countIt
    )();
    this.updateLayout();
  } // buildPage

  private buildPage1ProjectLines(): number {
    this.projectLineMap = {};
    const today = DateUtil.today();
    //
    let count = 0;
    for (const record of this.rawProjectLines) {
      if (record.name !== ProjectService.PROJECT) {
        const projectLineId = record.id ?? '';
        this.projectLineMap[projectLineId] = record;
        // refs
        const resourceId = DataRecordF.value(record, ProjectLineD.resourceSfId.n);
        let resource: DataRecordI | undefined;
        if (resourceId) {
          resource = this.resourceMap[resourceId];
        }
        const projectId = DataRecordF.value(record, ProjectLineD.projectSfId.n);
        const project: DataRecordI = this.projectMap[projectId ?? ''];
        //
        const value = DataRecordF.valueNumber(record, ProjectLineD.plannedEffort.n, 0);
        const plannedStart = DataRecordF.valueNumberOpt(record, ProjectLineD.plannedStart.n);
        const plannedEnd = DataRecordF.valueNumberOpt(record, ProjectLineD.plannedEnd.n);

        // Pallet..Box
        const pjPallet = this.wb.findCreateResourcePallet(resourceId, resource);
        const ppCrate = pjPallet.findCreateCrate('PJ', projectId, project);
        const plCarton = ppCrate.findCreateCarton('PL', projectLineId, record);
        //
        plCarton.subLabel = '-';
        if (resource) {
          plCarton.subLabel = '(' + DataRecordF.codeLabel(resource) + ')';
        } else {
          plCarton.subLabel = '<' + resourceId + '>';
        }
        // PlanLine
        const planLine: BoxLine = plCarton.findCreateLine(PalletLayout.LineTypePlan, undefined);
        planLine.startMs = plannedStart ? plannedStart : today.getTime();
        planLine.endMs = plannedEnd ? plannedEnd : planLine.startMs + DateUtil.ONEDAY * 5;
        planLine.value = value;
        //
        pjPallet.addSummaryValue(value, planLine.startMs, PalletLayout.LineTypePlan);
        count++;
      }
    } // projectLine
    return count;
  } // buildPage1ProjectLines

  private buildPage2Allocations(): number {
    let count = 0;
    for (const alloc of DataRecordF.cloneArray(this.rawAllocations)) {
      if (!alloc.isActive) {
        continue;
      }
      // refs
      const resourceId = DataRecordF.value(alloc, ProjectLineSharingD.resourceSfId.n);
      if (resourceId !== this.resource?.id) {
        // console.log('allocations res=' + resourceId + '<>' + this.resource.id);
        continue;
      }
      const projectLineId = DataRecordF.value(alloc, ProjectLineSharingD.projectLineSfId.n);
      const projectLine = projectLineId ? this.projectLineMap[projectLineId] : undefined;
      let projectId = DataRecordF.value(alloc, ProjectLineSharingD.projectSfId.n); // 15 of 18 a0F1E00000ovT7x
      if (projectLine) { // use project/phase from project line
        projectId = DataRecordF.value(projectLine, ProjectLineD.projectSfId.n); // 18
      }
      let project = this.projectMap[projectId ?? '']; // 18
      if (projectId != null && projectId.length === 15) {
        for (const pj of Object.values(this.projectMap)) {
          if (pj.id?.substr(0, 15) === projectId) {
            projectId = pj.id; // 18
            project = pj;
            break;
          }
        }
      }
      // detail allocations
      if (!alloc.details) {
        const detailString = DataRecordF.value(alloc, ProjectLineSharingD.plannedDetails.n);
        if (detailString && detailString.length > 0) {
          alloc.details = JSON.parse(detailString);
        } else {
          alloc.details = {};
        }
      }
      const detailList = Object.keys(alloc.details);
      if (detailList.length === 0) {
        // console.log('allocation', alloc);
        continue;
      }
      count++;
      // graph
      const rPallet = this.wb.findCreateResourcePallet(resourceId, this.resource);
      const pjCrate = rPallet.findCreateCrate('PJ', projectId, project);
      pjCrate.showHdr = true;
      const plCarton = pjCrate.findCreateCarton('PL', projectLineId, projectLine);
      const planLine = plCarton.findCreateLine(PalletLayout.LineTypePlan, resourceId);
      planLine.label = DataRecordF.codeLabel(this.resource);
      // plan line data
      for (const key of detailList) {
        if (key.startsWith('d')) {
          const ms = Number(key.substr(1));
          const hh = alloc.details[ key ];
          planLine.addValueMs(hh, ms);
        }
      }
    } // allocation
    return count;
  } // buildPage2Allocations

  private buildPage3Items(): number {
    let count = 0;
    for (const item of this.rawItems) {
      // refs
      const resourceId = DataRecordF.value(item, TEItemD.resourceSfId.n);
      if (resourceId !== this.resource?.id) {
        continue;
      }
      const projectId = DataRecordF.value(item, TEItemD.projectSfId.n);
      const project = this.projectMap[projectId ?? ''];
      const projectLineId = DataRecordF.value(item, TEItemD.projectLineSfId.n);
      const projectLine = this.projectLineMap[projectLineId ?? ''];

      const teDate: number | undefined = DataRecordF.valueNumberOpt(item, TEItemD.teDate.n);
      const hours: number | undefined = DataRecordF.valueNumberOpt(item, TEItemD.hours.n);
      if (!hours) {
        continue;
      }
      count++;
      // graph
      const rPallet = this.wb.findCreateResourcePallet(resourceId, this.resource);
      const pjCrate = rPallet.findCreateCrate('PJ', projectId, project);
      pjCrate.showHdr = true;
      const plCarton = pjCrate.findCreateCarton('PL', projectLineId, projectLine);
      const planLine = plCarton.findCreateLine(PalletLayout.LineTypeCurrent, resourceId);
      planLine.label = DataRecordF.codeLabel(this.resource);

      // individual days
      let xHours: number = 0;
      if (teDate) {
        for (let i = 0; i < 7; i++) {
          const xh = DataRecordF.valueNumberOpt(item, 'x' + (i + 1));
          if (xh) {
            const dd: number = teDate + (i * DateUtil.ONEDAY);
            planLine.addValueMs(xh, dd);
            rPallet.addSummaryValue(xh, dd, PalletLayout.LineTypeCurrent);
            xHours += xh;
          }
        }
      }
      // not normalized
      const otherHours = hours - xHours;
      if (otherHours && teDate) {
        planLine.addValueMs(otherHours, teDate);
        rPallet.addSummaryValue(otherHours, teDate, PalletLayout.LineTypeCurrent);
      }
      //
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      planLine.addDetailId(item.id!);
    } // item
    return count;
  } // buildPage3Items

  /**
   * Set graph.startMs, endMs -  node.info, startMs, endMs
   * + layout
   */
  private updateLayout(): void {
    this.version++;
    //
    const ref = this.elementRef.nativeElement as HTMLElement;
    const pageSize: ClientRect = ref.getBoundingClientRect();
    //
    this.layout = new PalletLayout(pageSize.width);
    this.layout.initialize(this.showPlanCurrent, true, undefined);
    this.layout.layout(this.wb);
    this.svgForm.controls.zoomLevel.setValue(this.layout.zoomLevel);

    // Counts
    this.resourceCountInfoAll = 'Projects: ' + this.projectRecords.length
      + ' Resources: ' + Object.keys(this.resourceMap).length;
    //
    let countProjects = 0;
    let countResources = 0;
    let countProjectLines = 0;
    let countItems = 0;
    for (const pallet of this.wb.pallets) {
      if (pallet.type === 'R') {
        countResources++;
        for (const crate of pallet.crates) {
          if (crate.type === 'PJ') {
            if (crate.id) {
              countProjects++;
            }
            for (const carton of crate.cartons) {
              if (carton.type === 'PL') {
                if (carton.id) {
                  countProjectLines++;
                }
                for (const line of carton.lines) {
                  if (line.type === PalletLayout.LineTypeCurrent) {
                    countItems += line.detailIds.length;
                  }
                } // line
              } // PL
            } // cartons
          } // PP
        } // crates
      } // PJ
    } // pallet
    this.resourceCountInfo = 'Projects: ' + countProjects
      + '; Lines: ' + countProjectLines + '; Items: ' + countItems;

    // single resource info
    this.resourceEffortInfo = undefined;
    this.resourcePlanInfo = undefined;
    this.resourceCurrentInfo = undefined;
    if (this.resource) { // single Resource
      const pp: Pallet = this.wb.findCreateResourcePallet(this.resource.id, this.resource);
      this.resourceEffortInfo = pp.effortInfo;
      this.resourcePlanInfo = pp.getDateInfo(PalletLayout.ShowPlan);
      this.resourceCurrentInfo = pp.getDateInfo(PalletLayout.ShowCurrent);
    }
    if (this.isDebug()) {
      this.log.debug('updateLayout ' + this.version, this.layout, this.wb.pallets)();
    }
  } // updateLayout

} // ResourceWorkbenchComponent
