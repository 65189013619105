import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MenuItem } from '../model/menu-item';

@Component({
  selector: 'acc-app-launcher',
  templateUrl: './app-launcher.component.html',
  styleUrls: [ './app-launcher.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class AppLauncherComponent {

  /** Menu Items to be displayed */
  @Input() menuItems: MenuItem[] = [];
  /** Clicked on Menu Item */
  @Output() menuSelected: EventEmitter<MenuItem> = new EventEmitter();


  constructor() {
  }

  onCloseClick(): void {
    this.menuSelected.emit(undefined);
  }

  onMenuClick(menuItem: MenuItem): void {
    this.menuSelected.emit(menuItem);
  }

}
