import { Box } from './box';
import { GraphVertex } from './graph-vertex';
import { GraphEdge } from './graph-edge';
import { Logger } from '../log/logger';
import { DateUtil } from '../utils/date-util';
import { DataRecordF } from '../utils/data-record-f';

/**
 * Box Graph Container
 * - Box Edges and Links
 */
export class GraphContainer extends Box {

  /** End Time in Days */
  public endTimeDays: number = 0;

  readonly MSG_ADDED = 'Link Added';

  /** List of links  - vertices */
  linkList: GraphVertex[] = [];

  /** List of nodes - edges */
  nodeList: GraphEdge[] = [];
  /** id -> edge */
  nodeMap: { [ key: string ]: GraphEdge } = {};

  protected log: Logger = new Logger('GraphContainer');

  /**
   * Add GraphVertex between cartons
   * @param from start
   * @param to end
   */
  addLink(from: GraphEdge, to: GraphEdge): string {
    if (from.parent?.id !== to.parent?.id) {
      return 'Cannot create - in different Graphs(Projects)';
    }
    // check if exists
    for (const link of this.linkList) {
      if (link.isLink(from, to)) {
        return 'GraphVertex already exists';
      }
      if (link.isLink(to, from)) {
        return 'GraphVertex(back) already exists';
      }
    }
    //
    this.linkList.push(new GraphVertex(this, from, to));
    // Nodes
    from.addDependent(to);
    to.addPrerequisite(from);
    return this.MSG_ADDED; // ok
  } // addLink

  /**
   * Add Node with an id
   * @param node project line
   */
  addNode(node: GraphEdge): void {
    if (node.id) { // skips __project__
      this.nodeList.push(node);
      this.nodeMap[ node.id ] = node;
    }
  }

  /**
   * Calculate
   * - node.durationDays
   * - cycles
   * - endTime, node.startOffset, node.title (prerequisites)
   * @return error message
   */
  calculate(): string|undefined {
    this.log.debug('calculate #' + this.nodeList.length)();
    // reset + durationDays
    this.nodeList.forEach((n) => {
      n.isSelected = false;
      n.title = n.label;
      n.startOffsetDays = undefined; // reset
      //
      n.prerequisiteIds = [];
      n.dependentIds = [];
      n.prerequisiteSet = new Set<string>();
      n.dependentSet = new Set<string>();

      // duration
      if (!n.durationDays) {
        const days = n.getDurationDays();
        if (days) {
          n.durationDays = days;
        }
      }
      // overwrite based on start/end
      if (n.startMs && n.endMs) {
        const durationMs = n.endMs - n.startMs;
        if (durationMs > 0) {
          n.durationDays = (durationMs / DateUtil.ONEDAY) + 1;
        }
      }
      // duration fallback
      if (!n.durationDays) {
        n.durationDays = 1;
      }
      // this.log.debug('calculate ' + n.title + ' dur=' + n.durationDays)();
    });

    // node.prerequisiteSet, node.dependentSet, node.prerequisiteIds
    this.calculatePrerequisites();
    const error = this.hasCycle();
    if (error) {
      return error;
    }
    // endTimeDays, node.startOffsetDays, node.endTimeDays, node.title (prerequisites), sort
    this.calculateRelativeStart();
    return undefined;
  } // calculate

  /**
   * Remove link from Graph
   * @param link the link
   */
  deleteLink(link: GraphVertex): void {
    // graph
    const index = this.linkList.indexOf(link);
    if (index >= 0) {
      this.linkList.splice(index, 1);
    }
    // Nodes
    link.from.deleteDependent(link.to);
    link.to.deletePrerequisite(link.from);
  } // deleteLink

  /**
   * Load Links from nodes
   */
  loadLinks(): void {
    for (const pl of this.nodeList) {
      const pres = pl.getPrerequisiteIdList();
      if (pres && pres.length > 0) {
        const preList: string[] = pres.split(',');
        this.log.debug('loadLinks', preList, pl)();
        for (const pre of preList) {
          const from = this.getNode(pre);
          if (from) {
            this.addLink(from, pl);
          } else {
            this.log.warn('loadLinks', 'NotFound=' + pre, pl)();
          }
        } // prerequisite
      } // prerequisites
    } // node
  } // loadLinks

  getNode(id: string): GraphEdge {
    return this.nodeMap[ id ];
  }

  /**
   * Is there a cycle
   * @param newFromId optional new from id
   * @param newToId optional new to id
   * @return error message or undefined
   */
  hasCycle(newFromId?: string, newToId?: string): string | undefined {
    // https://github.com/mission-peace/interview/blob/master/src/com/interview/graph/CycleInDirectedGraph.java
    const whiteNodeSet = new Set<GraphEdge>(); // new
    const grayIdSet = new Set<string>(); // investigating
    const blackIdSet = new Set<string>(); // all visited
    // fill white
    this.nodeList.forEach((n) => {
      n.dependentIds = [];
      whiteNodeSet.add(n);
    });
    // build dependents from prerequisites
    this.nodeList.forEach((n) => {
      if (n.prerequisiteIds) {
        const thisId = n.id;
        n.prerequisiteIds.forEach((pid) => {
          const node = this.getNode(pid);
          if (node) {
            if (!node.dependentIds) {
              node.dependentIds = [];
            }
            node.dependentIds.push(thisId);
          } else {
            this.log.warn('hasCycle', 'not found id=' + pid)();
          }
        });
      }
    });
    // add new link
    if (newFromId && newToId) {
      const newFromNode = this.getNode(newFromId);
      if (newFromNode) {
        newFromNode.dependentIds.push(newToId);
      } else {
        this.log.warn('hasCycle', 'not found new from id=' + newFromId)();
      }
    }
    // search for cycles
    while (whiteNodeSet.size > 0) {
      const setIter = whiteNodeSet.entries();
      const currentNode: GraphEdge = setIter.next().value[ 0 ];
      if (this.hasCycleDfs(currentNode, whiteNodeSet, grayIdSet, blackIdSet)) {
        // error - found a cycle
        let error: string = 'Invalid cycle';
        grayIdSet.forEach((value, key) => {
          const node = this.getNode(value);
          if (node) {
            error += ' - ' + node.label;
          } else {
            error += ' - ' + value;
          }
        });
        return error;
      }
    }
    return undefined; // ok
  } // hasCycle

  /**
   * de-select all nodes
   */
  unselectAll(): void {
    this.nodeList.forEach((n) => {
      n.isSelected = false;
    });
    this.linkList.forEach((l) => {
      l.isSelected = false;
    });
  } // unselectAll

  /**
   * Calculate Prerequisites
   * - called from calculate() - after reset - before calculateRelativeStart()
   * node.prerequisiteSet, node.dependentSet, node.prerequisiteIds
   */
  private calculatePrerequisites(): void {
    this.nodeList.forEach((n) => {
      const pre = n.getPrerequisiteId();
      if (pre && pre !== '') {
        const preNode = this.getNode(pre);
        if (preNode) {
          preNode.dependentSet.add(n.id);
          n.prerequisiteSet.add(pre);
        } else {
          this.log.info('calculatePrerequisites', 'NotFound prerequisiteSfId=' + pre, n.toStringX())();
        }
      }
      const pres = n.getPrerequisiteIdList();
      if (pres && pres !== '') {
        const parts = pres.split(',');
        for (const pp of parts) {
          const preNode = this.getNode(pp);
          if (preNode) {
            preNode.dependentSet.add(n.id);
            n.prerequisiteSet.add(pres);
          } else {
            this.log.info('calculatePrerequisites', 'NotFound prerequisiteIdList=' + pp, n.toStringX())();
          }
        }
      }
      const dep = n.getDependentId();
      if (dep && dep !== '') {
        const depNode = this.getNode(dep);
        if (depNode) {
          depNode.prerequisiteSet.add(n.id);
          n.dependentSet.add(dep);
        } else {
          this.log.info('calculatePrerequisites', 'NotFound dependentSfId=' + dep, n.toStringX())();
        }
      }
    });

    this.nodeList.forEach((n) => {
      this.updateNodeFromSets(n); // set prerequisiteIds
      // this.log.debug('calculatePrerequisites', n.toStringX())();
    });
  } // calculatePrerequisites

  /**
   * Calculate - called after calculatePrerequisites()
   * - endTimeDays
   * - node.startOffsetDays
   * - node.endTimeDays
   * - node.title (prerequisites)
   * sort
   */
  private calculateRelativeStart(): void {
    // calculate relative start
    this.endTimeDays = 0;
    // prerequisites
    this.nodeList.forEach((n) => {
      n.startOffsetDays = this.getStartOffsetDays(n);
      const end = n.startOffsetDays + n.durationDays; // time units
      if (this.endTimeDays < end) {
        this.endTimeDays = end;
      }
      // info
      n.title += ' start=' + n.startOffsetDays;
      if (n.prerequisiteIds && n.prerequisiteIds.length > 0) {
        let prefix = '; prerequisites=';
        n.prerequisiteIds.forEach((id) => {
          const node = this.getNode(id);
          n.title += prefix;
          if (node) {
            n.title += node.label;
          } else {
            n.title += id;
          }
          prefix = ', ';
        });
      }
      // console.debug('- ' + n.label + ' ' + n.title);
      // this.log.debug('calculateRelativeStart ' + n.title, n)();
    });
    if (this.startMs && this.endMs) {
      const ms = this.endMs - this.startMs;
      if (ms > 0) {
        const days = ms / DateUtil.ONEDAY;
        if (this.endTimeDays < days) {
          this.endTimeDays = days;
        }
      }
    }
    this.log.debug('calculateRelativeStart', 'endDays=' + this.endTimeDays)();

    // node sequence
    this.nodeList.sort((a: GraphEdge, b: GraphEdge) => {
      // (1) prerequisite|dependent
      if (this.isPrerequisite(a, b)) {
        //  console.debug(this.toStringSort(a, b, '<<', '(1)')); // a comes before b
        return -1;
      }
      if (this.isPrerequisite(b, a)) {
        //  console.debug(this.toStringSort(a, b, '>>', '(2)')); // b comes before a
        return 1;
      }

      // dependents
      if (a.dependentIds && a.dependentIds.length > 0) { // a has dependents
        //  console.debug(this.toStringSort(a, b, '[[', '(1d)')); // a comes before b
        return -1;
      }
      if (b.dependentIds && b.dependentIds.length > 0) {
        //  console.debug(this.toStringSort(a, b, ']]', '(2d)'));
        return 1;
      }

      if (a.prerequisiteIds && a.prerequisiteIds.length > 0) {
        //  console.debug(this.toStringSort(a, b, '{{', '(1p)'));
        return -1;
      }
      if (b.prerequisiteIds && b.prerequisiteIds.length > 0) {
        //  console.debug(this.toStringSort(a, b, '}}', '(2p)'));
        return 1;
      }

      // start time
      const sa = a.startOffsetDays ? a.startOffsetDays : 0;
      const sb = b.startOffsetDays ? b.startOffsetDays : 0;
      if (sa < sb) {
        //  console.debug(this.toStringSort(a, b, ' <t', '(1t)')); // a comes before b
        return -1;
      }
      if (sa > sb) {
        //  console.debug(this.toStringSort(a, b, 't>', '(2t)')); // b comes before a
        return 1;
      }
      // alpha
      const la = a.label;
      const lb = b.label;
      // console.debug(this.toStringSort(a, b, '--', 'a=' + la));
      return la.localeCompare(lb);
    }); // nodes.sort

  } // calculateRelativeStart

  /**
   * Get Start Offset Days
   * @param node start node
   * @return offset days
   */
  private getStartOffsetDays(node: GraphEdge): number {
    let startOffsetDays: number = 0;
    if (node.prerequisiteIds) {
      node.prerequisiteIds.forEach((id) => {
        const pre = this.getNode(id);
        if (pre) {
          // console.debug(this.toString(node) + ' pre: ' + this.toString(pre) + '(' + pre.startOffsetDays + ')')
          let so = 0;
          if (pre.startOffsetDays) {
            so = pre.startOffsetDays;
          } else {
            so = this.getStartOffsetDays(pre);
          }
          if (pre.durationDays) {
            so += pre.durationDays;
          } else {
            so += .1;
          }
          if (so > startOffsetDays) {
            startOffsetDays = so;
          }
        }
      });
    }
    if (this.startMs && node.startMs) {
      const offsetMs = node.startMs - this.startMs;
      if (offsetMs > 0) {
        return offsetMs / DateUtil.ONEDAY;
      }
    }
    return startOffsetDays;
  } // getStartOffsetDays

  /**
   * Cycle Depth First Search
   * @param currentNode test node
   * @param whiteNodeSet while list
   * @param grayIdSet gray list
   * @param blackIdSet black list
   * @return true if there is s cycle
   */
  private hasCycleDfs(currentNode: GraphEdge, whiteNodeSet: Set<GraphEdge>,
                      grayIdSet: Set<string>, blackIdSet: Set<string>): boolean {
    // move currentNode from white to gray
    whiteNodeSet.delete(currentNode);
    grayIdSet.add(currentNode.id);
    //
    for (const dependentId of currentNode.dependentIds) {
      if (blackIdSet.has(dependentId)) {
        continue; // already explored
      }
      if (grayIdSet.has(dependentId)) {
        return true; // cycle found
      }
      const dependent = this.getNode(dependentId);
      if (this.hasCycleDfs(dependent, whiteNodeSet, grayIdSet, blackIdSet)) {
        return true;
      }
    }
    // move currentNode from gray to black
    grayIdSet.delete(currentNode.id);
    blackIdSet.add(currentNode.id);
    return false; // ok
  } // hasCycleDfs

  /**
   * Is the node a prerequisite
   * @param node the node
   * @param other compare
   * @return true if prerequisite
   */
  private isPrerequisite(node: GraphEdge, other: GraphEdge): boolean {
    if (other.prerequisiteIds) {
      for (const pid of other.prerequisiteIds) {
        if (pid === node.id) {
          return true;
        } else { // deep
          const otherDep = this.getNode(pid);
          if (this.isPrerequisite(node, otherDep)) {
            return true;
          }
        }
      }
    }
    return false;
  } // isPrerequisite

  /**
   * @param nodeA from
   * @param nodeB to
   * @param cmp ?
   * @param ii ?
   * @return sort node info
   */
  private toStringSort(nodeA: GraphEdge, nodeB: GraphEdge, cmp: string, ii: string): string {
    let info = nodeA.label + '_' + nodeB.label + ' ' + cmp;
    info += ': ' + nodeA.label + ' ' + nodeA.startOffsetDays;
    info += ' _ ' + nodeB.label + ' ' + nodeB.startOffsetDays;
    info += ' ' + ii;
    return info;
  } // toStringX

  /**
   * Set prerequisite/dependent arrays + changeMap from sets
   * @param n node to be updated
   */
  private updateNodeFromSets(n: GraphEdge): void {
    n.prerequisiteIds = [];
    n.prerequisiteSet.forEach((vv) => {
      n.prerequisiteIds.push(vv);
    });
    n.dependentIds = [];
    n.dependentSet.forEach((vv) => {
      n.dependentIds.push(vv);
    });
    // record changes
    if (n.prerequisiteIds.length > 0) {
      //  n.record.changeMap.prerequisiteIdList = n.prerequisiteIds.join(',');
      DataRecordF.setValue(n.record, n.namePrerequisiteIdList(), n.prerequisiteIds.join(','));
      // n.record.changeMap.prerequisiteSfId = n.prerequisiteIds[ 0 ];
      DataRecordF.setValue(n.record, n.namePrerequisiteId(), n.prerequisiteIds[ 0 ]);
    } else {
      DataRecordF.setValue(n.record, n.namePrerequisiteIdList(), null);
      DataRecordF.setValue(n.record, n.namePrerequisiteId(), null);
    }
    if (n.dependentIds.length > 0) {
      // n.record.changeMap.dependentSfId = n.dependentIds[ 0 ];
      DataRecordF.setValue(n.record, n.nameDependentId(), n.dependentIds[ 0 ]);
    } else {
      DataRecordF.setValue(n.record, n.nameDependentId(), null);
    }
    // n.record.getChanges(); // clean up
  } // updateNodeFromSets

} // GraphContainer


