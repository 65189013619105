import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';

import { AccortoModule } from 'accorto';
import { AppComponent } from './app.component';
import { reducers } from './reducers';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ProjectWorkbenchComponent } from './workbench/project-workbench/project-workbench.component';
import { ResourceWorkbenchComponent } from './workbench/resource-workbench/resource-workbench.component';
import { ProjectImportComponent } from './project-import/project-import.component';
import { ProjectEffects } from './project/project.effects';
import { resourceReducer, resourcesFeatureKey } from './resource/resource.reducer';
import { ResourceEffects } from './resource/resource.effects';
import { projectReducer, projectsFeatureKey } from './project/project.reducer';
import { ProjectEditorComponent } from './project-editor/project-editor.component';
import { ProjectLineEditorComponent } from './project-line-editor/project-line-editor.component';
import { TeItemEffects } from './te-item/te-item.effects';
import { teItemFeatureKey, teItemReducer } from './te-item/te-item.reducer';
import { WorkbenchComponent } from './workbench/workbench.component';
import { ProjectAllocationComponent } from './project-allocation/project-allocation.component';
import { ProjectSheetComponent } from './project-sheet/project-sheet.component';
import { InvoiceEffects } from './invoice/invoice.effects';
import { invoiceFeatureKey, invoiceReducer } from './invoice/invoice.reducer';
import { ResourceOverviewComponent } from './resource-overview/resource-overview.component';
import { TimeLineStackedComponent } from './graphs/time-line-stacked/time-line-stacked.component';
import { StackedBarChartComponent } from './graphs/stacked-bar-chart/stacked-bar-chart.component';
import { TimeLineComponent } from './graphs/time-line/time-line.component';
import { ProjectBurnDownComponent } from './dashboard/project-burn-down/project-burn-down.component';
import { ResourceSearchComponent } from './resource-search/resource-search.component';
import { AllocProjectComponent } from './alloc/alloc-project/alloc-project.component';
import { AllocResourceComponent } from './alloc/alloc-resource/alloc-resource.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ProjectRescheduleComponent } from './workbench/project-reschedule/project-reschedule.component';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,

    AppRoutingModule,

    AccortoModule.forRoot({
      server: environment.server,
      serverStage: environment.serverStage,
      production: environment.production,
      gaCode: 'UA-32129178-2',
      ts: environment.ts,
      productCode: 'Accorto',
      productTitle: 'Accorto PSA Workbench'
    }),

    StoreModule.forRoot(reducers, {
      runtimeChecks:
        {
          strictStateImmutability: true, strictActionImmutability: true,
          strictStateSerializability: false, strictActionSerializability: false
        }
    }),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router', routerState: RouterState.Minimal }),
    StoreModule.forFeature(projectsFeatureKey, projectReducer),
    StoreModule.forFeature(resourcesFeatureKey, resourceReducer),
    StoreModule.forFeature(teItemFeatureKey, teItemReducer),
    StoreModule.forFeature(invoiceFeatureKey, invoiceReducer),
    //
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([ ProjectEffects, ResourceEffects, TeItemEffects, InvoiceEffects ]),
    //
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      serialize: true
    }),
    //
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    ProjectWorkbenchComponent,
    ProjectImportComponent,
    ResourceWorkbenchComponent,
    ProjectEditorComponent,
    ProjectLineEditorComponent,
    WorkbenchComponent,
    ProjectAllocationComponent,
    ProjectSheetComponent,
    StackedBarChartComponent,
    TimeLineComponent,
    ResourceOverviewComponent,
    TimeLineStackedComponent,
    ProjectBurnDownComponent,
    ResourceSearchComponent,
    AllocProjectComponent,
    AllocResourceComponent,
    CalendarComponent,
    ProjectRescheduleComponent
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
