import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AccortoCUtil, AccortoService, CRequestImport, CResponseImport, Logger } from 'accorto';

@Injectable({
  providedIn: 'root'
})
export class ProjectImportService {

  private log: Logger = new Logger('ProjectImportService');

  constructor(private http: HttpClient,
              private config: AccortoService) {
  }


  send(request: CRequestImport): Observable<CResponseImport> {
    this.config.setCRequest(request);
    request.importObject = 'Project;ProjectPhase;ProjectLine';

    const url = this.config.server + '/import';
    this.log.info('send ' + url, request)();
    const body = JSON.stringify(request);
    //
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(url, body, httpOptions)
      .pipe(
        map(response => AccortoCUtil.createCResponseImport(response)),
        tap(response => {
          this.log.info('send.response', response)();
        })
      );

  } // send


} // ProjectImportService
