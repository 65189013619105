import {DateUtil} from '../utils/date-util';
import {Rect} from './rect';
import {Margin} from './margin';
import {Colors} from './colors';
import {DataRecordI} from '../model/data-record-i';
import {Trl} from '../utils/trl';
import {GraphContainer} from './graph-container';

/**
 * Box to draw
 */
export class Box extends Rect {

  /** label to display */
  public label: string = '';
  /** title to display */
  public title: string = '';
  /** Type */
  public type: string = '';
  /** Type */
  public id: string = '';
  /** Name */
  public name: string = '';
  /** Value */
  public value: number = 0;
  /** Fill */
  public fill: string = 'white';
  /** Stroke */
  public stroke: string = 'red';
  /** Start Ms */
  public startMs: number = 0;
  /** End Ms */
  public endMs: number = 0;
  /** Duration days */
  public durationDays: number = 0;
  /** Record */
  public record?: DataRecordI;

  /** Radius x */
  public rx: number = 0;
  /** Radius y */
  public ry: number = 0;

  /** Show this */
  public show: boolean = true;

  /** Selected */
  isSelected: boolean = false;

  /** Parent Graph Container */
  parent?: GraphContainer;

  /** Default Stroke */
  protected strokeDefault: string = 'white';

  /**
   * Box
   * @param x x pos
   * @param y y pos
   * @param w outer width
   * @param h outer height
   * @param margin margin
   * @param d rounding
   */
  constructor(x: number = 0, y: number = 0, w: number = 0, h: number = 0,
              public margin?: Margin, d?: number) {
    super(x, y, w, h);
    this.setRadius(d ? d : 0);
  }

  /**
   * Foreground color based on background
   */
  get foreground(): string {
    if (this.stroke && this.stroke.startsWith('#')) {
      const rgb = Colors.hex2rgb(this.stroke);
      return Colors.contrastYiq(rgb);
    }
    return 'black';
  }

  /** Rectangle height after margin */
  get hh(): number {
    if (this.h === undefined || Number.isNaN(this.h)) {
      return 0;
    }
    if (this.margin) {
      const hh = this.h - this.margin.h;
      return hh < 0 ? 0 : hh;
    }
    return this.h;
  }

  /** label-name-id */
  get labelName(): string {
    if (this.label) {
      return this.label;
    }
    if (this.name) {
      return this.name;
    }
    return this.id;
  }

  /** record id */
  get recordId(): string | undefined {
    return this.record ? this.record.id : undefined;
  }

  /** x radius */
  get rxx(): number {
    if (this.rx === undefined || Number.isNaN(this.rx)) {
      return 0;
    }
    return this.rx;
  }

  /** y radius */
  get ryy(): number {
    if (this.ry === undefined || Number.isNaN(this.ry)) {
      return 0;
    }
    return this.ry;
  }

  /** Rectangle with after margin */
  get ww(): number {
    if (this.w === undefined || Number.isNaN(this.w)) {
      return 0;
    }
    if (this.margin) {
      const ww = this.w - this.margin.v;
      // if (ww <= 0) {
      //  console.log('ww=' + ww + ' ' + this);
      // }
      return ww < 0 ? 0 : ww;
    }
    return this.w;
  } // ww

  /** Rectangle x after margin */
  get xx(): number {
    if (this.x === undefined || Number.isNaN(this.x)) {
      return 0;
    }
    if (this.margin) {
      return this.x + this.margin.l;
    }
    return this.x;
  }

  /** Rectangle x after margin */
  get yy(): number {
    if (this.y === undefined || Number.isNaN(this.y)) {
      return 0;
    }
    if (this.margin) {
      return this.y + this.margin.t;
    }
    return this.y;
  }

  /**
   * Update Start/End date and duration
   * @param startMs optional start ms - overwrite if less
   * @param endMs optional end ms - overwrite if more
   */
  addStartEndMs(startMs: number, endMs: number): void {
    if (startMs && (!this.startMs || this.startMs > startMs)) {
      this.startMs = startMs;
    }
    if (endMs && (!this.endMs || this.endMs < endMs)) {
      this.endMs = endMs;
    }
    if (!this.endMs && this.startMs) {
      this.endMs = this.startMs; // same
    }
    // console.debug('addStartEndMs start=' + startMs + '->' + this.startMs + ' end=' + endMs + '->' + this.endMs);
    this.setDurationDays();
  }

  /**
   * Add Value
   * @param value value to add
   */
  addValue(value: number): void {
    if (this.value) {
      this.value += value;
    } else {
      this.value = value;
    }
  } // addValue

  /**
   * Calculate End Days
   * @param startMs start ms
   * @param hours hours to add
   * @param hoursPerDay hours per day (default 8)
   */
  public calculateEndMs(startMs: number, hours: number = 0, hoursPerDay: number = 8): number {
    let theTime = startMs;
    let remainingHours = hours;
    while (remainingHours > 0) {
      const theDate = new Date(theTime);
      if (!DateUtil.isWeekend(theDate)) {
        remainingHours -= hoursPerDay;
      }
      if (remainingHours > 0) {
        theTime += DateUtil.ONEDAY;
      }
      // console.debug('calculateEndMs remaining=' + remainingHours + ' ' + new Date(theTime).toUTCString());
    }
    return theTime;
  } // calculateEndDateMs

  /** set duration days from start/end */
  setDurationDays(): void {
    const durationMs = this.endMs - this.startMs;
    if (durationMs <= 0 || Number.isNaN(durationMs)) {
      // console.debug('setDurationDays 0 ms=' + durationMs);
      this.durationDays = 1;
    } else {
      this.durationDays = Math.floor(durationMs / DateUtil.ONEDAY);
      if (this.durationDays === 0) {
        // console.debug('setDurationDays 1 from', this.durationDays);
        this.durationDays = 1;
      } else {
        this.durationDays += 1;
        // console.debug('setDurationDays', this.durationDays);
      }
    }
  } // setDurationDays

  /** Set Radius */
  setRadius(r: number): void {
    this.rx = r;
    this.ry = r;
  }

  /**
   * Set Selected - change color
   * @param value new value
   */
  setSelect(value: boolean): boolean {
    this.isSelected = value;
    if (this.isSelected) {
      this.stroke = 'red';
    } else {
      this.stroke = this.strokeDefault;
    }
    return this.isSelected;
  } // setSelect

  /**
   * Set Start/End date and duration
   * @param startMs start date
   * @param endMs end date
   */
  setStartEndMs(startMs: number | undefined, endMs: number | undefined): void {
    this.startMs = startMs || NaN;
    this.endMs = endMs || NaN;
    if (!endMs && startMs) {
      this.endMs = this.startMs; // same
    }
    this.setDurationDays();
  }

  /**
   * Set Type and Id
   * @param type the type
   * @param id the id
   * @return this
   */
  setTypeId(type: string, id: string): Box {
    this.type = type;
    this.id = id;
    return this;
  }

  /**
   * Set X (based on startMs) and Width (based on days)
   * @param firstStartMs earliest start ms
   * @param xDayFactor width of a day
   * @return true if x/w is set
   */
  setXW(firstStartMs: number, xDayFactor: number): boolean {
    if (firstStartMs !== undefined && this.startMs !== undefined) {
      const startDays = Math.trunc((this.startMs - (firstStartMs ? firstStartMs : this.startMs)) / DateUtil.ONEDAY);
      this.x = Math.round(startDays * xDayFactor);
      this.w = xDayFactor * (this.durationDays ? this.durationDays : 1);
      // if (this.w === 0) {
      //  console.debug('setXW=0 ' + this, 'factor=' + xDayFactor);
      // }
      // console.debug('setXW ' + this, 'factor=' + xDayFactor);
      return true;
    }
    this.x = 0;
    this.w = 0;
    // console.debug('setXW-00 ' + this, 'first=' + firstStartMs, 'factor=' + xDayFactor);
    return false;
  } // setXW

  /** Info */
  toString(): string {
    let info = '';
    if (this.type) {
      info += this.type;
    }
    if (this.id) {
      info += '_' + this.id;
    }
    if (this.name) {
      info += '(' + this.name + ')';
    }
    if (this.value) {
      info += ' v=' + this.value;
    }
    if (this.startMs) {
      const dd = new Date(this.startMs);
      info += ' ' + dd.getUTCFullYear() + '-' + (dd.getUTCMonth() + 1) + '-' + dd.getUTCDate();
    }
    if (this.endMs) {
      const dd = new Date(this.endMs);
      info += this.startMs ? '|' : ' |';
      info += dd.getUTCFullYear() + '-' + (dd.getUTCMonth() + 1) + '-' + dd.getUTCDate();
    }
    if (this.durationDays) {
      info += ' d=' + this.durationDays;
    }
    info += ' [' + this.x + ',' + this.y + ' ' + this.w + 'x' + this.h + ']';
    if (this.margin) {
      info += this.margin.toString();
    }
    info += this.show ? 's' : 'h';
    return info;
  } // toString

  /**
   * Format Value - 0 or 0.1
   * @param value value
   */
  protected formatValue(value: number): string {
    if (value) {
      const s = String(value);
      if (s.includes('.')) {
        return Trl.formatNumber(value, 1);
      }
      return s;
    }
    return '0';
  } // formatValue

} // Box
