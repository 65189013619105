import {Component, ViewEncapsulation} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter} from 'rxjs/operators';
import {NavigationCancel, NavigationEnd, NavigationStart, Router, RoutesRecognized} from '@angular/router';
import {DomSanitizer, Meta, MetaDefinition, SafeHtml, Title} from '@angular/platform-browser';
import {appHeading, GoogleAnalyticsService, loginRequestAction, MenuItem, selectAppStatus} from 'accorto';
import {AppState} from './reducers';

@Component({
  selector: 'psa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  appTitle = 'Accorto PSA Workbench';
  appName: SafeHtml;

  menuItems: MenuItem[] = [];

  footerUrl: string = '-';
  footerStatus: string = '-';

  busy: boolean = true;
  startTime: number = Date.now();

  /**
   * Accorto PSA
   */
  constructor(private store: Store<AppState>,
              private router: Router,
              private pageTitle: Title,
              private pageMeta: Meta,
              sanitizer: DomSanitizer,
              ga: GoogleAnalyticsService) {
    this.appName = sanitizer.bypassSecurityTrustHtml('Accorto Workbench');
    // initial login
    this.store.dispatch(loginRequestAction({ request: undefined }));
    //
    this.routerTrackTitle();
    this.routerTrackBusy();
    this.initMenu();
  } // constructor

  private initMenu(): void {
    // Menu
    let mi = new MenuItem()
      .setName('ps', 'Project Sheet', 'Project Sheet',
        '/assets/accorto/project-100.svg')
      .setNav('/project/');
    this.menuItems.push(mi);

    mi = new MenuItem()
      .setName('pa', 'Allocation', 'Project Allocation',
        '/assets/icons/utility-sprite/svg/symbols.svg#groups')
      .setNav('/project-alloc/');
    this.menuItems.push(mi);

    mi = new MenuItem()
      .setName('ap', 'Project Alloc', 'Project Detail Allocation',
        '/assets/icons/utility-sprite/svg/symbols.svg#resource_territory')
      .setNav('/alloc-project/');
    this.menuItems.push(mi);

    mi = new MenuItem()
      .setName('ar', 'Resource Alloc', 'Resource Detail Allocation',
        '/assets/icons/utility-sprite/svg/symbols.svg#resource_capacity')
      .setNav('/alloc-resource/');
    this.menuItems.push(mi);

    mi = new MenuItem()
      .setName('pwb', 'Project Workbench', 'Project Workbench',
        '/assets/accorto/projectWorkbench-100.svg')
      .setNav('/project-wb/');
    this.menuItems.push(mi);

    mi = new MenuItem()
      .setName('rb', 'Resource Workbench', 'Resource Workbench',
        '/assets/accorto/resourceWorkbench-100.svg')
      .setNav('/resource-wb/');
    this.menuItems.push(mi);

    mi = new MenuItem()
      .setName('ro', 'Calendar', 'Resource Calendar',
        '/assets/icons/utility-sprite/svg/symbols.svg#date_input')
      .setNav('/calendar');
    this.menuItems.push(mi);

    mi = new MenuItem()
      .setName('ro', 'Resources', 'Resource Overview',
        '/assets/accorto/resource-100.svg')
      .setNav('/resources');
    this.menuItems.push(mi);

    mi = new MenuItem()
      .setName('pi', 'Project Import', 'Project Import',
        '/assets/accorto/projectImport-100.svg')
      .setNav('/project-import');
    this.menuItems.push(mi);
  } // initMenu

  /**
   * Busy
   */
  private routerTrackBusy(): void {
    this.router.events.subscribe((evt) => { // RouterEvent
      const now = Date.now();
      // console.debug('=router', evt);
      if (evt instanceof NavigationStart) {
        this.startTime = now;
        // console.debug('=router-start', evt.id, evt.url);
        this.busy = true;
      } else if (evt instanceof NavigationCancel) {
        // console.debug('=router-cancel=' + (now - this.startTime), evt.url);
        this.busy = false;
      } else if (evt instanceof NavigationEnd) {
        // console.debug('=router-end=' + (now - this.startTime), evt.url);
        this.busy = false;
      }
    });
  } // routerTrackBusy

  /**
   * Set PageTitle, PageMeta, Footer
   */
  private routerTrackTitle(): void {
    // route changed - update header
    this.router.events.pipe(
      filter(event => event instanceof RoutesRecognized)
    ).subscribe((rr) => {
      if (rr instanceof RoutesRecognized) {
        const theTitle = rr.state.root.firstChild?.data.title;
        const theDescription = rr.state.root.firstChild?.data.description;
        const theKeywords = rr.state.root.firstChild?.data.keywords;
        //
        if (theTitle) {
          this.pageTitle.setTitle(theTitle + ' | Accorto Workbench');
          this.store.dispatch(appHeading({heading: theTitle}));
        }
        //
        if (theDescription) {
          const description: MetaDefinition = {
            name: 'description',
            content: theDescription
          };
          this.pageMeta.updateTag(description);
        }
        if (theKeywords) {
          const keywords: MetaDefinition = {
            name: 'keywords',
            content: theKeywords
          };
          this.pageMeta.updateTag(keywords);
        }
      } // RoutesRecognized
    });

    // update footer - based on router
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((rr) => {
      if (rr instanceof NavigationEnd) {
        this.footerUrl = rr.urlAfterRedirects;
        const index = this.footerUrl.indexOf('?');
        if (index > 0) { // remove query parameters
          this.footerUrl = this.footerUrl.substring(0, index);
        }
      }
    });
    // footer status - based on this.store.dispatch(appStatus({ status: 'xx' }));
    this.store.pipe(
      select(selectAppStatus)
    ).subscribe((status) => {
      this.footerStatus = status ? status : '';
    });
  } // routerTrackTitle

} // AppComponent
