import { DateUtil } from 'accorto';

export class CalendarMonth {

  public readonly label: string;
  public readonly theDate: Date;
  public readonly month: number;


  constructor(public ms: number, public index: number) {
    this.theDate = new Date(ms);
    const year = this.theDate.getUTCFullYear();
    this.month = this.theDate.getUTCMonth();
    this.label = DateUtil.monthName(this.month) + ' ' + year;
  }

  /**
   * Last Date in Month in ms
   */
  get endMs(): number {
    return Date.UTC(this.theDate.getUTCFullYear(), this.theDate.getUTCMonth() + 1, 0, 0, 0, 0, 0);
  }

}
