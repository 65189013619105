import {Action, createReducer, on} from '@ngrx/store';
import {DataRecordF, DataRecordI} from 'accorto';
import * as ResourceActions from './resource.actions';

export const resourcesFeatureKey = 'resources';

/**
 * Resource State
 */
export interface ResourceState {
  // ResourceId -> DataRecord
  allResourcesMap: { [key: string]: DataRecordI };
  allResourceProjectAllocations: DataRecordI[];

  fromMs?: number;
  fromDate?: Date;
  toMs?: number;
  toDate?: Date;

  resourceTypes: string[];
  resourceGroups: string[];
  resourceLocations: string[];
  resourceSkills: string[];

  currentResource?: DataRecordI;
  currentResourceProjectLines: DataRecordI[];
  currentResourceProjectAllocations: DataRecordI[];
}

export const initialState: ResourceState = {
  allResourcesMap: {},
  allResourceProjectAllocations: [],

  fromMs: undefined,
  fromDate: undefined,
  toMs: undefined,
  toDate: undefined,

  resourceTypes: [],
  resourceGroups: [],
  resourceLocations: [],
  resourceSkills: [],

  currentResource: undefined,
  currentResourceProjectLines: [],
  currentResourceProjectAllocations: []
};


export function sortByName(one: DataRecordI, two: DataRecordI): number {
  const o = one.label ? one.label : one.name ? one.name : '';
  const t = two.label ? two.label : two.name ? two.name : '';
  return o.localeCompare(t);
}


const resourceReducer0 = createReducer(
  initialState,

  on(ResourceActions.resourceLoadAllResultAction,
    (state, action) => {
      const allResources: DataRecordI[] = action.resources;
      const allResourcesMap: { [ key: string ]: DataRecordI } = {};
      allResources.forEach((resource: DataRecordI) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        allResourcesMap[resource.id!] = resource;
      });
      const resourceTypes = [ ...new Set(allResources
        .filter((r) => {
          return DataRecordF.value(r, 'resourceType') != null;
        })
        .map((r) => DataRecordF.value(r, 'resourceType') ?? '')
      ) ];
      const resourceGroups = [ ...new Set(allResources
        .filter((r) => {
          return DataRecordF.value(r, 'resourceGroup') != null;
        })
        .map((r) => DataRecordF.value(r, 'resourceGroup') ?? '')
      )];
      const resourceLocations = [...new Set(allResources
        .filter((r) => {
          return DataRecordF.value(r, 'resourceLocation') != null;
        })
        .map((r) => DataRecordF.value(r, 'resourceLocation') ?? '')
      )];
      const resourceSkillSets = [...new Set(allResources
        .filter((r) => {
          return DataRecordF.value(r, 'skillSet') ?? '';
        })
        .map((r) => DataRecordF.value(r, 'skillSet')) ?? ''
      )];
      const resourceSkills: string[] = []; // resourceSkillSets; // todo
      return {
        ...state,
        allResourcesMap,
        resourceTypes,
        resourceGroups,
        resourceLocations,
        resourceSkills
      };
    }
  ),

  on(ResourceActions.resourceAddAction,
    (state, action) => {
      const allResourcesMap: { [key: string]: DataRecordI } = {
        ...state.allResourcesMap,
      };
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      allResourcesMap[action.resource.id!] = action.resource;
      return {
        ...state,
        allResourcesMap
      };
    }
  ),
  on(ResourceActions.resourceUpdateAction,
    (state, action) => {
      const allResourcesMap: { [key: string]: DataRecordI } = {
        ...state.allResourcesMap,
      };
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      allResourcesMap[action.resource.id!] = action.resource;
      return {
        ...state,
        allResourcesMap
      };
    }
  ),
  on(ResourceActions.resourceDeleteAction,
    (state, action) => {
      const allResourcesMap: { [ key: string ]: DataRecordI } = {
        ...state.allResourcesMap,
      };
      delete allResourcesMap[ action.id ];
      return {
        ...state,
        allResourcesMap
      };
    }
  ),
  on(ResourceActions.resourcesClearAction,
    (state, action) => {
      return {
        ...state,
        allResourcesMap: {}
      };
    }
  ),

  // current resource
  on(ResourceActions.resourceSelectedAction,
    (state, action) => {
      return {
        ...state,
        currentResource: action.resource,
        currentResourceProjectLines: [],
        currentResourceProjectAllocations: []
      };
    }
  ),
  // current resource - project lines
  on(ResourceActions.resourceProjectLineLoadResultAction,
    (state, action) => {
      return {
        ...state,
        currentResourceProjectLines: action.projectLines
      };
    }
  ),
  // current resource - allocations
  on(ResourceActions.resourceProjectAllocationLoadResultAction,
    (state, action) => {
      return {
        ...state,
        currentResourceProjectAllocations: action.projectAllocations
      };
    }
  ),

  // date range
  on(ResourceActions.resourceDateRangeAction,
    (state, action) => {
      return {
        ...state,
        fromMs: action.fromMs,
        fromDate: action.fromMs && action.fromMs > 0 ? new Date(action.fromMs) : undefined,
        toMs: action.toMs,
        toDate: action.toMs && action.toMs > 0 ? new Date(action.toMs) : undefined,
      };
    }
  ),
);

export const getCurrentResource = (state: ResourceState) => state.currentResource;


export function resourceReducer(state: ResourceState | undefined, action: Action): ResourceState {
  return resourceReducer0(state, action);
}
