import {Action, createReducer, on} from '@ngrx/store';
import {DataRecordI} from 'accorto';
import * as InvoiceActions from './invoice.actions';

export const invoiceFeatureKey = 'invoice';

export interface InvoiceState {
  projectId?: string;
  invoiceLines: DataRecordI[];
}

export const initialState: InvoiceState = {
  projectId: undefined,
  invoiceLines: []
};


const invoiceReducer0 = createReducer(
  initialState,

  on(InvoiceActions.invoiceLoadRequestAction, (state, action) => {
    return {
      ...state,
      projectId: action.projectId
    };
  }),
  on(InvoiceActions.invoiceLoadResultAction, (state, action) => {
    return {
      ...state,
      invoiceLines: action.invoiceLines
    };
  }),
);


export function invoiceReducer(state = initialState, action: Action): InvoiceState {
  return invoiceReducer0(state, action);
}
