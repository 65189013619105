import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';

import {DataService} from '../data/data.service';
import {UiTab} from '../model/ui-tab';
import {Logger} from '../log/logger';
import {DataRecord} from '../model/data-record';
import {CResponseData} from '../model/c-response-data';
import {ModelUtil} from '../utils/model-util';
import {selectUi} from '../ui/ui.selectors';
import {Meta, Title} from '@angular/platform-browser';
import {AccortoService} from '../accorto.service';
import {uiRequestAction} from '../ui/ui.actions';

@Component({
  selector: 'acc-record-home',
  templateUrl: './record-home.component.html',
  styleUrls: ['./record-home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecordHomeComponent implements OnInit, OnDestroy {

  /** UiTab */
  ui: UiTab = new UiTab();
  /** Data */
  record: DataRecord = new DataRecord();
  /** busy */
  busy: boolean = true;
  /** messages */
  message?: string;
  error?: string;

  tabUis: UiTab[] = [];
  subscriptions: Subscription[] = [];

  tabActive?: UiTab;
  tabRecords: DataRecord[] = [];

  private log: Logger = new Logger('RecordHome');

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dataService: DataService,
              private store: Store<object>,
              private pageTitle: Title,
              private pageMeta: Meta,
              private config: AccortoService) {
    // this.router.navigate(['/ui', this.ui.name, '-1']);
  }

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    this.initUi(this.route.snapshot.data.uiTab); // ui.resolver
    this.initRecord(this.route.snapshot.data.record, id); // data.resolver
    this.log.debug('ngOnInit ' + id, this.route.snapshot)();
    this.busy = false;

    // load tabs
    this.tabUis = [];
    for (const uiName of this.ui.childTabNames) {
      this.subscriptions.push(this.store.pipe(
        select(selectUi(uiName)),
        tap(ui => {
          if (ui === undefined) {
            this.store.dispatch(uiRequestAction({ uiName }));
          }
        })
        ).subscribe(ui => {
          if (ui) {
            this.tabUis.push(ui);
            this.log.debug('ngOnInit tabUi', ui)();
          }
        }) // subscribe
      );
    } // forEach
  } // ngOnInit

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  onClickDelete(): void {
    this.log.info('onClickDelete')();
    this.busy = true;
    this.message = 'deleting ...';
    this.error = undefined;
    this.dataService.deleteRecord(this.record)
      .subscribe((response: CResponseData) => {
        this.message = response.message;
        this.error = response.error;
        if (response.records && response.records.length > 0) {
          this.record = response.records[ 0 ];
        } else {
          this.message = 'Deleted: ' + this.record.name;
          this.record.isReadOnly = true;
          this.record.isActive = false;
        }
        this.busy = false;
      });
  } // onClickDelete


  /**
   * Save Record - called from Form/Record
   * @param record record to be saved
   */
  saveRecord(record: DataRecord): void {
    this.log.info('saveRecord', record)();
    this.busy = true;
    this.message = 'saving ...';
    this.error = undefined;
    this.dataService.saveRecord(record)
      .subscribe((response: CResponseData) => {
        this.message = response.message;
        this.error = response.error;
        const newRecord = this.record.id === undefined;
        if (response.records && response.records.length > 0) {
          this.record = response.records[ 0 ];
          if (newRecord && this.record.id !== undefined) {
            this.log.debug('saveRecord (new)', this.record)();
            this.router.navigate([ '/ui', this.ui.name, this.record.id ]);
          }
        }
        this.busy = false;
      });
  }

  statusUpdate(msg: string): void {
    this.log.info('statusUpdate', msg)();
    this.message = msg;
  }


  /**
   * On Tab Click = query
   * @param uiName tab name
   */
  onTabClick(uiName: string): void {
    this.log.info('onTabClick ' + uiName)();
    this.tabUis.forEach((ui) => {
      if (ui.name === uiName) {
        this.tabActive = ui;
        this.tabRecords = [];
        // load data TODO Parent Query
        const name = ui.dataTable?.name;
        if (name) {
          const sub = this.dataService.query(name, undefined)
            .subscribe((result) => {
              this.log.info('onTabClick', result)();
              this.tabRecords = result.records;
            });
          this.subscriptions.push(sub);
        }
      }
    });
  }

  getTabLabel(tabName: string): string {
    for (const ui of this.tabUis) {
      if (ui.name === tabName && ui.labelPlural) {
        return ui.labelPlural;
      }
    }
    return '?' + tabName;
  } // getTabLabel

  isTabActive(tabName: string): boolean {
    return this.tabActive != null && this.tabActive.name === tabName;
  }

  /**
   * Load Main UI
   * @param ui the ui
   */
  private initUi(ui: UiTab): void {
    this.log.debug('initUi', ui)();
    if (ui) {
      this.ui = ui;
      this.pageTitle.setTitle(ui.label + ' | ' + this.config.productTitle);
    }
  } // initUi

  /**
   * Load Main Record
   * @param record data record
   * @param recordId record id
   */
  private initRecord(record: DataRecord, recordId: string): void {
    if (record) {
      if (record.rowNo === undefined) {
        record.rowNo = 0;
      }
      if (record.id === undefined) {
        record = ModelUtil.newDataRecord(this.ui);
        this.log.debug('initRecord new', record)();
      } else {
        this.log.debug('initRecord', record)();
      }
      this.record = record;
    } else { // no record
      this.message = (this.ui.label ? this.ui.label : 'Record') + ' not found: ' + recordId;
      this.record.isActive = false;
      this.record.isReadOnly = true;
    }
  } // initRecord

  asString(value: string | undefined): string {
    return value ? value : '';
  }

} // RecordHomeComponent
