import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs/operators';

import { invoiceLoadRequestAction, invoiceLoadResultAction } from './invoice.actions';
import { InvoiceService } from './invoice.service';

@Injectable()
export class InvoiceEffects {

  loadInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceLoadRequestAction),
      concatMap((action) => this.service.loadInvoiceLines(action.projectId)),
      map(result => invoiceLoadResultAction({ invoiceLines: result }))
    ), {}
  );


  constructor(private actions$: Actions,
              private service: InvoiceService) {
  }

} //
