import { Point2d } from './point2d';
import { Logger } from '../log/logger';
import { GraphContainer } from './graph-container';
import { GraphEdge } from './graph-edge';

/**
 * Graph Vertex between edges - Link
 */
export class GraphVertex {

  readonly ARROW_WIDTH = 10;
  readonly ARROW_HEIGHT = 4;

  /** is link selected */
  isSelected: boolean = false;
  /** link polyline points */
  points: string = '';
  /** link stroke color */
  stroke: string = 'black';

  /** link id */
  readonly id: string;
  /** link label */
  readonly label: string;

  private log: Logger = new Logger('GraphVertex');

  /**
   * @param parent Graph container
   * @param from before
   * @param to after
   */
  constructor(public parent: GraphContainer, public from: GraphEdge, public to: GraphEdge) {
    this.id = 'l_' + from.id + '_' + to.id;
    this.label = from.label + ' -> ' + to.label;
    this.layout();
  }

  /**
   * This is the GraphVertex from-to
   */
  isLink(from: GraphEdge, to: GraphEdge): boolean {
    return this.from.id === from.id && this.to.id === to.id;
  }

  /**
   * Set PolyLine Points
   */
  layout(): void {
    const toBelow = this.from.top <= this.to.top;
    const toRight = this.from.left < this.to.left;

    if (toBelow) {
      if (toRight) {
        this.log.debug('layout', 'to: below-right |_')();
        this.pointsSet(this.from.centerBottom);
        this.pointsAdd(new Point2d(this.from.center, this.to.middle));
        const end = this.pointsAdd(this.to.leftMiddle);
        this.arrowRight(end);
      } else if (this.from.left === this.to.left) { // same start
        const endDelta = this.from.right - this.to.right;
        if (Math.abs(endDelta) < 20) { // ~ same end
          this.log.debug('layout', 'to: below sameStart-sameEnd =|')();
          this.pointsSet(this.from.rightMiddle);
          this.pointsAdd(new Point2d(this.from.right + 20, this.from.middle));  // -
          this.pointsAdd(new Point2d(this.to.right + 20, this.to.middle));      // |
          const end = this.pointsAdd(this.to.rightMiddle);  // -
          this.arrowLeft(end);
        } else if (this.from.right < this.to.right) {
          this.log.debug('layout', 'to: below sameStart-longer -|')();
          this.pointsSet(this.from.rightMiddle);
          const backset = (this.to.right - this.from.right) / 2 - this.ARROW_HEIGHT; // middle of remainder
          this.pointsAdd(new Point2d(this.to.right - backset, this.from.middle));
          const end = this.pointsAdd(new Point2d(this.to.right - backset, this.to.top));
          this.arrowDown(end);
        } else {
          this.log.debug('layout', 'to: below sameStart-shorter _|')();
          const backset = (this.from.right - this.to.right) / 2 - this.ARROW_HEIGHT; // middle of overhanging
          this.pointsSet(new Point2d(this.from.right - backset, this.from.bottom));
          this.pointsAdd(new Point2d(this.from.right - backset, this.to.middle));
          const end = this.pointsAdd(this.to.rightMiddle);
          this.arrowLeft(end);
        }
      } else { // to left
        this.log.debug('layout', 'to: below-left _|')();
        this.pointsSet(this.from.centerBottom);
        this.pointsAdd(new Point2d(this.from.center, this.to.middle));
        const end = this.pointsAdd(this.to.rightMiddle);
        // arrow <-
        this.arrowLeft(end);
      }
    } else { // --- from is below to (backwards) ---
      if (toRight) {
        this.log.debug('layout', 'to: above-right _|')();
        this.pointsSet(this.from.rightMiddle);
        this.pointsAdd(new Point2d(this.from.center, this.to.middle));
        const end = this.pointsAdd(this.to.centerBottom);
        // arrow up
        this.arrowUp(end);
      } else if (this.from.left === this.to.left) { // same start
        const endDelta = this.from.right - this.to.right;
        if (Math.abs(endDelta) < 20) { // ~ same end
          this.log.debug('layout', 'to: above sameStart-sameEnd =|')();
          this.pointsSet(this.to.rightMiddle);
          this.pointsAdd(new Point2d(this.to.right + 20, this.to.middle));  // -
          this.pointsAdd(new Point2d(this.from.right + 20, this.from.middle));      // |
          const end = this.pointsAdd(this.from.rightMiddle);  // -
          this.arrowLeft(end);
        } else if (this.from.right < this.to.right) {
          this.log.debug('layout', 'to: above sameStart-longer -|')();
          this.pointsSet(this.from.rightMiddle);
          const backset = (this.to.right - this.from.right) / 2 - this.ARROW_HEIGHT; // middle of remainder
          this.pointsAdd(new Point2d(this.to.right - backset, this.from.middle));
          const end = this.pointsAdd(new Point2d(this.to.right - backset, this.to.bottom));
          this.arrowUp(end);
        } else {
          this.log.debug('layout', 'to: above sameStart-shorter _|')();
          const backset = (this.from.right - this.to.right) / 2 - this.ARROW_HEIGHT; // middle of overhanging
          this.pointsSet(new Point2d(this.from.right - backset, this.from.bottom));
          this.pointsAdd(new Point2d(this.from.right - backset, this.to.middle));
          const end = this.pointsAdd(this.to.rightMiddle);
          this.arrowLeft(end);
        }
      } else { // to left
        this.log.debug('layout', 'to: above-left |_')();
        this.pointsSet(this.from.leftMiddle);
        this.pointsAdd(new Point2d(this.to.center, this.from.middle));
        const end = this.pointsAdd(this.to.centerBottom);
        // arrow up
        this.arrowUp(end);
      }
    }
  } // layout

  setSelect(value: boolean): void {
    this.isSelected = value;
    this.stroke = value ? 'red' : 'black';
  }

  /**
   * l_from.id_to.id
   */
  toString(): string {
    return this.id;
  }

  private arrowDown(end: Point2d): void {
    const tl = new Point2d(end.x - this.ARROW_HEIGHT, end.y - this.ARROW_WIDTH);
    const tr = new Point2d(end.x + this.ARROW_HEIGHT, end.y - this.ARROW_WIDTH);
    this.points += ' ' + tl.toString();   // \
    this.points += ' ' + tr.toString();   // -
    this.points += ' ' + end.toString();  //  /
  }

  private arrowLeft(end: Point2d): void {
    const tu = new Point2d(end.x + this.ARROW_WIDTH, end.y - this.ARROW_HEIGHT); // upper
    const tb = new Point2d(end.x + this.ARROW_WIDTH, end.y + this.ARROW_HEIGHT); // below
    this.points += ' ' + tu.toString();   // /
    this.points += ' ' + tb.toString();   // |
    this.points += ' ' + end.toString();  // \
  }

  private arrowRight(end: Point2d): void {
    // arrow -> marker-end="url(#triangle)"
    const tu = new Point2d(end.x - this.ARROW_WIDTH, end.y - this.ARROW_HEIGHT); // upper
    const tb = new Point2d(end.x - this.ARROW_WIDTH, end.y + this.ARROW_HEIGHT); // below
    this.points += ' ' + tu.toString();   // \
    this.points += ' ' + tb.toString();   // |
    this.points += ' ' + end.toString();  // /
  }

  private arrowUp(end: Point2d): void {
    const tl = new Point2d(end.x - this.ARROW_HEIGHT, end.y + this.ARROW_WIDTH);
    const tr = new Point2d(end.x + this.ARROW_HEIGHT, end.y + this.ARROW_WIDTH);
    this.points += ' ' + tl.toString();   // /
    this.points += ' ' + tr.toString();   // _
    this.points += ' ' + end.toString();  //  \
  }

  private pointsAdd(p: Point2d): Point2d {
    this.points += ' ' + p.toString();
    return p;
  }

  private pointsSet(p: Point2d): void {
    this.points = p.toString();
  }

} // GraphVertex
