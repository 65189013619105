import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {
  AccortoService,
  CRequestDataI,
  CResponseDataI,
  DataOperation,
  DataRecordI,
  Logger,
  NotificationService,
  ServiceBase
} from 'accorto';
import {PsaUtil} from '../psa-util';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends ServiceBase {

  /** Project Level Project lines */
  static readonly PROJECT = '__project__';

  private log: Logger = new Logger('ProjectService');

  constructor(private http: HttpClient,
              private config: AccortoService,
              private note: NotificationService) {
    super();
  }

  /**
   * Delete Record
   * @param record record
   */
  delete(record: DataRecordI): Observable<CResponseDataI> {
    const request: CRequestDataI = {
      tableName: record.recordType,
      operation: DataOperation.DELETE,
      record,
      params: {}
    };
    return this.submit(request, 'Delete');
  } // delete

  /**
   * Delete Records
   * @param records records
   */
  deleteList(records: DataRecordI[]): Observable<CResponseDataI> {
    const request: CRequestDataI = {
      tableName: records[0].recordType,
      operation: DataOperation.DELETE,
      records,
      params: {}
    };
    return this.submit(request, 'Delete List');
  } // deleteList

  /**
   * Load Project Allocations (active or inactive)
   * @param projectId to load its lines
   */
  loadProjectAllocations(projectId: string): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'ProjectLineSharing',
      operation: DataOperation.QUERY,
      dataView: {
        name: '',
        selectAddlColumns: PsaUtil.selectAddlColumns,
        whereList: [
          {
            whereDirect: 'accorto__Project_Line__r.accorto__Project__c = \'' + projectId + '\''
          }
        ]
      },
      params: {}
    };
    return this.send(request);
  }

  /**
   * Load Resource (all) active allocation info with additional data:
   *  projectLine.name, projectLine.project.name, projectLine.startPlan, projectLine.endPlan
   */
  loadResourcesAllocations(): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'ProjectLineSharing',
      operation: DataOperation.QUERY,
      dataView: {
        name: '',
        selectAddlColumns: PsaUtil.selectAddlColumns,
        whereList: [
          {
            columnName: 'isActive',
            value: 'true'
          },
          {
            whereDirect: 'accorto__Project_Line__r.accorto__Status__c != \'Completed\''
          }
        ]
      },
      params: {}
    };
    return this.send(request);
  } // loadResourcesAllocations

  /**
   * @param projectId to load its lines
   */
  loadProjectLines(projectId: string): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'ProjectLine',
      operation: DataOperation.QUERY,
      dataView: {
        name: '',
        whereList: [
          {
            columnName: 'projectId',
            value: projectId
          }
        ],
        orderBys: [
          'wbs', 'name'
        ]
      },
      params: {}
    };
    return this.send(request);
  } // loadProjectLines

  /**
   * @param projectId to load its phases
   */
  loadProjectPhases(projectId: string): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'ProjectPhase',
      operation: DataOperation.QUERY,
      dataView: {
        name: '',
        whereList: [
          {
            columnName: 'projectId',
            value: projectId
          }
        ],
        orderBys: [
          'wbs', 'name'
        ]
      },
      params: {}
    };
    return this.send(request);
  }

  /**
   * Load All Projects
   */
  loadProjects(): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'Project',
      operation: DataOperation.QUERY,
      params: {}
    };
    return this.send(request);
  } // loadProjects

  /**
   * Save Record
   */
  save(record: DataRecordI): Observable<CResponseDataI> {
    const request: CRequestDataI = {
      tableName: record.recordType,
      operation: DataOperation.SAVE,
      record,
      params: {}
    };
    return this.submit(request, 'Save');
  } // save

  /**
   * Save Records
   * @param records records
   */
  saveList(records: DataRecordI[]): Observable<CResponseDataI> {
    const request: CRequestDataI = {
      tableName: records[0].recordType,
      operation: DataOperation.SAVE,
      records,
      params: {}
    };
    return this.submit(request, 'Save List');
  } // saveList

  /**
   * Send/Query - return DataRecords
   * @param request request to send
   */
  send(request: CRequestDataI): Observable<DataRecordI[]> {
    return this
      .submit(request, 'Query')
      .pipe(
        map(response => response.records ? response.records : [])
      );
  } // sendI

  /**
   * Submit Request - return response
   * @param request request
   * @param type details
   */
  submit(request: CRequestDataI, type: string): Observable<CResponseDataI> {
    const start = new Date();
    const url = this.config.server + '/data';
    this.log.info('submit ' + url + ' ' + request.tableName, request)();
    this.config.setCRequestI(request);
    const body = JSON.stringify(request);
    //
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseDataI>(url, body, httpOptions)
      .pipe(
        tap(response => {
          const msg = this.markI(start, response, response.tableName);
          if (response.error) {
            this.note.addResponseData('Project ' + type + ' failed', response);
            this.log.warn('submit.response', response)();
            this.markError(start);
          } else {
            this.log.info('submit.response ' + msg)();
            this.markSuccess(start);
          }
        }),
        catchError((err) => {
          this.log.error('submit', err)();
          const error: CResponseDataI = {
            error: 'Connection Error'
          };
          if (err instanceof HttpErrorResponse) {
            error.error = err.message;
          }
          this.note.addError('Project ' + type + ' error', error.error);
          return of(error);
        })
      );
  } // submit

} // ProjectService
