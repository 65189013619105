import {Trl} from 'accorto';

/**
 * Allocation Info day cell
 */
export class AllocationInfoDay {

  /** date project/resource */
  title: string;

  constructor(public id: string, public ms: number,
              public isWeekly: boolean, public isWeekend: boolean, public isWeekStartDay: boolean, public isDisplayed: boolean,
              public value: number, title: string) {
    this.title = Trl.formatDateUtcMs(ms) + ' ' + title;
    this.setValue(value);
  }

  get stringValue(): string {
    if (this.value === 0) {
      return '';
    }
    return String(this.value);
  }

  /**
   * Set new Value
   * @param newValue new value
   */
  setValue(newValue: number): string {
    if (newValue == null) {
      this.value = 0;
    } else if (!Number.isNaN(newValue)) {
      this.value = newValue;
    }
    return this.stringValue;
  } // setValue

  toString(): string {
    return JSON.stringify(this);
  }

  /**
   * Toggle Value 0 - onValue
   */
  toggle(onValue: number = 8): string {
    if (this.value) {
      this.value = 0;
    } else {
      this.value = onValue;
    }
    return this.stringValue;
  } // toggle

} // AllocationInfoDay
