import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';

import {AccortoService, Dimensions, Logger, Trl} from 'accorto';
import {StackedBarChart} from './stacked-bar-chart';
import {StackedBar, StackedBarSegment} from './stacked-bar-segment';
import {BarSourceF, BarSourceI} from './bar-source';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Chromatic from 'd3-scale-chromatic';
import {GraphBase} from '../graph-base';

@Component({
  selector: 'psa-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StackedBarChartComponent
  extends GraphBase implements OnChanges {

  /** Stacked Bar Definition */
  @Input() definition: StackedBarChart = {
    prefix: '',
    decimals: 0,
    suffix: '',
    types: []
  };
  /** Data Source List */
  @Input() sources: BarSourceI[] = [];
  /** Chart Dimensions */
  @Input() dimensions: Dimensions = new Dimensions(500, 300, 20, 40);


  private log: Logger = new Logger('StackedBarChart');

  constructor(private config: AccortoService) {
    super();
  }

  get isDebug(): boolean {
    return this.config.isDebug;
  }


  public ngOnChanges(changes: SimpleChanges): void {
    this.message = this.sources.length === 0 ? 'No Data' : undefined;
    this.init();
  }

  /**
   * (re) Create SVG
   */
  private init(): void {
    super.initialize();
    this.log.debug('init (svg)', this.dimensions.toString(), this.sources)();

    // create Bars
    let yMaxDomain = 0;
    const barsList: StackedBarSegment[][] = []; // category: plan total - plan billable - Current Total ...
    const barInfos: StackedBar[] = [];
    for (const type of this.definition.types) {
      const bars: StackedBarSegment[] = [];
      let total = 0; // bar total
      const typeLabel = BarSourceF.labelFor(type);
      for (const source of this.sources) {
        const id = this.id + '-' + type + '-' + source.name;
        const value = BarSourceF.get(source, type);
        const valueString = this.definition.prefix
          + Trl.formatNumber(value, this.definition.decimals)
          + this.definition.suffix;
        //
        const domainLabel = source.label;
        const domainLabelSan = domainLabel
          .replace(/</g, '&le;')
          .replace(/>/g, '&gt;');
        // console.debug('--', domainLabelSan);
        const tooltip = domainLabelSan
          + '<br><small>' + typeLabel
          + ':</small> ' + valueString;
        const info = type + '/' + source.label + '=' + value
          + ' (' + total + '-' + (total + value) + ')';
        const bar: StackedBarSegment = {
          id,
          type,
          typeLabel,
          domainName: source.name,
          domainLabel,
          value,
          valueStart: total,
          valueEnd: total + value,
          valueString,
          tooltip,
          info
        };
        //
        bars.push(bar);
        total += value;
        // this.log.debug('init - ' + bar.info)();
      } // for all sources
      barsList.push(bars);
      barInfos.push({
        type,
        typeLabel,
        value: total,
        valueString: this.definition.prefix
          + Trl.formatNumber(total, this.definition.decimals)
          + this.definition.suffix
      });
      if (yMaxDomain < total) {
        yMaxDomain = total;
      }
    } // for all types
    // this.log.debug('init (bars) max=' + yMaxDomain, bars)();

    // -- X -- https://github.com/d3/d3-scale#ordinal-scales
    const xScale: d3Scale.ScaleBand<string> = d3Scale.scaleBand()
      .rangeRound([0, this.dimensions.boundedWidth])
      .paddingInner(0.05)
      .align(0.1);
    const typeKeys: string[] = this.definition.types.map(type => BarSourceF.labelFor(type));
    xScale.domain(typeKeys);
    this.log.debug('init (x)', xScale(BarSourceF.labelFor(this.definition.types[ 0 ])),
      xScale.bandwidth(), xScale.domain(), xScale.range())();
    // xScale.domain(bars.map(b => b.typeLabel));
    // https://github.com/d3/d3-axis
    super.createXAxis(xScale);

    // --- Y ---
    const yScale: d3Scale.ScaleLinear<number, number> = d3Scale.scaleLinear()
      .rangeRound([this.dimensions.boundedHeight, 0])
      .domain([0, yMaxDomain])
      .nice();
    this.log.debug('init (y)',
      'max=' + yMaxDomain, yScale(1), yScale(yMaxDomain),
      yScale.domain(), yScale.range())(); // 255, 0
    super.createYAxis(yScale);

    // --- color ---
    const domainKeys: string[] = this.sources.map((bs: BarSourceI) => bs.name); // projectLineId
    const zScale: d3Scale.ScaleOrdinal<string, string> = d3Scale.scaleOrdinal(d3Chromatic.schemeSet3)
      .domain(domainKeys); // https://github.com/d3/d3-scale-chromatic
    this.log.debug('init (domainKeys)', domainKeys)();

    // bars
    let barNo = 0;
    for (const bars /* tackedBarSegment[] */ of barsList) { // category: plan total - plan billable - Current Total ...
      barNo++;
      const barSegSelector = '.sbc-bar-' + barNo;
      const svgRectangles: d3.Selection<SVGRectElement, StackedBarSegment, SVGGElement, any> = this.svgMain
        .selectAll<SVGRectElement, StackedBarSegment>('rect' + barSegSelector)
        .data(bars, d => d.id);

      svgRectangles.enter()
        .append<SVGRectElement>('rect')
        .attr('id', (d: StackedBarSegment) => d.id)
        .attr('width', xScale.bandwidth)
        .attr('class', barSegSelector.substring(1))
        .merge(svgRectangles)
        // .transition()
        .attr('data-info', d => d.info)
        .attr('x', (d: StackedBarSegment) => xScale(d.typeLabel) ?? 0)
        .attr('y', d => yScale(d.valueEnd))
        .attr('height', d => yScale(d.valueStart) - yScale(d.valueEnd))
        .attr('fill', d => zScale(d.domainName))
        .on('mouseover', (d) => {
          this.tooltipDiv.style('display', 'block');
        })
        .on('mouseout', (d) => {
          this.tooltipDiv.style('display', 'none');
        })
        .on('mousemove', (d) => {
          const xP = d.event.pageX;
          const yP = d.event.pageY;
          this.tooltipDiv.style('top', yP + 'px');
          this.tooltipDiv.style('left', (xP + 15) + 'px');
          this.tooltipDiv.html(d.tooltip);
        });
      svgRectangles.exit().remove();

      // bar segment labels
      const textBarClass = 'sbc-seg-label';
      const textBarSelector = '.sbc-bar-label-' + barNo;
      const svgBarLabels = this.svgMain
        .selectAll<SVGTextElement, StackedBarSegment>(textBarSelector)
        .data(bars, d => d.id);
      svgBarLabels.enter()
        .append<SVGTextElement>('text')
        .attr('class', textBarClass + ' ' + textBarSelector.substring(1))
        .attr('clip-path', d => 'url(#clip-' + d.type + ')')
        .merge(svgBarLabels)
        .attr('x', d => (xScale(d.typeLabel) ?? 0) + 5)
        .attr('y', d => yScale(d.valueEnd) + 15)
        .text(d => d.domainLabel)
        .filter((d) => { // don't display small labels
          return d.value === 0 || yScale(d.valueStart) - yScale(d.valueEnd) < 18;
        })
        .attr('style', 'display: none;');
      svgBarLabels.exit().remove();
    } // forEach bar

    // bar top label
    const textTopSelector = '.sbc-bar-label';
    const svgTopLabels = this.svgMain
      .selectAll<SVGTextElement, StackedBar>(textTopSelector)
      .data(barInfos);
    svgTopLabels.enter()
      .append<SVGTextElement>('text')
      .attr('class', textTopSelector.substring(1))
      .merge(svgTopLabels)
      .attr('x', d => (xScale(d.typeLabel) ?? 0) + xScale.bandwidth())
      .attr('y', d => yScale(d.value) - 5)
      .text(d => d.valueString);
    svgTopLabels.exit().remove();

    // segment text clips
    const svgClips = this.svgMain
      .selectAll<SVGClipPathElement, StackedBar>('clipPath')
      .data(barInfos);
    svgClips.enter()
      .append<SVGClipPathElement>('clipPath')
      .merge(svgClips)
      .attr('id', d => 'clip-' + d.type)
      .call(parent => {
        if (parent.select('rect').empty()) {
          parent.append('rect')
            .attr('x', d => xScale(d.typeLabel) ?? 0)
            .attr('width', xScale.bandwidth)
            .attr('y', 0)
            .attr('height', this.dimensions.boundedHeight);
        }
      });
    svgClips.exit().remove();

  } // init

} // StackedBarChartComponent
