import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ResourceService} from './resource.service';
import {concatMap, filter, map} from 'rxjs/operators';
import {
  resourceLoadAllRequestAction,
  resourceLoadAllResultAction,
  resourceProjectAllocationLoadResultAction,
  resourceProjectLineLoadResultAction,
  resourceSelectedAction
} from './resource.actions';
import {itemLoadResourceRequestAction} from '../te-item/te-item.actions';


@Injectable()
export class ResourceEffects {

  // -- resource load
  loadResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourceLoadAllRequestAction),
      concatMap(action => this.service.loadResources()),
      map(resources => resourceLoadAllResultAction({ resources }))
    ), {}
  );

  // -- resource selected -- (1) lines (all)
  selectResourcePLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourceSelectedAction),
      map(action => action.resource?.id),
      filter(resourceId => !!resourceId),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      concatMap(resourceId => this.service.loadResourceProjectLines(resourceId!, 0)),
      map(projectLines => resourceProjectLineLoadResultAction({projectLines}))
    ), {}
  );

  // -- resource selected -- (2) allocations (all)
  selectResourceProjectAllocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourceSelectedAction),
      map(action => action.resource?.id),
      filter(resourceId => !!resourceId),
      concatMap(resourceId => this.service.loadResourceAllocations(resourceId, undefined, undefined)),
      map(projectAllocations => resourceProjectAllocationLoadResultAction({projectAllocations}))
    ), {}
  );

  // -- resource selected -- (3) items
  selectResourceItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourceSelectedAction),
      map(action => action.resource?.id),
      filter(resourceId => !!resourceId),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map(resourceId => itemLoadResourceRequestAction({resourceId: resourceId!, dateFrom: 0}))
    ), {}
  );


  /* -- resource: all project lines
  selectResourceProjectLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectLineResourceLoadRequestAction),
      concatMap(action => this.service.loadResourceProjectLines(action.resourceId, action.dateFrom)),
      map(projectLines => projectLineResourceLoadResultAction({ projectLines }))
    ), {}
  );

  // -- resource: all project allocations
  selectResourceProjectAllocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectAllocationsLoadRequestAction),
      concatMap(action => this.service.loadResourceProjectAllocations(action.resourceId, action.dateFrom)),
      map(projectAllocations => projectAllocationsLoadResultAction({ projectAllocations }))
    ), {}
  );
  */


  constructor(private actions$: Actions,
              private service: ResourceService) {
  }

} // ResourceEffects
