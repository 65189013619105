export class PsaUtil {

  /**
   * ProjectLineSharing additional columns:
   * - projectLine.name, projectLine.project.name, projectLine.startPlan, projectLine.endPlan
   */
  static readonly selectAddlColumns: string[] = [
    'accorto__Project_Line__r.Name',
    'accorto__Project_Line__r.accorto__Project__r.Name',
    'accorto__Project_Line__r.accorto__Start_Plan__c',
    'accorto__Project_Line__r.accorto__End_Plan__c',
    'accorto__Project_Line__r.accorto__Effort_Plan__c'
  ];

  static readonly AllocProjectLineName = 'projectLine.name';
  static readonly AllocProjectName = 'projectLine.project.name';
  static readonly AllocProjectLineStartPlan = 'projectLine.startPlan';
  static readonly AllocProjectLineEndPlan = 'projectLine.endPlan';
  static readonly AllocProjectLineEffortPlan = 'projectLine.effortPlan';


} // PsaUtil
