import { DataRecordI } from './data-record-i';
import { CRequestI } from './c-request-i';
import { DataViewI } from './data-view-i';

/* GenTsInterface client: com.accorto.domain.CRequestData */
/* tslint:disable max-line-length no-inferrable-types */

export enum DataOperation {
  DELETE = 'DELETE',
  QUERY = 'QUERY',
  SAVE = 'SAVE'
}

/**
 * extends: CRequestI
 * name: CRequestData
 */
export interface CRequestDataI extends CRequestI {

  /**
   * SELECT ... WHERE ... ORDER
   * label: Query Data View
   */
  dataView?: DataViewI;

  /**
   * label: Count only
   */
  isCount?: boolean;

  /**
   * label: Query Limit: Number of records
   */
  limit?: number;

  /**
   * label: Query Offset: Start
   */
  offset?: number;

  /**
   * label: Operation
   */
  operation?: DataOperation;

  /**
   * label: Table Name
   */
  tableName?: string;

  /**
   * save, delete
   * label: Data Record
   */
  record?: DataRecordI;

  /**
   * query, delete
   * label: Record Id
   */
  recordId?: string;

  /**
   * query, delete
   * label: Record Ids
   */
  recordIds?: string[];

  /**
   * save, delete
   * label: Data Records
   */
  records?: DataRecordI[];

  /**
   * label: Query Table Id
   */
  tableId?: number;

} // CRequestData

/** CRequestData Definition */
export const CRequestDataD = {
  recordTypeName: 'CRequestData',
  dataView: {
    n: 'dataView',
    t: 'DataViewI'
  },
  isCount: {
    n: 'isCount',
    t: 'boolean'
  },
  limit: {
    n: 'limit',
    t: 'number'
  },
  offset: {
    n: 'offset',
    t: 'number'
  },
  operation: {
    n: 'operation',
    t: 'DataOperation'
  },
  tableName: {
    n: 'tableName',
    t: 'string'
  },
  record: {
    n: 'record',
    t: 'DataRecordI'
  },
  recordId: {
    n: 'recordId',
    t: 'string'
  },
  recordIds: {
    array: true,
    n: 'recordIds',
    t: 'string'
  },
  records: {
    array: true,
    n: 'records',
    t: 'DataRecordI'
  },
  tableId: {
    n: 'tableId',
    t: 'number'
  },
}; // CRequestData
