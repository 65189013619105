import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FkService } from '../fk/fk.service';
import { select, Store } from '@ngrx/store';
import { selectLoginSession } from '../login/login.selectors';
import { Session } from '../model/session';
import { selectUis } from '../ui/ui.selectors';
import { Subscription } from 'rxjs';
import { selectFkCaches } from '../fk/fk.selectors';
import { FkCache } from '../fk/fk-cache';
import { UiService } from '../ui/ui.service';
import { DataService } from '../data/data.service';
import { AccortoService, MemoryStats } from '../accorto.service';
import { Preference } from '../utils/preference';
import { Logger } from '../log/logger';
import { FkRequests } from '../fk/fk-requests';
import { UiTabI } from '../model/ui-tab-i';

/**
 * Status Component
 */
@Component({
  selector: 'acc-status',
  templateUrl: './status.component.html',
  styleUrls: [ './status.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class StatusComponent implements OnInit, OnDestroy {

  content: string[][] = [];
  /** Session */
  session: Session = new Session();
  /** UIs */
  uis: { [ key: string ]: UiTabI } = {};
  /** FKs */
  fks: { [ key: string ]: FkCache } = {};
  /** Preferences */
  preferences: Preference[] = [];
  /** Memory Statistics */
  memStats: MemoryStats[] = [];


  private subscriptions: Subscription[] = [];
  private log: Logger = new Logger('Status');

  constructor(private store: Store<object>,
              private config: AccortoService,
              private fkService: FkService, private uiService: UiService, private dataService: DataService) {
    this.memStats = config.memStats;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.pipe(select(selectLoginSession)).subscribe((session) => {
        if (session) {
          this.session = session;
        }
      })
    );
    this.subscriptions.push(
      this.store.pipe(select(selectUis)).subscribe((uis) => {
        if (uis) {
          this.uis = uis;
        }
      })
    );
    this.subscriptions.push(
      this.store.pipe(select(selectFkCaches)).subscribe((fks) => {
        if (fks) {
          this.fks = fks;
        }
      })
    );

    this.onClickRefresh();
  } // ngOnInit

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this.subscriptions = [];
  } // ngOnDestroy

  /**
   * Build Content
   */
  onClickRefresh(): void {
    this.log.debug('refresh')();
    this.config.getMemoryStats();
    this.memStats = this.config.memStats;
    this.content = [];

    // Session
    if (this.session) {
      const createdInfo = this.session.created ? new Date(this.session.created).toISOString() : '';
      this.addLine('Session', this.session.name, createdInfo);
      this.addLine(' -', 'firstDay(1..7)=' + this.session.firstDayOfWeek, 'uid=' + this.session.tenantUserId);
      this.addLine(' -', 'loc=' + this.session.locale, 'tz=' + this.session.timezone);
      this.addLine(' -', 'sfUid=' + this.session.sfUserId, 'rid=' + this.session.sfResourceId);
      this.addLine(' -', JSON.stringify(this.session.settings), '');
    }

    this.addLine('Environment', '', '');
    Object.keys(this.config.env).forEach((key) => {
      const value = this.config.env[ key ];
      this.addLine('-', key, value);
    });
    this.addLine('Requests', String(this.config.requestCount), '');

    // Uis
    this.addLine('UIs', '#' + Object.keys(this.uis).length, this.uiService.summary());
    for (const key of Object.keys(this.uis)) {
      this.addLine(' -', key, '');
    }

    // FKs
    this.addLine('FKs', '#' + Object.keys(this.fks).length, this.fkService.summary());
    for (const key of Object.keys(this.fks)) {
      const fkc = this.fks[ key ];
      if (fkc) {
        this.addLine(' -', fkc.toString(), fkc.parentInfo());
      } else {
        this.addLine(' -', key, 'NotFound');
      }
    }
    this.addLine('FK Requests', FkRequests.toString(), '');

    // Data
    this.addLine('Data', '', this.dataService.summary());

    // update preferences
    this.preferences = this.config.preferences();
    // console.log(this.preferences);
  } // onClickRefresh

  /**
   * Preference changed
   */
  onPreferenceChange(event: Event, pref: Preference): void {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    pref.save(value);
    this.log.debug('onPreferenceChange', pref.name, value)();
  }


  addLine(label: string, value: string, comment: string): void {
    this.content.push([ label, value, comment ]);
  }

} // StatusComponent
