import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, finalize, first, tap } from 'rxjs/operators';

import { DataRecordI } from 'accorto';
import { AppState } from '../reducers';
import { selectProjectsAll } from './project.selectors';
import { projectLoadAllRequestAction } from './project.actions';

@Injectable({
  providedIn: 'root'
})
export class ProjectResolver implements Resolve<DataRecordI[]> {

  loading = false;

  constructor(private store: Store<AppState>) {
  }

  /**
   * Load All Projects
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<DataRecordI[]> | Promise<DataRecordI[]> | DataRecordI[] {

    return this.store
      .pipe(
        select(selectProjectsAll),
        tap((projects) => {
          // console.log('ProjectResolver ' + this.loading, projects);
          if (!this.loading && (!projects || projects.length === 0)) {
            this.loading = true;
            // console.log('ProjectResolver request');
            this.store.dispatch(projectLoadAllRequestAction());
          }
        }),
        filter(projects => !!projects),
        first(),
        finalize(() => {
          // console.log('ProjectResolver fini');
          this.loading = false;
        })
      );
  }

} // ProjectResolver
