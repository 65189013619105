import {DataRecordF, DataRecordI, DateUtil, ProjectLineSharingD, ResourceD} from 'accorto';
import {HeaderInfoDay} from './header-info-day';
import {AllocationInfoDay} from './allocation-info-day';

/**
 * Allocation Line
 */
export class AllocationInfo {

  name?: string;
  label?: string;
  title?: string;

  resourceId?: string;

  projectLineId?: string;
  plannedStart?: number;
  plannedEnd?: number;

  /** Total Allocations (date range) */
  public sum: number = 0;
  /** Total Allocations */
  public total: number = 0;

  /** Planned Detail Object */
  plannedDetails: { [key: string]: number } = {};

  isWeekly: boolean = false;
  /** Days */
  dayList: AllocationInfoDay[] = [];
  dayPrefix: string = '';


  /**
   * Allocation Info Line
   * @param allocation project line allocation
   * @param res resource
   */
  constructor(public allocation: DataRecordI, public res: DataRecordI) {
    this.name = allocation.name;
    // Allocation details
    if (!allocation.details) {
      const detailString = DataRecordF.value(allocation, ProjectLineSharingD.plannedDetails.n);
      if (detailString && detailString.length > 0) {
        allocation.details = JSON.parse(detailString);
      } else {
        allocation.details = {};
      }
    }
    this.plannedDetails = allocation.details;
    // resource
    this.resourceId = res.id;
    // project line
    this.projectLineId = DataRecordF.value(allocation, ProjectLineSharingD.projectLineSfId.n) ?? '';
  } // constructor

  get capacityDay(): number {
    const nn = DataRecordF.valueNumber(this.res, ResourceD.capacityDay.n, 8);
    return nn > 0 ? nn : 8;
  }

  get daysWeek(): number {
    const nn = DataRecordF.valueNumber(this.res, ResourceD.daysWeek.n, 5);
    return nn > 0 ? nn : 5;
  }


  /**
   * Build Allocation Day List
   */
  buildDayList(isWeekly: boolean, headerInfoList: HeaderInfoDay[]): void {
    this.isWeekly = isWeekly;
    this.sum = 0;
    this.dayList = [];
    for (const hdr of headerInfoList) {
      const isDisplayed = this.isDisplayed(hdr.ms);
      const value: number = this.getValue(hdr.ms, hdr.isWeekly); // get from details
      const id = this.projectLineId + '_' + this.resourceId + '_' + (isWeekly ? 'w' : 'd') + hdr.ms;
      const day = new AllocationInfoDay(id, hdr.ms, hdr.isWeekly, hdr.isWeekend, hdr.isWeekStartDay, isDisplayed,
        value, this.dayPrefix);
      // this.setValue(day.ms, day.isWeekly, day.value); // set explicitly
      this.dayList.push(day);
      this.sum += value;
    }
    this.total += this.getTotal(isWeekly);
    this.allocation.changeMap[ ProjectLineSharingD.plannedEffort.n ] = String(this.sum);
  } // buildDayList


  /**
   * Get Total from Details
   * @param weekly weekly
   */
  getTotal(weekly: boolean): number {
    let totalWeek: number = 0;
    let totalDay: number = 0;
    for (const key of Object.keys(this.plannedDetails)) {
      const value = this.plannedDetails[ key ];
      if (key.startsWith('w')) {
        totalWeek += value;
      } else {
        totalDay += value;
      }
    }
    // console.debug('ProjectAllocationInfo.getTotal', totalDay, totalWeek, this.plannedDetails, );
    return weekly ? totalWeek : totalDay;
  } // getTotal

  /**
   * Is the date in the planned date range
   * @param ms the day
   */
  isDisplayed(ms: number): boolean {
    if (this.plannedStart && this.plannedEnd
      && ms >= this.plannedStart && ms <= this.plannedEnd) {
      return true;
    }
    if (this.plannedEnd
      && (!this.plannedStart || this.plannedStart === 0) && ms <= this.plannedEnd) {
      return true;
    }
    if (this.plannedStart
      && ms >= this.plannedStart && (!this.plannedEnd || this.plannedEnd === 0)) {
      return true;
    }
    return (!this.plannedStart || this.plannedStart === 0)
      && (!this.plannedEnd || this.plannedEnd === 0);
  } // isDisplayed

  /**
   * Set the value for day
   * @param value new value
   * @param ad the day
   */
  setValueDay(value: number, ad: AllocationInfoDay): string {
    if (this.isWeekly) {
      value = this.setValueWeek(ad.ms, value); // value - remaining
    }
    return ad.setValue(value);
  } // setValueDay

  /**
   * Toggle the day value
   * @param ad the day
   */
  toggleDay(ad: AllocationInfoDay): string {
    const capacityDay = DataRecordF.valueNumber(this.res, ResourceD.capacityDay.n, 8);
    if (this.isWeekly) {
      const daysWeek = DataRecordF.valueNumber(this.res, ResourceD.daysWeek.n, 5);

      return ad.toggle(capacityDay * daysWeek);
    }
    return ad.toggle(capacityDay);
  } // toggleDay

  /**
   * Update Allocation Sum of days displayed and set detail value
   * @return total
   */
  updateSum(): number {
    this.sum = 0; // date-range
    for (const day of this.dayList) {
      this.setValue(day.ms, day.isWeekly, day.value);
      this.sum += day.value;
    }

    // total - all
    this.total = this.getTotal(this.isWeekly);
    this.allocation.changeMap[ ProjectLineSharingD.plannedEffort.n ] = String(this.total);
    // console.debug('ProjectAllocationInfo.updateSum', this.total);
    return this.total;
  } // updateSum

  /**
   * Get Value for day/week
   * @param ms ms
   * @param isWeekly weekly
   */
  protected getValue(ms: number, isWeekly: boolean): number {
    if (isWeekly) {
      return this.getValueWeek(ms);
    }
    const key = 'd' + ms;
    const value = this.plannedDetails[ key ];
    return value ? value : 0;
  } // getValue

  /**
   * Get Value for week
   * @param ms start ms
   */
  protected getValueWeek(ms: number): number {
    let value = 0;
    for (let dd = 0; dd < 7; dd++) { // days
      const key = 'd' + (ms + (dd * DateUtil.ONEDAY));
      const vv = this.plannedDetails[ key ];
      if (vv) {
        value += vv;
      }
    }
    return value;
  } // valueWeek

  /**
   * Set Value for day/week
   * @param ms ms
   * @param weekly weekly
   * @param value new value
   */
  protected setValue(ms: number, weekly: boolean, value: number): void {
    if (weekly) {
      this.setValueWeek(ms, value);
    } else {
      const key = (weekly ? 'w' : 'd') + ms;
      this.plannedDetails[ key ] = value;
      //
      this.setValuePlannedDetails();
    }
  } // setValue

  /**
   * Set Planned Details string - eliminate 0's
   */
  protected setValuePlannedDetails(): void {
    const details: { [ key: string ]: number } = {};
    for (const key of Object.keys(this.plannedDetails)) {
      const vv = this.plannedDetails[ key ];
      if (vv) {
        details[ key ] = vv;
      }
    }
    const detailString = JSON.stringify(details);
    this.allocation.changeMap[ ProjectLineSharingD.plannedDetails.n ] = detailString;
  }

  /**
   * Set Value for day/week
   * @param ms start ms
   * @param value total value
   * @return allocated value
   */
  protected setValueWeek(ms: number, value: number): number {
    const capacityDay = DataRecordF.valueNumber(this.res, ResourceD.capacityDay.n, 8);
    let remaining = value ? value : 0;
    let info = this.label;

    // distribute per week day
    for (let dd = 0; dd < 7; dd++) { // days
      const msDay = ms + (dd * DateUtil.ONEDAY);
      const key = 'd' + msDay;
      if (DateUtil.isWeekend(new Date(msDay)) || !this.isDisplayed(msDay)) {
        this.plannedDetails[ key ] = 0;
        info += ' _';
      } else {
        const valueDay = remaining > 0 ? Math.min(remaining, capacityDay) : 0;
        this.plannedDetails[ key ] = valueDay;
        info += ' ' + valueDay;
        remaining -= valueDay;
      }
    }
    // console.debug('ProjectAllocationInfo.setValueWeek', info, 'remaining=' + remaining);
    //
    this.setValuePlannedDetails();
    return value - remaining;
  } // setValueWeek

} // AllocationInfo
