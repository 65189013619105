import { DataRecordF, DataRecordI, ProjectLineD } from 'accorto';
import { AllocationInfo } from '../allocation-info';

/**
 * Project Allocation - Table Line
 */
export class ProjectAllocationInfo extends AllocationInfo {

  /**
   * Project Line Allocation
   * @param allocation allocation
   * @param res resource
   * @param pl project line
   * @param isLineResource mark as line resource
   */
  constructor(allocation: DataRecordI, res: DataRecordI, private pl: DataRecordI, isLineResource: boolean) {
    super(allocation, res);
    this.label = DataRecordF.labelName(res) + (isLineResource ? ' \u2606' : ''); // white star
    this.dayPrefix = DataRecordF.codeLabel(pl) + ': ' + DataRecordF.codeLabel(res);
    this.title = this.dayPrefix + (isLineResource ? ' (Line Resource)' : '');

    // Project Line
    this.projectLineId = pl.id;
    this.plannedStart = DataRecordF.valueNumber(pl, ProjectLineD.plannedStart.n, 0);
    this.plannedEnd = DataRecordF.valueNumber(pl, ProjectLineD.plannedEnd.n, 0);
  }

} // ProjectAllocationInfo
