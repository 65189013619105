import { createAction, props } from '@ngrx/store';
import { DataRecordI } from 'accorto';


export const itemLoadProjectRequestAction = createAction(
  '[TeItem] Load Project Request',
  props<{ projectId: string }>()
);

export const itemLoadProjectResultAction = createAction(
  '[TeItem/api] Loaded Project Results',
  props<{ items: DataRecordI[] }>()
);

/**
 * init: resource-overview.refresh
 * - effect: loadResourceTeItems$
 * -- service: loadResourceItems
 * -- itemLoadResourceResultAction
 * --- reducer: state.resourceItems
 */
export const itemLoadResourceRequestAction = createAction(
  '[TeItem] Load Resource Request',
  props<{ resourceId: string, dateFrom: number }>()
);

export const itemLoadResourceResultAction = createAction(
  '[TeItem/api] Loaded Resource Results',
  props<{ items: DataRecordI[] }>()
);


