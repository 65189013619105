/**
 * Screen Dimensions
 */
import {ElementRef} from '@angular/core';

/**
 * Maintain Dimensions of Container
 *
 * <div #container />
 * @ViewChild('container') container: ElementRef;
 *
 * public ngAfterViewChecked(): void {
 *   this.onResize(undefined);
 * }
 *
 * @HostListener('window:resize', ['$event'])
 * onResize(event: Event) {
 *  this.dimensions.updateWidth(this.container);
 * }
 */
export class Dimensions {

  /** Bounded or content/inner height - margins */
  public boundedHeight: number;
  /** Bounded or content/inner width - margins */
  public boundedWidth: number;

  /**
   * Container dimensions
   * @param width container outer width
   * @param height container outer height
   * @param marginTop optional container margin - or margin for all(10)
   * @param marginRight optional container right margin - default margin top (10)
   * @param marginBottom optional container bottom margin - default margin top (10)
   * @param marginLeft optional container left margin - default margin right (10)
   */
  constructor(public width: number,
              public height: number,
              public marginTop: number = 10,
              public marginRight: number = -1,
              public marginBottom: number = -1,
              public marginLeft: number = -1) {
    if (this.marginRight === -1) {
      this.marginRight = this.marginTop;
    }
    if (this.marginBottom === -1) {
      this.marginBottom = this.marginTop;
    }
    if (this.marginLeft === -1) {
      this.marginLeft = this.marginRight;
    }
    this.boundedHeight = Math.max(this.height - this.marginTop - this.marginBottom, 0);
    this.boundedWidth = Math.max(this.width - this.marginLeft - this.marginRight, 0);
  }

  /**
   * Update dimensions from container
   * @param container container
   */
  public updateDimensions(container: ElementRef | undefined): void {
    this.updateHeight(container);
    this.updateWidth(container);
  }

  /**
   * @param container container providing offset height
   * @return true if changed
   */
  public updateHeight(container: ElementRef | undefined): boolean {
    if (container) {
      const oldHeight = this.height;
      this.height = container.nativeElement.offsetHeight;
      // console.log('updateHeight=' + this.height);
      this.boundedWidth = Math.max(this.height - this.marginTop - this.marginBottom, 0);
      return oldHeight !== this.height;
    }
    return false;
  }

  /**
   * @param container container providing offset width
   * @return true if changed
   */
  public updateWidth(container: ElementRef | undefined): boolean {
    if (container) {
      const oldWidth = this.width;
      this.width = container.nativeElement.offsetWidth;
      // console.log('updateWidth=' + this.width);
      this.boundedWidth = Math.max(this.width - this.marginLeft - this.marginRight, 0);
      return oldWidth !== this.width;
    }
    return false;
  }

  toString(): string {
    return this.width + 'x' + this.height + '(' + this.boundedWidth + 'x' + this.boundedHeight + ')';
  }

} // Dimensions
