import {createAction, props} from '@ngrx/store';
import {CResponseDataI, DataRecordI} from 'accorto';


/**
 * load all projects
 * - initiated: resolver
 * - effect: service.loadProjects -> projectLoadAllResultAction
 * -- reducer: state.allProjectsMap
 */
export const projectLoadAllRequestAction = createAction(
  '[Projects] Load All Projects Request'
);
export const projectLoadAllResultAction = createAction(
  '[Projects/api] Load All Projects Results',
  props<{ projects: DataRecordI[] }>()
);


/**
 * add/update/delete/clear project
 * - initiated: -
 * - reducer ...
 */
export const projectAddAction = createAction(
  '[Project*] Add Project',
  props<{ project: DataRecordI }>()
);

/**
 * project save
 * - initiated: project-editor
 * - effect: service.save -> projectSaveResultAction
 * -- effect: projectSelectedAction
 * -- effect: projectAddAction
 */
export const projectSaveRequestAction = createAction(
  '[Project] Save Project Request',
  props<{ project: DataRecordI }>()
);
export const projectSaveResultAction = createAction(
  '[Project/api] Save Project Response',
  props<{ responseData: CResponseDataI }>()
);


export const projectUpdateAction = createAction(
  '[Project*] Update Project',
  props<{ project: DataRecordI }>()
);
export const projectDeleteAction = createAction(
  '[Project*] Delete Project',
  props<{ id: string }>()
);
export const projectsClearAction = createAction(
  '[Project*] Clear Projects'
);

/**
 * set project (load dependents)
 * - initiated: dashboard, psa-base
 * - reducer: state.currentProject (clear dependents)
 * - effect
 * -- service.loadProjectLines -> projectLineLoadResultAction
 * -- service.loadProjectPhases -> projectPhaseLoadResultAction
 * -- service.loadProjectAllocations -> projectAllocationLoadResultAction
 * -- itemLoadProjectRequestAction
 * -- invoiceLoadRequestAction
 */
export const projectSelectedAction = createAction(
  '[Project] Selected Project',
  props<{ project: DataRecordI | undefined }>()
);
/**
 * update project only
 * - initiated: project-allocation - onSave
 * - reducer: state.currentProject
 */
export const projectSelectedUpdateAction = createAction(
  '[Project] Selected Project Update',
  props<{ project: DataRecordI }>()
);

/**
 * project lines
 * - initiated: project-allocation - onSave, effects: selectProjectLines$
 * - reducer: state.currentProjectLines
 */
export const projectLinesLoadResultAction = createAction(
  '[Project/api] Load Selected Project Line Results',
  props<{ projectLines: DataRecordI[] }>()
);
/**
 * project phases
 * - initiated: effects: selectProjectPhases$
 * - reducer: state.currentProjectPhases
 */
export const projectPhasesLoadResultAction = createAction(
  '[Project/api] Load Selected Project Phase Results',
  props<{ projectPhases: DataRecordI[] }>()
);

/**
 * project allocation
 * - initiated: project-allocation - onSave, effects: selectProjectAllocations$
 * - reducer: state.currentProjectAllocations
 */
export const projectAllocationsLoadResultAction = createAction(
  '[Project/api] Load Selected Project Allocation Results',
  props<{ projectAllocations: DataRecordI[] }>()
);


/**
 * project line save
 * - initiated: project-burn-down
 * - effect: service.save -> projectLineSaveResultAction
 * -- reducer: state.currentProjectLines
 */
export const projectLineSaveRequestAction = createAction(
  '[Project Line] Save Project Line Request',
  props<{ projectLine: DataRecordI }>()
);
export const projectLineSaveResultAction = createAction(
  '[Project Line/api] Save Project Line Response',
  props<{ responseData: CResponseDataI }>()
);

/**
 * project lines save
 * - initiated: project-allocation-detail.onSave
 * - effect: service.saveList -> projectLinesSaveResultAction
 */
export const projectLinesSaveRequestAction = createAction(
  '[Project Lines] Save Project Lines Request',
  props<{ projectLines: DataRecordI[] }>()
);
/**
 * project lines save result
 * - initiated: effect projectLinesSave$
 * - effect
 * -- notification
 * -- service.loadProjectLines -> projectLinesLoadResultAction
 */
export const projectLinesSaveResultAction = createAction(
  '[Project Lines] Save Project Lines Response',
  props<{ responseData: CResponseDataI }>()
);

/**
 * Project Line delete request
 * - initiated: manually
 * - effect:
 * -- reload current lines (projectLineDeleteResultAction)
 */
export const projectLineDeleteRequestAction = createAction(
  '[Project Line] Delete Project Line Request',
  props<{ projectLine: DataRecordI }>()
);
export const projectLineDeleteResultAction = createAction(
  '[Project Line] Delete Project Lines Response',
  props<{ responseData: CResponseDataI }>()
);


/**
 * project allocations save
 * - initiated: project-allocation-detail.onSave
 * - effect: service.saveList -> projectAllocationsSaveResultAction
 */
export const projectAllocationsSaveRequestAction = createAction(
  '[Project Allocations] Save Project Allocations Request',
  props<{ projectAllocations: DataRecordI[] }>()
);
/**
 * project allocations save result
 * - initiated: effect projectAllocationsSave$
 * - effect
 * -- notification
 * -- service.loadProjectAllocations -> projectAllocationsLoadResultAction
 */
export const projectAllocationsSaveResultAction = createAction(
  '[Project Allocations] Save Project Allocations Response',
  props<{ responseData: CResponseDataI }>()
);

