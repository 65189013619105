import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataResolver, LoginGuard, ObjectHomeComponent, RecordHomeComponent, StatusComponent, UiResolver } from 'accorto';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectImportComponent } from './project-import/project-import.component';
import { ProjectResolver } from './project/project.resolver';
import { ResourceResolver } from './resource/resource.resolver';
import { ProjectAllocationComponent } from './project-allocation/project-allocation.component';
import { ProjectSheetComponent } from './project-sheet/project-sheet.component';
import { ResourceOverviewComponent } from './resource-overview/resource-overview.component';
import { AllocResourceComponent } from './alloc/alloc-resource/alloc-resource.component';
import { AllocProjectComponent } from './alloc/alloc-project/alloc-project.component';
import { ProjectWorkbenchComponent } from './workbench/project-workbench/project-workbench.component';
import { ResourceWorkbenchComponent } from './workbench/resource-workbench/resource-workbench.component';
import { CalendarComponent } from './calendar/calendar.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login',
      description: 'Please provide Credentials for Accorto PSA Workbench',
      keywords: 'Accorto PSA, Professional Services Application'
    }
  },

  {
    path: '',
    component: DashboardComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Welcome',
      description: 'Accorto PSA Workbench',
      keywords: 'Accorto PSA, Professional Services Application'
    }
  },
  {
    path: 'dashboard/:id',
    component: DashboardComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Welcome',
      description: 'Accorto PSA Workbench',
      keywords: 'Accorto PSA, Professional Services Application'
    }
  },


  {
    path: 'project/:id',
    component: ProjectSheetComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Project Sheet',
      description: 'Accorto Project Overview',
      keywords: 'Project Sheet'
    }
  },
  {
    path: 'project',
    component: ProjectSheetComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Project Sheet',
      description: 'Accorto Project Overview',
      keywords: 'Project Sheet'
    }
  },

  {
    path: 'project-wb/:id',
    component: ProjectWorkbenchComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Project Workbench',
      description: 'Accorto Project Workbench',
      keywords: 'Project Workbench'
    }
  },
  {
    path: 'project-wb',
    component: ProjectWorkbenchComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Project Workbench',
      description: 'Accorto Project Workbench',
      keywords: 'Project Workbench'
    }
  },


  {
    path: 'project-alloc/:id',
    component: ProjectAllocationComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Project Allocation',
      description: 'Accorto Project Allocation',
      keywords: 'Project Allocation'
    }
  },
  {
    path: 'project-alloc',
    component: ProjectAllocationComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Project Allocation',
      description: 'Accorto Project Allocation',
      keywords: 'Project Allocation'
    }
  },

  {
    path: 'alloc-project/:id',
    component: AllocProjectComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Project Detail Allocation',
      description: 'Accorto Project Detail Allocation',
      keywords: 'Project Allocation'
    }
  },
  {
    path: 'alloc-project',
    component: AllocProjectComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Project Detail Allocation',
      description: 'Accorto Project Detail Allocation',
      keywords: 'Project Allocation'
    }
  },

  {
    path: 'alloc-resource/:id',
    component: AllocResourceComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Resource Detail Allocation',
      description: 'Accorto Resource Detail Allocation',
      keywords: 'Resource Allocation'
    }
  },
  {
    path: 'alloc-resource',
    component: AllocResourceComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Resource Detail Allocation',
      description: 'Accorto Resource Detail Allocation',
      keywords: 'Resource Allocation'
    }
  },

  {
    path: 'project-import',
    component: ProjectImportComponent,
    canActivate: [ LoginGuard ],
    data: {
      title: 'Project Import',
      description: 'Accorto Project Import',
      keywords: 'Project Import'
    }
  },


  {
    path: 'resources',
    component: ResourceOverviewComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Resource Overview',
      description: 'Accorto Resource Overview',
      keywords: 'Resource Overview'
    }
  },

  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Resource Calendar',
      description: 'Accorto Resource Calendar',
      keywords: 'Resource Calendar'
    }
  },

  {
    path: 'resource-wb/:id',
    component: ResourceWorkbenchComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Resource Workbench',
      description: 'Accorto Resource Workbench',
      keywords: 'Resource Workbench'
    }
  },
  {
    path: 'resource-wb',
    component: ResourceWorkbenchComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      projects: ProjectResolver,
      resources: ResourceResolver
    },
    data: {
      title: 'Resource Workbench',
      description: 'Accorto Resource Workbench',
      keywords: 'Resource Workbench'
    }
  },


  {
    path: 'ui/:uiTab',
    component: ObjectHomeComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      uiTab: UiResolver
    }
  },
  {
    path: 'ui/:uiTab/:id',
    component: RecordHomeComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      uiTab: UiResolver,
      record: DataResolver
    }
  },
  {
    path: 'status',
    component: StatusComponent,
    data: {
      title: 'Status',
      description: 'Client Status and settings',
      keywords: ''
    }
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
