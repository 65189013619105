import {Trl} from 'accorto';

/** ** **
 * Resource Line Day Cell
 */
export class ResourceInfoDay {

  /** date resource */
  readonly titleBase: string;
  /** List of project line infos */
  projectLines: string[] = [];

  constructor(public ms: number, public isWeekly: boolean,
              public isWeekend: boolean, public isWeekStartDay: boolean,
              public value: number, public target: number, resourceLabel: string) {
    this.titleBase = Trl.formatDateUtcMs(ms) + ' ' + resourceLabel;
  }

  get isCapacity(): boolean {
    return this.value === this.target;
  }

  get isOver(): boolean {
    return this.value > this.target;
  }

  get stringValue(): string {
    if (this.value === 0) {
      return '';
    }
    return String(this.value);
  }

  /** date resource: allocations */
  get title(): string {
    if (this.projectLines.length > 0) {
      return this.titleBase + ': ' + this.projectLines.join('; ');
    }
    return this.titleBase;
  }

  toString(): string {
    return JSON.stringify(this);
  }

} // ResourceInfoDay
