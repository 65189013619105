import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { DataRecordI } from 'accorto';
import { AppState } from '../reducers';
import { selectResourcesAll } from './resource.selector';
import { resourceLoadAllRequestAction } from './resource.actions';

@Injectable({
  providedIn: 'root'
})
export class ResourceResolver implements Resolve<DataRecordI[]> {

  loading = false;

  constructor(private store: Store<AppState>) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<DataRecordI[]> | Promise<DataRecordI[]> | DataRecordI[] {

    return this.store
      .pipe(
        select(selectResourcesAll),
        tap((resources) => {
          // console.log('ResourceResolver ' + this.loading, resources);
          if (!this.loading && (!resources || resources.length === 0)) {
            this.loading = true;
            // console.log('ResourceResolver request');
            this.store.dispatch(resourceLoadAllRequestAction());
          }
        }),
        filter(resources => !!resources),
        first(),
        finalize(() => {
          // console.log('ResourceResolver fini');
          this.loading = false;
        })
      );
  }

} // ResourceResolver
