import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {AccortoService, Dimensions, Logger, Trl} from 'accorto';
import {TimeSourceF, TimeSourceI} from './time-source';
import {TimeLine} from './time-line';
import {TimeLinePoint} from './time-line-point';
import {TimeLineLegend} from './time-line-legend';

import {GraphBase} from '../graph-base';
import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Axis from 'd3-axis';
import * as d3Shape from 'd3-shape';

/**
 * Time Line - Earned Value
 * https://en.wikipedia.org/wiki/Earned_value_management
 */
@Component({
  selector: 'psa-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: [ './time-line.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class TimeLineComponent
  extends GraphBase implements OnChanges {

  /** the id */
  @Input() id: string = '';
  /** Data Source List */
  @Input() sources: TimeSourceI[] = [];
  /** Chart Dimensions */
  @Input() dimensions: Dimensions = new Dimensions(500, 300, 20, 40);
  /** Chart Definition */
  @Input() definitions: TimeLine[] = [];

  /** cart div */
  @ViewChild('chart', {static: true}) chartContainer?: ElementRef;

  sourcesPlus: TimeSourceI[] = [];
  info?: string;

  currentSource?: TimeSourceI;
  legends: TimeLineLegend[] = [];

  /** Lines g */
  private svgLines: d3.Selection<any, any, any, any> = d3.selection();

  private isCumulated: boolean = true;
  private log: Logger = new Logger('TimeLine');


  constructor(private config: AccortoService) {
    super();
  }

  get isDebug(): boolean {
    return this.config.isDebug;
  }

  /**
   * Changes - sort sources + cumulated
   * @param changes changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    this.message = this.sources.length === 0 ? 'No Data' : undefined;
    // sort
    this.sources.sort((one, two) => {
      return one.date.getTime() - two.date.getTime();
    });
    // clone and create cumulated values
    const cum: { [key: string]: any } = {};
    let no: number = 1;
    this.sourcesPlus = this.sources.map((src) => {
      const copy: TimeSourceI = {
        date: src.date,
        no: no++,
        values: Object.assign({}, src.values)
      };
      // new values
      Object.keys(copy.values).forEach((key) => {
        const value = copy.values[ key ];
        const cumValue = cum[ key ];
        cum[ key ] = (cumValue ? cumValue : 0) + (value ? value : 0);
        copy.values[ key + 'C' ] = cum[ key ];
      });
      // add unchanged
      Object.keys(cum).forEach((key) => {
        const value = copy.values[ key + 'C' ];
        if (!value) {
          copy.values[ key + 'C' ] = cum[ key ];
        }
      });
      return copy;
    });
    //
    this.initGraph();
  } // ngOnChanges

  /**
   * Cumulated Clicked
   */
  onCumulated(event: MouseEvent): void {
    const cb = event.target as HTMLInputElement;
    this.isCumulated = cb.checked !== false;
    this.log.debug('onCumulated ' + this.isCumulated)();
    //
    this.initGraph();
  } // onCumulated

  /**
   * Draw SVG
   */
  private initGraph(): void {
    const selectorBackground = '.tl-background';
    if (super.initialize()) {
      this.svgMain
        .append('rect') // background for legend
        .attr('class', selectorBackground.substring(1))
        .attr('x', '0')
        .attr('y', '0')
        .attr('width', this.dimensions.boundedWidth)
        .attr('height', this.dimensions.boundedHeight);
      this.svgLines = this.svgMain
        .append('g')
        .attr('class', 'lines');
    }
    this.log.debug('initGraph (svg)', this.dimensions.toString(), this.sourcesPlus, this.tooltipDiv)();

    // http://bl.ocks.org/d3netxer/10a28b7aee406f4e7fce
    // http://bl.ocks.org/DStruths/9c042e3a6b66048b5bd4

    // build data
    let yMaxDomainLeft: number = 0;
    let yMaxDomainRight: number = 0;
    const lines: TimeLinePoint[][] = [];
    for (const line of this.definitions) { // line
      let acc: number = 0;
      let instanceNo = 0;
      let maxValue = 0;
      const linePoints: TimeLinePoint[] = this.sourcesPlus.map((source) => {
        instanceNo++;
        const value = TimeSourceF.get(source, line.type);
        const label = line.label;
        const tooltip = label
          + '<br><small>' + Trl.formatDate(source.date) + ':</small>  '
          + line.prefix + Trl.formatNumber(value, line.decimals) + line.suffix
          + ' (' + Trl.formatNumber(acc + value, line.decimals) + ')';
        const tlp: TimeLinePoint = {
          timePoint: source.date.getTime(),
          label,
          value,
          valueCumulated: acc + value,
          tooltip,
          color: line.color,
          isRight: line.yAxisRight,
          id: line.type + 'i' + instanceNo
        };
        if (maxValue < value) {
          maxValue = value;
        }
        acc += value;
        return tlp;
      }); // point map

      const yMaxDomain: number = this.isCumulated
        ? linePoints.length > 0 ? linePoints[ linePoints.length - 1 ].valueCumulated : 1
        : maxValue;
      if (line.yAxisRight) {
        if (yMaxDomainRight < yMaxDomain) {
          yMaxDomainRight = yMaxDomain;
        }
      } else {
        if (yMaxDomainLeft < yMaxDomain) {
          yMaxDomainLeft = yMaxDomain;
        }
      }
      lines.push(linePoints);
      // this.log.debug('init line ' + line.type, linePoints)();
    } // forEach line


    // --- X ---
    const dateMin: Date = this.sourcesPlus.length > 0 ? this.sourcesPlus[0].date : new Date();
    const dateMax: Date = this.sourcesPlus.length > 0 ? this.sourcesPlus[this.sourcesPlus.length - 1].date : new Date();
    const xScale = d3Scale.scaleTime()
      .range([0, this.dimensions.boundedWidth])
      .domain([dateMin, dateMax])
      .nice();
    super.createXAxis(xScale);
    // X-line
    const selectorXline = '.x-line';
    this.svgMain.select(selectorXline).remove();
    const xLine = this.svgMain
      .append('line')
      .attr('class', selectorXline.substring(1))
      .attr('y1', 0)
      .attr('y2', this.dimensions.boundedHeight)
      .attr('stroke', 'blue')
      .attr('stroke-dasharray', '2 1')
      .attr('x1', 0)
      .attr('x2', 0);

    // --- Y left ----
    const yScaleLeft = d3Scale.scaleLinear()
      .rangeRound([ this.dimensions.boundedHeight, 0 ])
      .domain([ 0, yMaxDomainLeft ])
      .nice();
    const yAxisLeft = d3Axis.axisLeft(yScaleLeft);
    const selectorYaxisLeft = '.y-axis-left';
    if (this.svgMain.select(selectorYaxisLeft).empty()) {
      this.svgMain
        .append('g')
        .attr('class', selectorYaxisLeft.substring(1))
        .call(yAxisLeft);
      this.svgMain
        .append('g')
        .attr('class', 'y-axis-label')
        .append('text')
        .attr('x', 5)
        .attr('y', 10)
        .text('h');
    } else {
      this.svgMain.select(selectorYaxisLeft)
        .append('g') // added
        .call(yAxisLeft);
    }

    // --- Y right ----
    const yScaleRight = d3Scale.scaleLinear()
      .rangeRound([ this.dimensions.boundedHeight, 0 ])
      .domain([ 0, yMaxDomainRight ])
      .nice();
    const yAxisRight = d3Axis.axisRight(yScaleRight);
    const selectorYaxisRight = '.y-axis-right';
    if (this.svgMain.select(selectorYaxisRight).empty()) {
      this.svgMain
        .append('g')
        .attr('class', selectorYaxisRight.substring(1))
        .attr('transform', 'translate(' + this.dimensions.boundedWidth + ', 0)')
        .call(yAxisRight);
    } else {
      this.svgMain.select(selectorYaxisRight)
        .append('g') // added
        .attr('transform', 'translate(' + this.dimensions.boundedWidth + ', 0)')
        .call(yAxisRight);
    }

    // --- lines ---
    let lineNo = 0;
    for (const linePoints of lines) {
      lineNo++;
      const isRight = linePoints.length > 0 ? linePoints[ 0 ].isRight : false;
      const yScale = isRight ? yScaleRight : yScaleLeft;

      // line for scale
      const lineGenerator = d3Shape.line<TimeLinePoint>()
        .x(d => xScale(d.timePoint))
        .y(d => yScale(this.isCumulated ? d.valueCumulated : d.value)); // l|r

      const cyFunction = (d: TimeLinePoint) => {
        return yScale(this.isCumulated ? d.valueCumulated : d.value); // l|r
      };

      const selectorLine = '.time-line-' + lineNo;
      const color = linePoints.length > 0 ? linePoints[ 0 ].color : 'brown';
      if (this.svgLines.select(selectorLine).empty()) {
        this.svgLines
          .datum(linePoints)
          .append('path')
          .attr('class', selectorLine.substring(1))
          .attr('d', lineGenerator)
          .attr('stroke', color)
          .attr('fill', 'none');
      } else {
        this.svgLines.select(selectorLine)
          .datum(linePoints)
          .attr('class', selectorLine.substring(1))
          .attr('d', lineGenerator)
          .attr('stroke', color);
      }

      // dots
      const selectorDot = '.time-dot-' + lineNo;
      const dots = this.svgLines
        .selectAll<SVGCircleElement, TimeLinePoint>('circle' + selectorDot)
        .data(linePoints, d => d.id);
      dots.enter()
        .append('circle')
        .attr('r', 4)
        .attr('class', selectorDot.substring(1))
        .merge(dots)
        .attr('cx', d => xScale(d.timePoint))
        .attr('cy', cyFunction) // l|r
        .attr('fill', d => d.color)
        .on('mouseover', (d) => {
          this.tooltipDiv.style('display', 'block');
        })
        .on('mouseout', (d) => {
          this.tooltipDiv.style('display', 'none');
        })
        .on('mousemove', (d) => {
          const xP = d.event.pageX;
          const yP = d.event.pageY;
          this.tooltipDiv.style('top', yP + 'px');
          this.tooltipDiv.style('left', (xP + 15) + 'px');
          this.tooltipDiv.html(d.tooltip);
        });
      dots.exit().remove();
    } // lines

    // legend
    this.svg.select(selectorBackground)
      .on('mousemove', (d) => {
        // const xP = d3.event.pageX;
        const xPoint = d.event.offsetX - this.dimensions.marginLeft; // 1..719
        const xDomain = xScale.invert(xPoint);
        this.info = String(xPoint) + ' - ' + JSON.stringify(xDomain);
        this.legend(xDomain);
        // x line
        xLine.attr('x1', xPoint)
          .attr('x2', xPoint);
      });

    // show legend
    if (dateMax) {
      this.legend(dateMax);
    }
  } // initGraph

  /**
   * Build legend
   * @param mouseDate the hover date
   */
  private legend(mouseDate: Date): void {
    this.legendSource(mouseDate);
    this.legends = [];
    // date legend
    if (this.currentSource) {
      const value1 = Trl.formatDate(mouseDate); // this.currentSource.date);
      this.legends.push({
        label: 'Date',
        color: '',
        value: value1,
        valueCumulated: '#' + this.currentSource.no
      });
      // hours, etc
      this.definitions.forEach((def) => {
        const theValue = this.currentSource?.values[def.type];
        const value2 = def.prefix + Trl.formatNumber(theValue, def.decimals) + def.suffix;
        const theValueC = this.currentSource?.values[def.type + 'C'];
        const valueCumulated = Trl.formatNumber(theValueC, def.decimals);
        this.legends.push({
          label: def.label,
          color: def.color,
          value: value2,
          valueCumulated
        });
      });
      // margin
      const costs = this.currentSource.values.costsC; // HARD-CODED
      const revenue = this.currentSource.values.revenueC;
      const amt = (revenue ? revenue : 0) - (costs ? costs : 0);
      const pct = (revenue && revenue > 0)
        ? (amt / revenue * 100)
        : Number.NEGATIVE_INFINITY;
      this.legends.push({
        label: 'Margin',
        color: '',
        value: Trl.formatNumber(amt, 0),
        valueCumulated: Trl.formatNumber(pct, 0) + '%',
        background: amt > 0 ? 'lightgreen' : 'lightsalmon'
      });
    } // currentSource
  } // legend

  /**
   * Get Time Source for mouse date
   * @param mouseDate mouse date
   */
  private legendSource(mouseDate: Date): TimeSourceI {
    const mouseTime = mouseDate.getTime();
    //
    this.currentSource = undefined;
    if (this.sourcesPlus.length > 0) {
      let previous = this.sourcesPlus[ 0 ]; // first
      for (const src of this.sourcesPlus) { // loop
        if (mouseTime < src.date.getTime()) {
          this.currentSource = previous;
          break;
        }
        previous = src;
      }
      const last = this.sourcesPlus[this.sourcesPlus.length - 1];
      if (mouseTime >= last.date.getTime()) { // after
        this.currentSource = last;
      }
    } // legendSource
    if (this.currentSource) {
      return this.currentSource;
    }
    throw new Error('TimeLineComponent.legendSource NotFound');
  } // legendSource

} // TimeLineComponent
