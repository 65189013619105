import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger } from '../log/logger';
import { Freshdesk } from './freshdesk-ticket';
import { HelpService } from './help.service';
import { AccortoService } from '../accorto.service';
import { FreshdeskSearch } from './freshdesk-search';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { Point2d } from '../draw/point2d';

/**
 * Help Component - do not add directly - use Help Button
 * TODO Video
 * TODO select Freshdesk
 */
@Component({
  selector: 'acc-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpComponent implements OnChanges {

  @Input() title: string = 'Help';
  @Input() message: string = '';
  /** Optional vimeo id */
  @Input() vimeoId?: string;
  @Input() youtubeId?: string;


  @Output() closing = new EventEmitter();

  @ViewChild('dialog', {static: true}) dialogElementRef?: ElementRef;
  mouseOffset?: Point2d;

  activeTab: string = 'overview';

  searchTerm: string = '';
  searchMessage: string = '';
  @ViewChild('inp', {static: false}) searchInputRef?: ElementRef;
  searchResults: FreshdeskSearch[] = [];
  searchArticle?: SafeHtml;
  searchArticleTitle: string = '';

  contactForm: FormGroup;
  contactReadOnly: boolean = false;
  contactMessage: string = '';

  isActiveOverview: boolean = true;
  isActiveVideo: boolean = false;
  isActiveSearch: boolean = false;
  isActiveContact: boolean = false;

  tabIndexOverview: number = 0;
  tabIndexVideo: number = -1;
  tabIndexSearch: number = -1;
  tabIndexContact: number = -1;

  showVimeo: boolean = false;
  showYouTube: boolean = false;
  vimeoEmbed?: SafeUrl;
  vimeoUrl?: SafeUrl;
  youtubeEmbed?: SafeUrl;
  youtubeUrl?: SafeUrl;


  private log: Logger = new Logger('HelpComponent');
  private freshdesk: Freshdesk;

  /**
   * Help
   */
  constructor(private service: HelpService,
              private config: AccortoService,
              private sanitizer: DomSanitizer) {
    const email = config.getUserEmail();
    this.contactForm = new FormGroup({
      email: new FormControl(email, [ Validators.required ]),
      subject: new FormControl('', [ Validators.required ]),
      question: new FormControl('', [ Validators.required ])
    });
    //
    if (config.productCode === 'Track4D') {
      this.freshdesk = Freshdesk.TRACK4D;
    } else if (config.productCode === 'Pick4D') {
      this.freshdesk = Freshdesk.PICK4D;
    } else if (config.productCode === 'Check4Sf') {
      this.freshdesk = Freshdesk.CHECK4SF;
    } else if (config.productCode === 'Accorto') {
      this.freshdesk = Freshdesk.ACCORTO;
    } else {
      this.log.warn('Help', 'ProductCode=' + this.config.productCode)();
      this.freshdesk = Freshdesk.ACCORTO;
    }
  } // constructor

  public ngOnChanges(changes: SimpleChanges): void {
    this.showVimeo = false;
    this.showYouTube = false;
    if (this.vimeoId) {
      this.showVimeo = true;
      this.vimeoEmbed = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.vimeoId);
      this.vimeoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://vimeo.com/' + this.vimeoId);
    } else if (this.youtubeId) {
      this.showYouTube = true;
      this.youtubeEmbed = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'
        + this.youtubeId + '?rel=0&enablejsapi=1');
      this.youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/watch?v='
        + this.youtubeId + '?rel=0');
    }
  } // ngOnChanges

  onClose(): void {
    this.closing.emit();
  }

  /**
   * Contact Support
   */
  onContactSend(): void {
    this.log.info('onContactSend', this.contactForm.value)();
    const ticket = this.freshdesk.createTicket();
    ticket.email = this.contactForm.value.email;
    ticket.subject = this.contactForm.value.subject;
    //
    ticket.description = this.contactForm.value.question
      + '\n\n----'
      + '\n' + location.href;
    if (this.config.session) {
      ticket.description
        += '\n' + this.config.session.label + ' ' + this.config.session.userName
        + '\n' + this.config.session.sfUserName;
    }
    ticket.description = ticket.description.replace('/(?:\r\n|\r|\n)/g', '<br/>');

    this.contactReadOnly = true;
    this.contactMessage = 'sending ...';

    this.service.sendTicket(ticket)
      .subscribe((result: string) => {
        this.log.debug('onContactSend.result', result)();
        this.contactMessage = result;
      });
  } // onContactSupport

  onHeaderMouseDown(event: MouseEvent): void {
    // event.preventDefault();
    const div = this.dialogElementRef?.nativeElement as HTMLDivElement;
    if (div) {
      const mousePos = new Point2d(event.clientX, event.clientY);
      const divPos = new Point2d(div.offsetLeft, div.offsetTop);
      this.mouseOffset = divPos.minus(mousePos);
      this.log.debug('onHeaderHouseDown', mousePos, divPos, this.mouseOffset)();
    }
  }

  onHeaderMouseMove(event: MouseEvent): void {
    // event.preventDefault();
    if (this.mouseOffset) {
      const ref = this.dialogElementRef?.nativeElement;
      if (ref) {
        const mouse = new Point2d(event.clientX, event.clientY);
        const newPos = mouse.add(this.mouseOffset);
        // this.log.debug('onHeaderHouseMove', mouse, newPos)();
        ref.style.left = (newPos.x) + 'px';
        ref.style.top = (newPos.y) + 'px';
      }
    }
  }

  onHeaderMouseUp(): void {
    this.mouseOffset = undefined;
  }

  /**
   * Search
   */
  onSearchClick(): void {
    this.log.debug('onSearchClick ' + this.searchTerm)();
    if (this.searchTerm) {
      this.service.search(this.searchTerm, this.freshdesk)
        .subscribe((result: FreshdeskSearch[]) => {
          this.log.debug('onSearchClick', result)();
          this.searchMessage = 'Found ' + result.length + ' entries';
          this.searchResults = result;
        });
    } else {
      this.searchMessage = 'Enter a search term';
    }
  } // onSearchClick

  onSearchKeyUp(event: KeyboardEvent): void {
    const inp = this.searchInputRef?.nativeElement as HTMLInputElement;
    if (inp) {
      this.searchTerm = inp.value;
      this.log.debug('onSearchKeyUp ' + event.key, this.searchTerm)();
      if (event.key === 'Enter') {
        if (this.searchTerm) {
          this.onSearchClick();
          return;
        }
      } else if (event.key === 'Escape' || event.key === 'Clear') {
        this.searchTerm = '';
        inp.value = '';
      }
    }
  } // onSearchKeyUp

  /**
   * @param sr article selected to view
   */
  onSearchTitle(sr: FreshdeskSearch): void {
    this.log.debug('onSearchTitle', sr)();
    this.searchArticleTitle = sr.title;
    this.searchArticle = this.sanitizer.bypassSecurityTrustHtml(sr.description);
  }

  /**
   * @param activateTab tab to activate
   */
  onTabClick(activateTab: string): void {
    this.activeTab = activateTab;
    this.isActiveOverview = 'overview' === activateTab;
    this.isActiveVideo = 'video' === activateTab;
    this.isActiveSearch = 'search' === activateTab;
    this.isActiveContact = 'contact' === activateTab;
    this.tabIndexOverview = (activateTab === 'overview') ? 0 : -1;
    this.tabIndexVideo = (activateTab === 'video') ? 0 : -1;
    this.tabIndexSearch = (activateTab === 'search') ? 0 : -1;
    this.tabIndexContact = (activateTab === 'contact') ? 0 : -1;
  }


} // Help
